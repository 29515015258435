import {
  ChevronRightIcon,
  CalendarIcon,
  ExclamationTriangleIcon,
  HomeIcon,
  UserIcon,
  XMarkIcon,
  PlusIcon,
} from "@heroicons/react/24/solid";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import FindUsersPalette from "../../components/commandpalettes/FindUsersPalette";
import InfinityLoader from "../../components/loaders/InfinityLoader";
import BigModalTemplate from "../../components/modals/BigModalTemplate";
import NoSpaceTemplate from "../../components/modals/NoSpaceTemplate";
import API from "../../services/API";
import { getDateLabel } from "../../utils/date_utils";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function PositionInfo() {
  let pathParams = useParams();
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [experts, setExperts] = useState([]);
  const [format, setFormat] = useState({});
  let query = useQuery();
  const [positionText, setPositionText] = useState(query.get("puesto_nombre"));
  const { user, token } = useSelector((state) => state.authentication);

  let navigate = useNavigate();
  let editPositionRef = useRef();
  let deleteExpertRef = useRef();
  let findBossesRef = useRef();

  const [currentExpert, setCurrentExpert] = useState(0);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [currentLimit, setCurrentLimit] = useState(25);
  const [currentStatus, setCurrentStatus] = useState(1);
  const [currentPosition, setCurrentPosition] = useState("null");
  const [pagesNumber, setPagesNumber] = useState(0);
  const [dpsCount, setDpsCount] = useState(0);
  const [dps, setDps] = useState([]);


  useEffect(() => {
    setCurrentLimit(25);
    setCurrentOffset(0);
  }, [currentStatus]);

  useEffect(() => {
    const fetchActiveDPs = () => {
      let params = {
        limit: currentLimit,
        offset: currentOffset * currentLimit,
        position: pathParams.positionId
      };
      const options = { params, headers: { Authorization: token } };
      API.get("/dp/formats", options)
        .then((response) => {
          const { data, status } = response;
          if (status === 200) {
            setDps(data.dps);
            setPagesNumber(data.pages + 1);
            setDpsCount(data.count);
          } else if (status === 204) {
            setDps([]);
            setPagesNumber(0);
            setDpsCount(0);
          } else {
            setDps([]);
            setPagesNumber(0);
            setDpsCount(0);
          }
        })
        .catch((err) => {
          setDps([]);
          setPagesNumber(0);
          setDpsCount(0);
        });
    };
    fetchActiveDPs();
  }, [currentStatus, currentPosition, currentLimit, currentOffset]);

  useEffect(() => {
    let params = { puesto: pathParams.positionId };
    let paramsDP = { dp_usuario_id: pathParams.positionId };
    const options = { params, headers: { Authorization: token } };
    API.get("/dp/experts", options).then(({ data, status }) => {
      if (status === 200) {
        setExperts(data);
      }
    });
    const optionsDP = { params: paramsDP, headers: { Authorization: token } };
    setLoadingInfo(true);
    API.get("/dp/formats/users", optionsDP).then(({ data, status }) => {
      setLoadingInfo(false);
      if (status === 200) {
        setFormat(data);
      }
    })
    .catch(error => {
      setLoadingInfo(false);
    });
  }, []);

  const goBack = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const selectExpertToDelete = (expert) => {
    setCurrentExpert(expert);
    deleteExpertRef.current.showModal();
  };

  const getEstatusClassName = (status) => {
    let className = "";
    switch(status){
      case 2:
        className = "text-yellow-600";
        break;
      case 3:
        className = "text-green-600";
        break;
      case 4:
        className = "text-blue-600";
        break;
      default:
        className = "text-red-500";
        break;
    }
    return className;
  };

  const getEstatusLabel = (status) => {
    let label = "";
    switch(status){
      case 2:
        label = "Descripción actualizada";
        break;
      case 3:
        label = "Descripción validada";
        break;
      case 4:
        label = "Descripción autorizada";
        break;
      default:
        label = "Descripción en proceso";
        break;
    }
    return label;
  };

  let areaText = query.get("area_nombre");
  const patchPosition = (e) => {
    let body = {
      puesto: positionText,
      puesto_id: pathParams.positionId,
    };
    let options = {
      headers: {
        Authorization: token
      }
    }
    API.patch("/dp/positions", body, options)
      .then((response) => {
        const { data, status } = response;
        if (status === 200) {

          navigate({
            pathname: `/t3b/admin/regions/${pathParams.regionType}/areas/${pathParams.areaId}/positions/${pathParams.positionId}`,
            search: `?area_nombre=${areaText}&puesto_nombre=${positionText}`
          }, {replace: true});
          editPositionRef.current.hideModal();
          return setEditing(false);
        }
      })
      .catch((error) => {});
  };


  const createUpdate = (e) => {
    let body = {
      numero_empleado: user.numero_empleado,
      usuario_actualiza_id: user.numero_empleado,
      puesto_id: pathParams.positionId,
    };
    const options = { headers: { Authorization: token } };
    API.post(`/dp/positions/${pathParams.positionId}/update`, body, options)
      .then((response) => {
        const { data, status } = response;
        if (status === 200) {
          return navigate(`/t3b/admin/position-profile/${data.tra_ap_id}/update`);
        }
      })
      .catch((error) => {});
  };

  const deleteUser = () => {
    let body = {
      rel_puesto_experto_id: currentExpert,
      usuario_alta: user.numero_empleado,
    };
    let options = { headers: {Authorization: token} }
    API.patch("/dp/experts", body, options)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          deleteExpertRef.current.hideModal();
          let newExperts = [experts];
          newExperts.filter((e) => e.rel_puesto_experto_id == currentExpert);
          setCurrentExpert(0);
          return setExperts(newExperts);
        }
      })
      .catch((error) => {

      });
  };

  const selectUser = (selected) => {
    let body = {
      puesto_id: pathParams.positionId,
      numero_empleado: selected.numero_empleado,
      usuario_alta: user.numero_empleado,
    };
    let options = { headers: {Authorization: token} }
    API.post("/dp/experts", body, options)
      .then((response) => {
        const { data, status } = response;
        if (status === 200) {
          body.nombre_completo = selected.nombre_completo;
          body.rel_puesto_experto_id = data;
          setExperts([...experts, body]);
          return findBossesRef.current.hideModal();
        }
      })
      .catch((error) => {

      });
  };

  let superAdministrador = user?.certificados?.filter(c => c.cat_permiso_id == 9 && c.estatus == 1)?.length > 0;

  return (
    <div className="flex justify-center w-full">
      <div className="px-4 sm:px-6 lg:px-8 py-8 flex-1 max-w-6xl">
        <nav className="flex mb-4" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            <li>
              <div>
                <Link
                  to="/t3b/admin/regions"
                  className="text-gray-400 hover:text-gray-500"
                >
                  <HomeIcon
                    className="h-5 w-5 flex-shrink-0"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Home</span>
                </Link>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon
                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <button
                  onClick={goBack}
                  className="ml-4 text-sm font-medium hover:text-gray-500"
                >
                  {query.get("area_nombre")}
                </button>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon
                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <span className="ml-4 text-sm font-medium text-gray-700">
                  {query.get("puesto_nombre")}
                </span>
              </div>
            </li>
          </ol>
        </nav>
        {loadingInfo ? (
          <>
            <InfinityLoader />
          </>
        ) : (
          <div className="flex flex-col space-y-6">
            <div className="overflow-hidden bg-white shadow sm:rounded-lg mt-8">
              <div className="px-4 py-5 sm:px-6 flex items-center flex-wrap justify-between">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Detalles del puesto
                </h3>
                <div className="flex space-x-2 flex-wrap">
                  <Link to={`/t3b/position/${pathParams.positionId}/profile${superAdministrador ? "?prtadm=yes" : ""}`}>
                    <button
                      className="rounded-lg px-3 py-2 bg-gray-200 text-gray-700 text-sm font-medium hover:bg-gray-300"
                    >
                      Imprimir
                    </button>
                  </Link>
                  {superAdministrador && <button
                    onClick={(e) => editPositionRef.current.showModal()}
                    className="rounded-lg px-3 py-2 bg-green-200 text-green-700 text-sm font-medium hover:bg-green-300"
                  >
                    Editar puesto
                  </button>}
                </div>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Puesto</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {query.get("puesto_nombre")}
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                    Última actualización
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <p className="mb-1">
                        {getDateLabel(format.fecha_modificacion)}
                      </p>
                      {superAdministrador && <button
                        className="text-green-600 hover:text-green-800 underline font-medium"
                        onClick={createUpdate}
                      >
                        Actualizar
                      </button>}
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      <p>Expertos Funcionales, Validadores y Autorizadores</p>
                      {superAdministrador && <button onClick={e => findBossesRef.current.showModal()} className="flex items-center text-green-600 hover:text-green-800 underline font-medium">
                        <PlusIcon className="mr-1 h-5 w-5" /> Agregar
                      </button>}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {
                        [...experts].length < 1
                        ? <p className="text-red-500 font-medium">
                          No hay Expertos Funcionales Activos en el
                          puesto
                        </p>
                        : <ul role="list" className="divide-y divide-gray-200 rounded-md border border-gray-200">
                          {[...experts]
                            .map((expert) => (
                              <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm" key={`key-expert-${expert.numero_empleado}`}>
                                <div className="flex w-0 flex-1 items-center">
                                  <UserIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                  <span className="ml-2 w-0 flex-1 truncate">
                                  {expert.nombre_completo}
                                  </span>
                                </div>
                                {superAdministrador && <div className="ml-4 flex-shrink-0">
                                  <button
                                    className="font-medium text-red-600 hover:text-red-500"
                                    onClick={e => selectExpertToDelete(expert.rel_puesto_experto_id)}
                                  >
                                    Eliminar
                                  </button>
                                </div>}
                              </li>
                          ))}
                        </ul>
                      }
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            {superAdministrador && <div className="overflow-hidden bg-white shadow sm:rounded-md">
              <ul role="list" className="divide-y divide-gray-200">
                {dps.map((dp) => (
                  <li key={`key-history-${dp.tra_ap_id}`}>
                    <Link to={`/t3b/admin/position-history/${dp.tra_ap_id}`} className="block hover:bg-gray-50">
                      <div className="flex items-center px-4 py-4 sm:px-6">
                        <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                          <div className="truncate">
                            <div className="flex text-sm">
                              <p className={`truncate font-medium ${getEstatusClassName(dp.estatus_ap_id)}`}>{getEstatusLabel(dp.estatus_ap_id)}</p>
                              <p className="ml-1 flex-shrink-0 font-normal text-gray-500"> última modificación por {dp.usuario_modificacion_nombre}</p>
                            </div>
                            <div className="mt-2 flex">
                              <div className="flex items-center text-sm text-gray-500">
                                <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                <p>
                                  Actualizada el {getDateLabel(dp.fecha_modificacion)}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                            {/* <div className="flex -space-x-1 overflow-hidden">
                              {dp.applicants.map((applicant) => (
                                <img
                                  key={applicant.email}
                                  className="inline-block h-6 w-6 rounded-full ring-2 ring-white"
                                  src={applicant.imageUrl}
                                  alt={applicant.name}
                                />
                              ))}
                            </div> */}
                          </div>
                        </div>
                        <div className="ml-5 flex-shrink-0">
                          <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>}
          </div>
        )}
      </div>
      <BigModalTemplate ref={editPositionRef}>
        <div className="flex justify-between items-top mb-8">
          <p className="text-3xl font-medium">Editar puesto</p>
          <button
            type="button"
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            onClick={(e) => editPositionRef.current.hideModal()}
          >
            <span className="sr-only">Cerrar</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="">
          <div className="mb-4">
            <label
              htmlFor="question-title"
              className="block text-xs text-red-500"
            >
              PUESTO
            </label>
            <div className="mt-1">
              <input
                id="title"
                name="title"
                type="text"
                placeholder="Nombre del puesto..."
                value={positionText}
                onChange={(e) => setPositionText(e.target.value)}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="pt-6">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={(e) => editPositionRef.current.hideModal()}
                className="rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                Cancelar
              </button>
              <button
                type="button"
                onClick={patchPosition}
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                {loading ? "Editando puesto" : "Editar"}
              </button>
            </div>
          </div>
        </div>
      </BigModalTemplate>
      <BigModalTemplate ref={deleteExpertRef}>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon
              className="h-6 w-6 text-red-600"
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3
              as="h3"
              className="text-lg font-medium leading-6 text-gray-900"
            >
              Eliminar experto
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                ¿Deseas eliminar el experto funcional del puesto?
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={deleteUser}
            disabled={loadingDelete}
          >
            {loadingDelete ? "Eliminando experto" : "Eliminar"}
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={() => deleteExpertRef.current.hideModal()}
          >
            Cancel
          </button>
        </div>
      </BigModalTemplate>
      <NoSpaceTemplate ref={findBossesRef}>
          <FindUsersPalette callback={selectUser} />
      </NoSpaceTemplate>
    </div>
  );
}
