import { forwardRef, Fragment, useImperativeHandle, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'

const NoSpaceTemplate = forwardRef(({ noClickOutside, children }, ref) => {
  const [open, setOpen] = useState(false)

  const cancelButtonRef = useRef(null)

  useImperativeHandle(ref, () => ({
    showModal() {
        setOpen(true);
    },
    hideModal() {
        setOpen(false);
    }
  }));

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-100" initialFocus={cancelButtonRef} onClose={e => setOpen(noClickOutside ? true : false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform text-left transition-all sm:w-full sm:max-w-3xl">
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
});

export default NoSpaceTemplate;