import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Notification from '../../components/notifications/Notification';
import API from '../../services/API';
import { useSelector } from 'react-redux';

const CreatePositionProfileProcess = () => {
  const [open, setOpen] = useState(false)
  const [loadingFile, setLoadingFile] = useState(false)
  const [notificationType, setNotificationType] = useState("danger")
  const [file, setFile] = useState()
  const [error, setError] = useState("")
  const [errorTitle, setErrorTitle] = useState("Error")
  const notificationRef = useRef();
  const {user,token} = useSelector(state => state.authentication);

  const uploadFile = (e) => {
    if(e.target.files[0]){
      setOpen(true);
      setFile(e.target.files[0]);
      e.target.value = "";
    } else {
      setNotificationType("danger");
      setErrorTitle("Error");
      setError(
        "Selecciona un archivo para subir"
      );
      notificationRef.current.showNotification();
    }
  }

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject("Error: ", error);
      };
    });
  };

  const saveFile = async () => {
    let body = {
      file: await getBase64(file),
      numero_empleado: user.numero_empleado
    };
    setOpen(false);
    setLoadingFile(true);
    API.post(`/dp/users`, body, {
      headers: { Authorization: token },
    })
      .then((result) => {
        setErrorTitle("Archivo cargado");
        setError("Se han cargado los procesos de actualización");
        setNotificationType("success");
        setLoadingFile(false);
        notificationRef.current.showNotification();
      })
      .catch((error) => {
        setLoadingFile(false);
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          }
          setErrorTitle("Hubo errores en el archivo");
          setError(error.response.data.join("\n"));
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al subir el archivo de layout"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al subir el archivo de layout"
          );
        }
        setErrorTitle("Error");
        setNotificationType("danger");
        notificationRef.current.showNotification();
      });
  };

  return (
    <div className="flex justify-center py-8">
      <div className="max-w-5xl w-full">
        <div className="flex-1">
          <label
            htmlFor="cover-photo"
            className="block text-sm font-medium text-gray-700"
          >
            Subir layout
          </label>
          <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
            <div className="space-y-1 text-center">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="flex text-sm text-gray-600">
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                >
                  <span>Sube el layout de actualización de descripciones de puesto para comenzar un periodo de actualización</span>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    className="sr-only"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={uploadFile}
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Subir layout
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          ¿Estás seguro de querer subir el layout de proceso de actualización de Descripciones de Puesto?.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={saveFile}
                    >
                      Subir
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Notification
        title={errorTitle}
        message={error}
        type={notificationType}
        ref={notificationRef}
      />
    </div>
  );
};

export default CreatePositionProfileProcess;
