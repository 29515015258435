import PropTypes from "prop-types";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useRef } from "react";
import logo from "./../../assets/img/brand/logo.svg";
import API from "../../services/API";
import { XMarkIcon } from "@heroicons/react/20/solid";
import {
  competenceLevelsOptions,
  competencesCultureOptions,
  competencesOptions,
  competencesSalesOptions,
  escolaridadOptions,
  estadoCivilOptions,
  estatusEstudiosOptions,
  experienceOptions,
  experienceYearsOptions,
  frecuencias,
  generoOptions,
  horarioOptions,
  languageLevelOptions,
  travelOptions,
} from "../../constants/utils";
import TextAreaAutosize from "../inputs/TextAreaAutosize";
import { Range, getTrackBackground } from "react-range";
import { PlusIcon } from "@heroicons/react/24/solid";
import BigModalTemplate from "../modals/BigModalTemplate";
import Select from "react-select";

const UpdatePositionProfileFormat = ({
  answers,
  onChangeCallback,
  setAnswers,
  ap,
}) => {
  const { user, token } = useSelector((state) => state.authentication);
  let newFunctionRef = useRef();
  let newInteractionRef = useRef();
  let newLanguageRef = useRef();
  let newPositionRef = useRef();
  let newTechnicalCompetitionRef = useRef();
  let newBusinessCompetitionRef = useRef();
  let editFunctionRef = useRef();

  const [title, setTitle] = useState("");
  const [competence, setCompetence] = useState("");
  const [competenceType, setCompetenceType] = useState(1);
  const [questionEdit, setQuestionEdit] = useState(0);
  const [indexEdit, setIndexEdit] = useState(0);
  const [level, setLevel] = useState(0);
  const [attachments, setAttachments] = useState("");
  const [frequency, setFrequency] = useState("");
  const [newPositionSelected, setNewPositionSelected] = useState(0);
  const [type, setType] = useState(0);
  const [positionsAll, setPositionsAll] = useState([]);
  const [positionSelected, setPositionSelected] = useState([]);
  const [areas, setAreas] = useState([]);

  useEffect(() => {
    let params = {};
    let options = { params, headers: { Authorization: token } };
    API.get("/areas", options).then(({ data, status }) => {
      if (status === 200) {
        setAreas(
          data.map((f) => {
            f.id = f.area_id;
            f.label = f.area_nombre;
            return f;
          })
        );
      }
    });
    options.params = { perareaall: true };
    API.get("/positions", options).then(({ data, status }) => {
      if (status === 200) {
        setPositionsAll(
          data.map((f) => {
            f.id = f.puesto_id;
            f.value = f.puesto_id;
            f.label = f.puesto_nombre;
            return f;
          })
        );
      }
    });
  }, []);

  const checkJSON = (pregunta_id) => {
    if (answers[pregunta_id]) {
      return true;
    } else {
      return false;
    }
  };

  const returnJSON = (pregunta_id) => {
    if (answers[pregunta_id]) {
      return answers[pregunta_id].respuesta_json;
    } else {
      return false;
    }
  };

  const toggleAnswers = (
    tra_ap_usuario_id,
    tipo_respuesta_id,
    respuesta_texto,
    pregunta_id
  ) => {
    const regex =
      /^[0-9a-zA-Z(\-\ \xE9\xE0\xD6\x90\xB5\xA3\xA2\xA1\x82\xA0\'\%\&\/\(\)\=\?\¿\¡\!\$\´\xA5\xA4\xEF\,\.\;\:\+\*(\r\n|\r|\n))]*$/;
    if (respuesta_texto.match(regex)) {
      let newAnswers = { ...answers };
      if (!newAnswers[pregunta_id]) {
        newAnswers[pregunta_id] = {
          tra_ap_usuario_id: "",
          tipo_respuesta_id: tipo_respuesta_id,
          respuesta_texto: "",
          respuesta_json: "",
          numero_empleado: "",
          pregunta_id: pregunta_id,
        };
      }
      newAnswers[pregunta_id].tra_ap_usuario_id = tra_ap_usuario_id;
      newAnswers[pregunta_id].respuesta_texto = respuesta_texto;
      newAnswers[pregunta_id].respuesta_json = respuesta_texto;
      newAnswers[pregunta_id].numero_empleado = user.numero_empleado;
      setAnswers(newAnswers);
    } else {
      const regexreplace =
        /^[0-9a-zA-Z(\-\ \xE9\xE0\xD6\x90\xB5\xA3\xA2\xA1\x82\xA0\'\%\&\/\(\)\=\?\¿\¡\!\$\´\xA5\xA4\xEF\,\.\;\:\+\*(\r\n|\r|\n))]*$/g;
      let str = respuesta_texto.replace(regexreplace, "");
      let newAnswers = { ...answers };
      if (!newAnswers[pregunta_id]) {
        newAnswers[pregunta_id] = {
          tra_ap_usuario_id: "",
          tipo_respuesta_id: tipo_respuesta_id,
          respuesta_texto: "",
          respuesta_json: "",
          numero_empleado: "",
          pregunta_id: pregunta_id,
        };
      }
      newAnswers[pregunta_id].tra_ap_usuario_id = tra_ap_usuario_id;
      newAnswers[pregunta_id].respuesta_texto = str;
      newAnswers[pregunta_id].respuesta_json = str;
      newAnswers[pregunta_id].numero_empleado = user.numero_empleado;
      setAnswers(newAnswers);
    }
  };

  const toggleSelectAnswers = (
    tra_ap_usuario_id,
    tipo_respuesta_id,
    respuesta_texto,
    pregunta_id
  ) => {
    const regex =
      /^[0-9a-zA-Z(\-\ \xE9\xE0\xD6\x90\xB5\xA3\xA2\xA1\x82\xA0\'\%\&\/\(\)\=\?\¿\¡\!\$\´\xA5\xA4\xEF\,\.\;\:\+\*(\r\n|\r|\n))]*$/;
    if (respuesta_texto.match(regex)) {
      let newAnswers = { ...answers };
      if (!newAnswers[pregunta_id]) {
        newAnswers[pregunta_id] = {
          tra_ap_usuario_id: "",
          tipo_respuesta_id: tipo_respuesta_id,
          respuesta_texto: "",
          respuesta_json: "",
          numero_empleado: "",
          pregunta_id: pregunta_id,
        };
      }
      newAnswers[pregunta_id].tra_ap_usuario_id = tra_ap_usuario_id;
      newAnswers[pregunta_id].respuesta_texto = respuesta_texto;
      newAnswers[pregunta_id].respuesta_json = respuesta_texto;
      newAnswers[pregunta_id].numero_empleado = user.numero_empleado;
      setAnswers(newAnswers);
      onChangeCallback(pregunta_id, newAnswers[pregunta_id]);
    } else {
      const regexreplace =
        /^[0-9a-zA-Z(\-\ \xE9\xE0\xD6\x90\xB5\xA3\xA2\xA1\x82\xA0\'\%\&\/\(\)\=\?\¿\¡\!\$\´\xA5\xA4\xEF\,\.\;\:\+\*(\r\n|\r|\n))]*$/g;
      let str = respuesta_texto.replace(regexreplace, "");
      let newAnswers = { ...answers };
      if (!newAnswers[pregunta_id]) {
        newAnswers[pregunta_id] = {
          tra_ap_usuario_id: "",
          tipo_respuesta_id: tipo_respuesta_id,
          respuesta_texto: "",
          respuesta_json: "",
          numero_empleado: "",
          pregunta_id: pregunta_id,
        };
      }
      newAnswers[pregunta_id].tra_ap_usuario_id = tra_ap_usuario_id;
      newAnswers[pregunta_id].respuesta_texto = str;
      newAnswers[pregunta_id].respuesta_json = str;
      newAnswers[pregunta_id].numero_empleado = user.numero_empleado;
      setAnswers(newAnswers);
      onChangeCallback(pregunta_id, newAnswers[pregunta_id]);
    }
  };

  const removeFromArray = (pregunta_id, index, tra_ap_usuario_id) => {
    let newAnswers = { ...answers };
    if (!newAnswers[pregunta_id]) {
      newAnswers[pregunta_id] = { ...answers[pregunta_id] };
    }
    newAnswers[pregunta_id].tra_ap_usuario_id = tra_ap_usuario_id;
    newAnswers[pregunta_id].respuesta_json.splice(index, 1);
    newAnswers[pregunta_id].numero_empleado = user.numero_empleado;
    setAnswers(newAnswers);
    onChangeCallback(pregunta_id, newAnswers[pregunta_id]);
  };

  const addItemToAnswers = (tra_ap_usuario_id, item, pregunta_id) => {
    const tipo_respuesta_id = 2;
    let newAnswers = { ...answers };
    if (!newAnswers[pregunta_id]) {
      newAnswers[pregunta_id] = {
        tra_ap_usuario_id: "",
        tipo_respuesta_id: "",
        respuesta_texto: "",
        respuesta_json: answers[pregunta_id]
          ? answers[pregunta_id].respuesta_json
          : [],
        numero_empleado: "",
        pregunta_id: "",
      };
    }
    newAnswers[pregunta_id].tra_ap_usuario_id = tra_ap_usuario_id;
    newAnswers[pregunta_id].tipo_respuesta_id = tipo_respuesta_id;
    newAnswers[pregunta_id].respuesta_texto = "";
    newAnswers[pregunta_id].respuesta_json = [
      ...newAnswers[pregunta_id].respuesta_json,
      { ...item },
    ];
    newAnswers[pregunta_id].pregunta_id = pregunta_id;
    newAnswers[pregunta_id].numero_empleado = user.numero_empleado;
    setAnswers(newAnswers);
    onChangeCallback(pregunta_id, newAnswers[pregunta_id]);
  };

  const editItemFromAnswers = (index, field, pregunta_id, value) => {
    let newAnswers = { ...answers };
    newAnswers[pregunta_id].respuesta_json[index][field] = value;
    newAnswers[pregunta_id].numero_empleado = user.numero_empleado;
    setAnswers(newAnswers);
    onChangeCallback(pregunta_id, newAnswers[pregunta_id]);
  };

  const newPositionPosition = (type) => {
    setNewPositionSelected(0);
    setType(type);
    newPositionRef.current.showModal();
  };

  const newPositionFunction = () => {
    setTitle("");
    newFunctionRef.current.showModal();
  };

  const newPositionInteraction = () => {
    setTitle("");
    setAttachments("");
    setFrequency(1);
    setPositionSelected([]);
    newInteractionRef.current.showModal();
  };

  const newPositionLanguage = () => {
    setTitle("");
    setLevel(0);
    newLanguageRef.current.showModal();
  };

  const newPositionTechnicalCompetition = () => {
    setTitle("");
    setLevel(0);
    newTechnicalCompetitionRef.current.showModal();
  };

  const newPositionBusinessCompetition = () => {
    setTitle("");
    setLevel(0);
    setCompetence(0);
    setCompetenceType("1");
    newBusinessCompetitionRef.current.showModal();
  };

  const saveNewPositionPosition = (ap_id) => {
    let item = {
      puesto_id: newPositionSelected
    };
    addItemToAnswers(ap_id, item, type);
    newPositionRef.current.hideModal();
  };

  const editFunction = (pregunta_id, item, key) => {
    setTitle(item.titulo);
    setQuestionEdit(pregunta_id);
    setIndexEdit(key);
    editFunctionRef.current.showModal();
  };

  const saveEditPositionFunction = () => {
    if (title == "") {
      return;
    }
    editItemFromAnswers(indexEdit, 'titulo', questionEdit, title);
    setTitle("");
    editFunctionRef.current.hideModal();
  };

  const saveNewPositionFunction = (ap_id, item) => {
    if (item.titulo == "") {
      return;
    }
    addItemToAnswers(ap_id, item, 9);
    newFunctionRef.current.hideModal();
  };

  const saveNewPositionLanguage = (ap_id, item) => {
    if (item.titulo == "") {
      return;
    }
    addItemToAnswers(ap_id, item, 22);
    newLanguageRef.current.hideModal();
  };

  const saveNewPositionTechnicalCompetition = (ap_id, item) => {
    if (item.titulo == "") {
      return;
    }
    addItemToAnswers(ap_id, item, 30);
    newTechnicalCompetitionRef.current.hideModal();
  };

  const saveNewPositionBusinessCompetition = (ap_id, item) => {
    if (competenceType == "TYPE2") {
      item.titulo = title;
    }
    addItemToAnswers(ap_id, item, 29);
    newBusinessCompetitionRef.current.hideModal();
  };

  const saveNewPositionInteraction = (ap_id) => {
    if (title == "") {
      return;
    }
    if (attachments == "") {
      return;
    }
    if (positionSelected.length < 1) {
      return;
    }
    let item = {
      actividad: title,
      inputs: attachments,
      frecuencia: frequency,
      puestos: positionSelected,
    };
    addItemToAnswers(ap_id, item, 11);
    newInteractionRef.current.hideModal();
  };

  return (
    <>
      <div className="w-full flex flex-col justify-center items-center">
        <div className="bg-white max-w-7xl mt-4 shadow border w-full p-3">
          <div className="text-red-900 px-4 py-2 flex justify-between items-center">
            <p>
              <span className="text-red-900 text-2xl font-extrabold">
                DESCRIPCIÓN DE PUESTO{" "}
              </span>
              <span className=" text-2xl font-extrabold">
                / {ap.puesto_nombre}
              </span>
            </p>
            <img src={logo} alt="" className="h-10 w-10" />
          </div>
          <div className="px-4 py-2">
            <p className="text-gray-900 font-medium">
              I. INFORMACIÓN GENERAL Y UBICACIÓN ORGANIZACIONAL.
            </p>
            <p className="text-gray-600 text-sm">
              Especifique a qué rango organizacional pertenece el puesto y el
              nombre del mismo.
            </p>
            <div className="mt-4">
              <label
                htmlFor="department-area"
                className="block text-sm font-medium text-gray-700"
              >
                Departamento/ Área:
              </label>
              <select
                id="department-area"
                name="department-area"
                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                value={answers[2] ? answers[2].respuesta_texto : ""}
                onChange={(e) =>
                  toggleSelectAnswers(
                    ap.tra_ap_usuario_id,
                    3,
                    e.target.value,
                    2
                  )
                }
              >
                <option value={0} disabled>
                  Selecciona una opción
                </option>
                {areas.map((area) => (
                  <option
                    key={`option-area-key${area.area_id}`}
                    value={area.area_id}
                  >
                    {area.area_nombre}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="px-4 py-2 mt-8">
            <p className="text-gray-900 font-medium">
              II. OBJETIVO DEL PUESTO.
            </p>
            <p className="text-gray-600 text-sm">
              Razón de ser ¿Qué hace?, ¿Cómo lo hace?, ¿Para qué lo hace?
            </p>
            <div className="mt-4">
              <TextAreaAutosize
                id="purpose-input"
                rows="3"
                value={answers[3] ? answers[3].respuesta_texto : ""}
                onChange={(e) =>
                  toggleAnswers(ap.tra_ap_usuario_id, 1, e.target.value, 3)
                }
                onBlur={(e) => onChangeCallback(3, answers[3])}
              ></TextAreaAutosize>
            </div>
          </div>
          <div className="px-4 py-2 mt-8">
            <p className="text-gray-900 font-medium">
              III. ORGANIGRAMA Y TRAMO DE CONTROL.
            </p>
            <p className="text-gray-600 text-sm">
              Posición y relación jerárquica.
            </p>
            <div className="mt-4">
              <label
                htmlFor="boss-position"
                className="block text-sm font-medium text-gray-700"
              >
                Puesto del Jefe Inmediato:
              </label>
              <select
                id="boss-position"
                name="boss-position"
                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                value={answers[4] ? answers[4].respuesta_texto : ""}
                onChange={(e) =>
                  toggleSelectAnswers(
                    ap.tra_ap_usuario_id,
                    1,
                    e.target.value,
                    4
                  )
                }
              >
                <option value={0} disabled>
                  Selecciona una opción
                </option>
                {positionsAll.map((puesto) => (
                  <option
                    key={`option-puesto-jefe-key-${puesto.puesto_region_area_id}-${puesto.puesto_id}`}
                    value={puesto.puesto_id}
                  >
                    {puesto.puesto_nombre}
                    {positionsAll.filter(
                      (p) => p.puesto_nombre === puesto.puesto_nombre
                    )?.length > 1
                      ? `(${puesto.puesto_region_area_id})`
                      : ""}
                  </option>
                ))}
              </select>
            </div>
            <div className="mt-8">
              <label
                htmlFor="boss-position"
                className="block text-sm font-medium text-gray-700"
              >
                Puestos que supervisa directamente:
              </label>
              <div className="mt-1 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr className="divide-x divide-gray-200">
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Puestos que supervisa directamente
                            </th>
                            <th
                              scope="col"
                              className="w-36 py-3.5 pl-4 pr-4 text-center text-sm font-semibold text-gray-900 sm:pr-6"
                            >
                              <button
                                onClick={e => newPositionPosition(5)}
                                className="px-3 py-2 rounded-lg bg-green-200 text-green-700 hover:bg-green-300 flex items-center text-sm mx-auto"
                              >
                                <PlusIcon className="h-4 w-4 mr-1" /> Puesto
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {checkJSON(5) &&
                            returnJSON(5)?.map((item, key) => (
                              <tr
                                className="divide-x divide-gray-200"
                                key={`tr-key-funcion-${key}`}
                              >
                                <td className="whitespace-pre-wrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                                  {item
                                    ? positionsAll.find(p => p.puesto_id == item.puesto_id)?.puesto_nombre
                                    : 0
                                  }
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm sm:pr-6 text-center">
                                  <button
                                    onClick={(e) => removeFromArray(5, key)}
                                    className="text-red-500 hover:text-red-700"
                                  >
                                    Eliminar
                                  </button>
                                </td>
                              </tr>
                            ))}
                          {checkJSON(5) && returnJSON(5)?.length < 1 && (
                            <tr className="divide-x divide-gray-200">
                              <td
                                className="whitespace-pre-wrap py-4 pl-4 pr-4 text-sm font-medium text-red-500 sm:pl-6"
                                colSpan={2}
                              >
                                No se han definido Puestos que supervisa directamente, da
                                click en "+ Puesto" para agregar uno.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8">
              <label
                htmlFor="boss-position"
                className="block text-sm font-medium text-gray-700"
              >
                Personal a cargo 2do NIVEL (Colaboradores de sus colaboradores
                directos):
              </label>
              <div className="mt-1 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr className="divide-x divide-gray-200">
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Personal a cargo 2do NIVEL (Colaboradores de sus colaboradores directos)
                            </th>
                            <th
                              scope="col"
                              className="w-36 py-3.5 pl-4 pr-4 text-center text-sm font-semibold text-gray-900 sm:pr-6"
                            >
                              <button
                                onClick={e => newPositionPosition(7)}
                                className="px-3 py-2 rounded-lg bg-green-200 text-green-700 hover:bg-green-300 flex items-center text-sm mx-auto"
                              >
                                <PlusIcon className="h-4 w-4 mr-1" /> Puesto
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {checkJSON(7) &&
                            returnJSON(7)?.map((item, key) => (
                              <tr
                                className="divide-x divide-gray-200"
                                key={`tr-key-funcion-${key}`}
                              >
                                <td className="whitespace-pre-wrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                                  {item
                                    ? positionsAll.find(p => p.puesto_id == item.puesto_id)?.puesto_nombre
                                    : 0
                                  }
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm sm:pr-6 text-center">
                                  <button
                                    onClick={(e) => removeFromArray(7, key)}
                                    className="text-red-500 hover:text-red-700"
                                  >
                                    Eliminar
                                  </button>
                                </td>
                              </tr>
                            ))}
                          {checkJSON(7) && returnJSON(7)?.length < 1 && (
                            <tr className="divide-x divide-gray-200">
                              <td
                                className="whitespace-pre-wrap py-4 pl-4 pr-4 text-sm font-medium text-red-500 sm:pl-6"
                                colSpan={2}
                              >
                                No se han definido Puestos que supervisa directamente, da
                                click en "+ Puesto" para agregar uno.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8">
              <label
                htmlFor="boss-position"
                className="block text-sm font-medium text-gray-700"
              >
                Personal a cargo Indirecto(Supervisión en región)
              </label>
              <div className="mt-1 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr className="divide-x divide-gray-200">
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Personal a cargo Indirecto(Supervisión en región)
                            </th>
                            <th
                              scope="col"
                              className="w-36 py-3.5 pl-4 pr-4 text-center text-sm font-semibold text-gray-900 sm:pr-6"
                            >
                              <button
                                onClick={e => newPositionPosition(8)}
                                className="px-3 py-2 rounded-lg bg-green-200 text-green-700 hover:bg-green-300 flex items-center text-sm mx-auto"
                              >
                                <PlusIcon className="h-4 w-4 mr-1" /> Puesto
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {checkJSON(8) &&
                            returnJSON(8)?.map((item, key) => (
                              <tr
                                className="divide-x divide-gray-200"
                                key={`tr-key-funcion-${key}`}
                              >
                                <td className="whitespace-pre-wrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                                  {item
                                    ? positionsAll.find(p => p.puesto_id == item.puesto_id)?.puesto_nombre
                                    : 0
                                  }
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm sm:pr-6 text-center">
                                  <button
                                    onClick={(e) => removeFromArray(8, key)}
                                    className="text-red-500 hover:text-red-700"
                                  >
                                    Eliminar
                                  </button>
                                </td>
                              </tr>
                            ))}
                          {checkJSON(8) && returnJSON(8)?.length < 1 && (
                            <tr className="divide-x divide-gray-200">
                              <td
                                className="whitespace-pre-wrap py-4 pl-4 pr-4 text-sm font-medium text-red-500 sm:pl-6"
                                colSpan={2}
                              >
                                No se han definido Personal a cargo Indirecto(Supervisión en región), da
                                click en "+ Puesto" para agregar uno.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="px-4 py-2 mt-8">
            <p className="text-gray-900 font-medium">
              IV. FUNCIONES PRINCIPALES.
            </p>
            <p className="text-gray-600 text-sm">
              Responsabilidades, procesos y acciones clave para ejecutar el
              objetivo de la posición. ¿Qué hace? (Acción), ¿Cómo se hace?
              (método), ¿Para qué se hace? (objetivo)
            </p>
            <div className="mt-4 flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr className="divide-x divide-gray-200">
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Función
                          </th>
                          <th
                            scope="col"
                            className="w-24 py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                          </th>
                          <th
                            scope="col"
                            className="w-36 py-3.5 pl-4 pr-4 text-center text-sm font-semibold text-gray-900 sm:pr-6"
                          >
                            <button
                              onClick={newPositionFunction}
                              className="px-3 py-2 rounded-lg bg-green-200 text-green-700 hover:bg-green-300 flex items-center text-sm mx-auto"
                            >
                              <PlusIcon className="h-4 w-4 mr-1" /> Función
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {checkJSON(9) &&
                          returnJSON(9)?.map((item, key) => (
                            <tr
                              className="divide-x divide-gray-200"
                              key={`tr-key-funcion-${key}`}
                            >
                              <td className="whitespace-pre-wrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                                {item
                                  ? item.titulo
                                  : 0
                                }
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm sm:pr-6 text-center">
                                <button
                                  onClick={(e) => editFunction(9, item, key)}
                                  className="text-green-500 hover:text-green-700"
                                >
                                  Editar
                                </button>
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm sm:pr-6 text-center">
                                <button
                                  onClick={(e) => removeFromArray(9, key)}
                                  className="text-red-500 hover:text-red-700"
                                >
                                  Eliminar
                                </button>
                              </td>
                            </tr>
                          ))}
                        {checkJSON(9) && returnJSON(9)?.length < 1 && (
                          <tr className="divide-x divide-gray-200">
                            <td
                              className="whitespace-pre-wrap py-4 pl-4 pr-4 text-sm font-medium text-red-500 sm:pl-6"
                              colSpan={2}
                            >
                              No se han definido funciones principales, da click
                              en "+ Función" para agregar una.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="px-4 py-2 mt-8">
            <p className="text-gray-900 font-medium">
              V. INTERACCIÓN CON OTRAS ÁREAS.
            </p>
            <div className="mt-4 flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr className="divide-x divide-gray-200">
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Actividad
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Entregable
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Frecuencia
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Puestos
                          </th>
                          <th
                            scope="col"
                            className="w-36 py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-6"
                          >
                            <button
                              onClick={newPositionInteraction}
                              className="px-3 py-2 rounded-lg bg-green-200 text-green-700 hover:bg-green-300 flex items-center text-sm mx-auto"
                            >
                              <PlusIcon className="h-4 w-4 mr-1" /> Interacción
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {checkJSON(11) &&
                          returnJSON(11)?.map((item, key) => (
                            <tr
                              className="divide-x divide-gray-200"
                              key={`tr-key-actividad-${key}`}
                            >
                              <td className="whitespace-pre-wrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                                {item
                                  ? item.actividad
                                  : 0
                                }
                              </td>
                              <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                                {item
                                  ? item.inputs
                                  : 0
                                }
                              </td>
                              <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                                {item
                                  ? frecuencias.find(
                                      (f) =>
                                        f.frecuencia_id ==
                                        item.frecuencia
                                    )?.frecuencia_label
                                  : "Por definir"
                                }
                              </td>
                              <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                                {item
                                  ? item.puestos?.map((p) => p.puesto_nombre)
                                      .join()
                                  : "Por definir"
                                }
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm sm:pr-6 text-center">
                                <button
                                  onClick={(e) => removeFromArray(11, key)}
                                  className="text-red-500 hover:text-red-700"
                                >
                                  Eliminar
                                </button>
                              </td>
                            </tr>
                          ))}
                        {checkJSON(11) && returnJSON(11)?.length < 1 && (
                          <tr className="divide-x divide-gray-200">
                            <td
                              className="whitespace-pre-wrap py-4 pl-4 pr-4 text-sm font-medium text-red-500 sm:pl-6"
                              colSpan={2}
                            >
                              No se han definido interacciones con otras áreas,
                              da click en "+ Interacción" para agregar una.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="text-red-900 px-4 py-2 flex justify-between items-center mt-8">
            <p>
              <span className="text-red-900 text-2xl font-extrabold">
                PERFIL DEL PUESTO{" "}
              </span>
              <span className=" text-2xl font-extrabold">
                / {ap.puesto_nombre}
              </span>
            </p>
            <img src={logo} alt="" className="h-10 w-10" />
          </div>

          <div className="px-4 py-2 mt-8">
            <p className="text-gray-900 font-medium">
              VI. REQUISITOS GENERALES.
            </p>
            <p className="text-gray-600 text-sm">
              Especifique los requisitos generales para desempeñar el puesto.
            </p>
            <div className="mt-4">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-6 gap-10">
                <div className="mt-4 col-span-2">
                  <label
                    htmlFor="boss-position"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Rango de edad:
                  </label>
                  <AgeRange
                    onChange={(data) =>
                      toggleAnswers(
                        ap.tra_ap_usuario_id,
                        1,
                        data.join(" - "),
                        13
                      )
                    }
                    onFinalChange={(e) => onChangeCallback(13, answers[13])}
                    age={
                      answers[13]
                        ? answers[13].respuesta_texto.split(" - ")
                        : [18, 60]
                    }
                  />
                </div>
                <div className="mt-4 col-span-2">
                  <label
                    htmlFor="boss-position"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Género:
                  </label>
                  <select
                    id="boss-position"
                    name="boss-position"
                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    onChange={(e) =>
                      toggleSelectAnswers(
                        ap.tra_ap_usuario_id,
                        3,
                        e.target.value,
                        14
                      )
                    }
                    value={answers[14] ? answers[14].respuesta_texto : 0}
                  >
                    <option value={0} disabled>
                      Selecciona una opción
                    </option>
                    {generoOptions.map((puesto) => (
                      <option
                        key={`option-genero-key-${puesto.value}`}
                        value={puesto.value}
                      >
                        {puesto.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mt-4 col-span-2">
                  <label
                    htmlFor="boss-position"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Estado civil:
                  </label>
                  <select
                    id="boss-position"
                    name="boss-position"
                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    onChange={(e) =>
                      toggleSelectAnswers(
                        ap.tra_ap_usuario_id,
                        3,
                        e.target.value,
                        12
                      )
                    }
                    value={answers[12] ? answers[12].respuesta_texto : 0}
                  >
                    <option value={0} disabled>
                      Selecciona una opción
                    </option>
                    {estadoCivilOptions.map((puesto) => (
                      <option
                        key={`option-estado-civil-key-${puesto.value}`}
                        value={puesto.value}
                      >
                        {puesto.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-span-3">
                  <label
                    htmlFor="boss-position"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Horario:
                  </label>
                  <select
                    id="boss-position"
                    name="boss-position"
                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    onChange={(e) =>
                      toggleSelectAnswers(
                        ap.tra_ap_usuario_id,
                        3,
                        e.target.value,
                        15
                      )
                    }
                    value={answers[15] ? answers[15].respuesta_texto : 0}
                  >
                    <option value={0} disabled>
                      Selecciona una opción
                    </option>
                    {horarioOptions.map((puesto) => (
                      <option
                        key={`option-horario-key-${puesto.value}`}
                        value={puesto.value}
                      >
                        {puesto.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-span-3">
                  <label
                    htmlFor="boss-position"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Requiere viajar:
                  </label>
                  <select
                    id="boss-position"
                    name="boss-position"
                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    onChange={(e) =>
                      toggleSelectAnswers(
                        ap.tra_ap_usuario_id,
                        3,
                        e.target.value,
                        17
                      )
                    }
                    value={answers[17] ? answers[17].respuesta_texto : 0}
                  >
                    <option value={0} disabled>
                      Selecciona una opción
                    </option>
                    {travelOptions.map((puesto) => (
                      <option
                        key={`option-viajar-key-${puesto.value}`}
                        value={puesto.value}
                      >
                        {puesto.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="px-4 py-2 mt-8">
            <p className="text-gray-900 font-medium">
              VII. FORMACIÓN ACADÉMICA.
            </p>
            <p className="text-gray-600 text-sm">
              Especifique los requisitos generales para desempeñar el puesto.
            </p>
            <div className="mt-4">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-6 gap-10">
                <div className="mt-4 col-span-3">
                  <label
                    htmlFor="boss-position"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Escolaridad:
                  </label>
                  <select
                    id="boss-position"
                    name="boss-position"
                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    onChange={(e) =>
                      toggleSelectAnswers(
                        ap.tra_ap_usuario_id,
                        3,
                        e.target.value,
                        19
                      )
                    }
                    value={answers[19] ? answers[19].respuesta_texto : 0}
                  >
                    <option value={0} disabled>
                      Selecciona una opción
                    </option>
                    {escolaridadOptions.map((puesto) => (
                      <option
                        key={`option-escolaridad-key-${puesto.value}`}
                        value={puesto.value}
                      >
                        {puesto.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mt-4 col-span-3">
                  <label
                    htmlFor="boss-position"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Estatus de estudios:
                  </label>
                  <select
                    id="boss-position"
                    name="boss-position"
                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    onChange={(e) =>
                      toggleSelectAnswers(
                        ap.tra_ap_usuario_id,
                        3,
                        e.target.value,
                        20
                      )
                    }
                    value={answers[20] ? answers[20].respuesta_texto : 0}
                  >
                    <option value={0} disabled>
                      Selecciona una opción
                    </option>
                    {estatusEstudiosOptions.map((puesto) => (
                      <option
                        key={`option-estudios-key-${puesto.value}`}
                        value={puesto.value}
                      >
                        {puesto.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <p className="text-gray-900 text-sm text-center mt-6 font-bold">
                Licenciatura(s) y/o Posgrado(s) deseados
              </p>
              <p className="text-gray-600 text-sm text-center">
                Definir las licenciaturas y/o posgrados afines al puesto.
              </p>
              <p className="text-gray-600 text-sm text-center">
                Ejemplo: Licenciatura:
                Psicología/Administración/Pedagogía/Comunicación/Mercadotecnia
                Maestría: Desarrollo Organizacional
              </p>

              <div className="mt-4">
                <TextAreaAutosize
                  id="purpose-input"
                  rows="3"
                  value={answers[26] ? answers[26].respuesta_texto : ""}
                  onBlur={(e) => onChangeCallback(26, answers[26])}
                  onChange={(e) =>
                    toggleAnswers(ap.tra_ap_usuario_id, 1, e.target.value, 26)
                  }
                ></TextAreaAutosize>
              </div>
            </div>
          </div>

          <div className="px-4 py-2 mt-8">
            <p className="text-gray-900 font-medium">
              VIII. EXPERIENCIA REQUERIDA.
            </p>
            <div className="mt-4">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-6 gap-10">
                <div className="mt-4 col-span-3">
                  <label
                    htmlFor="boss-position"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Experiencia:
                  </label>
                  <select
                    id="boss-position"
                    name="boss-position"
                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    onChange={(e) =>
                      toggleSelectAnswers(
                        ap.tra_ap_usuario_id,
                        3,
                        e.target.value,
                        24
                      )
                    }
                    value={answers[24] ? answers[24].respuesta_texto : 0}
                  >
                    <option value={0} disabled>
                      Selecciona una opción
                    </option>
                    {experienceOptions.map((puesto) => (
                      <option
                        key={`option-escolaridad-key-${puesto.value}`}
                        value={puesto.value}
                      >
                        {puesto.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mt-4 col-span-3">
                  <label
                    htmlFor="boss-position"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Años de Experiencia:
                  </label>
                  <select
                    id="boss-position"
                    name="boss-position"
                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    onChange={(e) =>
                      toggleSelectAnswers(
                        ap.tra_ap_usuario_id,
                        3,
                        e.target.value,
                        25
                      )
                    }
                    value={answers[25] ? answers[25].respuesta_texto : 0}
                  >
                    <option value={0} disabled>
                      Selecciona una opción
                    </option>
                    {experienceYearsOptions.map((puesto) => (
                      <option
                        key={`option-estudios-key-${puesto.value}`}
                        value={puesto.value}
                      >
                        {puesto.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <p className="mt-8 text-gray-600 text-sm text-center">
              Especifique la experiencia necesaria para desempeñar el puesto (cargos, giro empresarial, actividades)
              </p>

              <div className="mt-1">
                <TextAreaAutosize
                  id="question-27"
                  rows="3"
                  value={answers[27] ? answers[27].respuesta_texto : ""}
                  onBlur={(e) => onChangeCallback(27, answers[27])}
                  onChange={(e) =>
                    toggleAnswers(ap.tra_ap_usuario_id, 1, e.target.value, 27)
                  }
                ></TextAreaAutosize>
              </div>

              <div className="mt-4 col-span-3">
                <label
                  htmlFor="boss-position"
                  className="block text-sm font-medium text-gray-700"
                >
                  Otro idioma:
                </label>
                <div className="mt-1 flex flex-col">
                  <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr className="divide-x divide-gray-200">
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Idioma
                              </th>
                              <th
                                scope="col"
                                className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Nivel
                              </th>
                              <th
                                scope="col"
                                className="w-36 py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-6"
                              >
                                <button
                                  onClick={newPositionLanguage}
                                  className="px-3 py-2 rounded-lg bg-green-200 text-green-700 hover:bg-green-300 flex items-center text-sm mx-auto"
                                >
                                  <PlusIcon className="h-4 w-4 mr-1" /> Idioma
                                </button>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {checkJSON(22) &&
                              returnJSON(22)?.map((item, key) => (
                                <tr
                                  className="divide-x divide-gray-200"
                                  key={`tr-key-actividad-${key}`}
                                >
                                  <td className="whitespace-pre-wrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                                    {item
                                      ? item.titulo
                                      : 0
                                    }
                                  </td>
                                  <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                                    {item
                                      ? languageLevelOptions.find(c => c.id == item.nivel)?.label
                                      : 0
                                    }
                                  </td>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm sm:pr-6 text-center">
                                    <button
                                      onClick={(e) => removeFromArray(22, key)}
                                      className="text-red-500 hover:text-red-700"
                                    >
                                      Eliminar
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            {(!checkJSON(22) || returnJSON(22)?.length < 1) && (
                              <tr className="divide-x divide-gray-200">
                                <td
                                  className="whitespace-pre-wrap py-4 pl-4 pr-4 text-sm font-medium text-red-500 sm:pl-6"
                                  colSpan={2}
                                >
                                  No se han definido otros idiomas,
                                  da click en "+ Idioma" para agregar uno.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="px-4 py-2 mt-8">
            <p className="text-gray-900 font-medium">
            IX. COMPETENCIAS DEL PUESTO Y NIVEL DE DOMINIO.
            </p>

            <p className="text-gray-900 font-medium mt-4">
            BLANDAS.
            </p>
            <div className="mt-1 flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr className="divide-x divide-gray-200">
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Competencia
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Nivel
                          </th>
                          <th
                            scope="col"
                            className="w-36 py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-6"
                          >
                            <button
                              onClick={newPositionBusinessCompetition}
                              className="px-3 py-2 rounded-lg bg-green-200 text-green-700 hover:bg-green-300 flex items-center text-sm mx-auto"
                            >
                              <PlusIcon className="h-4 w-4 mr-1" /> Competencia
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {checkJSON(29) &&
                          returnJSON(29)?.map((item, key) => (
                            <tr
                              className="divide-x divide-gray-200"
                              key={`tr-key-actividad-${key}`}
                            >
                              <td className="whitespace-pre-wrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                                {item
                                  ? item.titulo
                                  : 0
                                }
                              </td>
                              <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                                {item
                                  ? competenceLevelsOptions.find(c => c.id == item.nivel)?.label
                                  : 0
                                }
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm sm:pr-6 text-center">
                                <button
                                  onClick={(e) => removeFromArray(29, key)}
                                  className="text-red-500 hover:text-red-700"
                                >
                                  Eliminar
                                </button>
                              </td>
                            </tr>
                          ))}
                        {checkJSON(29) && returnJSON(29)?.length < 1 && (
                          <tr className="divide-x divide-gray-200">
                            <td
                              className="whitespace-pre-wrap py-4 pl-4 pr-4 text-sm font-medium text-red-500 sm:pl-6"
                              colSpan={2}
                            >
                              No se han definido competencias blandas,
                              da click en "+ Competencia" para agregar una.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <p className="text-gray-900 font-medium mt-4">
            TÉCNICAS (Procesos, Sistemas, Metodologías, ERP, Software, etc.)
            </p>
            <div className="mt-1 flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr className="divide-x divide-gray-200">
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Competencia
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Nivel
                          </th>
                          <th
                            scope="col"
                            className="w-36 py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-6"
                          >
                            <button
                              onClick={newPositionTechnicalCompetition}
                              className="px-3 py-2 rounded-lg bg-green-200 text-green-700 hover:bg-green-300 flex items-center text-sm mx-auto"
                            >
                              <PlusIcon className="h-4 w-4 mr-1" /> Competencia
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {checkJSON(30) &&
                          returnJSON(30)?.map((item, key) => (
                            <tr
                              className="divide-x divide-gray-200"
                              key={`tr-key-actividad-${key}`}
                            >
                              <td className="whitespace-pre-wrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                                {item
                                  ? item.titulo
                                  : 0
                                }
                              </td>
                              <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                                {item
                                  ? competenceLevelsOptions.find(c => c.id == item.nivel)?.label
                                  : 0
                                }
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm sm:pr-6 text-center">
                                <button
                                  onClick={(e) => removeFromArray(30, key)}
                                  className="text-red-500 hover:text-red-700"
                                >
                                  Eliminar
                                </button>
                              </td>
                            </tr>
                          ))}
                        {checkJSON(30) && returnJSON(30)?.length < 1 && (
                          <tr className="divide-x divide-gray-200">
                            <td
                              className="whitespace-pre-wrap py-4 pl-4 pr-4 text-sm font-medium text-red-500 sm:pl-6"
                              colSpan={2}
                            >
                              No se han definido competencias blandas,
                              da click en "+ Competencia" para agregar una.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <BigModalTemplate ref={newFunctionRef}>
        <div className="flex justify-between items-top mb-8">
          <p className="text-3xl font-medium">Agregar función</p>
          <button
            type="button"
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            onClick={(e) => newFunctionRef.current.hideModal()}
          >
            <span className="sr-only">Cerrar</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="">
          <div className="mb-4">
            <label
              htmlFor="question-title"
              className="block text-xs text-red-500"
            >
              FUNCIÓN
            </label>
            <div className="mt-1">
              <TextAreaAutosize
                id="title"
                rows="3"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              ></TextAreaAutosize>
            </div>
          </div>
          <div className="pt-6">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={(e) => newFunctionRef.current.hideModal()}
                className="rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                Cancelar
              </button>
              <button
                type="button"
                onClick={(e) =>
                  saveNewPositionFunction(ap.tra_ap_usuario_id, {
                    titulo: title,
                    kpi: "",
                  })
                }
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                Agregar
              </button>
            </div>
          </div>
        </div>
      </BigModalTemplate>
      <BigModalTemplate ref={editFunctionRef}>
        <div className="flex justify-between items-top mb-8">
          <p className="text-3xl font-medium">Editar función</p>
          <button
            type="button"
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            onClick={(e) => editFunctionRef.current.hideModal()}
          >
            <span className="sr-only">Cerrar</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="">
          <div className="mb-4">
            <label
              htmlFor="question-title"
              className="block text-xs text-red-500"
            >
              FUNCIÓN
            </label>
            <div className="mt-1">
              <TextAreaAutosize
                id="title"
                rows="3"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              ></TextAreaAutosize>
            </div>
          </div>
          <div className="pt-6">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={(e) => editFunctionRef.current.hideModal()}
                className="rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                Cancelar
              </button>
              <button
                type="button"
                onClick={(e) =>
                  saveEditPositionFunction()
                }
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                Editar
              </button>
            </div>
          </div>
        </div>
      </BigModalTemplate>
      <BigModalTemplate ref={newLanguageRef}>
        <div className="flex justify-between items-top mb-8">
          <p className="text-3xl font-medium">Agregar idioma</p>
          <button
            type="button"
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            onClick={(e) => newLanguageRef.current.hideModal()}
          >
            <span className="sr-only">Cerrar</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="">
          <div className="mb-4">
            <label
              htmlFor="question-title"
              className="block text-xs text-red-500"
            >
              IDIOMA
            </label>
            <div className="mt-1">
              <TextAreaAutosize
                id="title"
                rows="3"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              ></TextAreaAutosize>
            </div>
          </div>
          <div className="mb-4">
            <label
              htmlFor="question-title"
              className="block text-xs text-red-500"
            >
              NIVEL
            </label>
            <div className="mt-1">
            <select
                id="boss-position"
                name="boss-position"
                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                value={level}
                onChange={(e) => setLevel(e.target.value)}
              >
                <option value={0} disabled>
                  Selecciona una opción
                </option>
                {languageLevelOptions.map((puesto) => (
                  <option
                    key={`option-puesto-agregar-idioma-key-${puesto.id}`}
                    value={puesto.value}
                  >
                    {puesto.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="pt-6">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={(e) => newLanguageRef.current.hideModal()}
                className="rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                Cancelar
              </button>
              <button
                type="button"
                onClick={(e) =>
                  saveNewPositionLanguage(ap.tra_ap_usuario_id, {
                    titulo: title,
                    nivel: "",
                  })
                }
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                Agregar
              </button>
            </div>
          </div>
        </div>
      </BigModalTemplate>
      <BigModalTemplate ref={newBusinessCompetitionRef}>
        <div className="flex justify-between items-top mb-8">
          <p className="text-3xl font-medium">Agregar competencia 3B</p>
          <button
            type="button"
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            onClick={(e) => newBusinessCompetitionRef.current.hideModal()}
          >
            <span className="sr-only">Cerrar</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="">
          <div className="mb-4">
            <label
              htmlFor="question-title"
              className="block text-xs text-red-500"
            >
              TIPO DE COMPETENCIA
            </label>
            <div className="mt-1">
              <select
                id="boss-position"
                name="boss-position"
                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                value={competenceType}
                onChange={(e) => setCompetenceType(e.target.value)}
              >
                <option value={1}>
                  3B
                </option>
                <option value={2}>
                  Cultura
                </option>
                <option value={3}>
                  Compras
                </option>
                <option value={"TYPE2"}>
                  Otra...
                </option>
              </select>
            </div>
          </div>
          {competenceType == "1" && <div className="mb-4">
            <label
              htmlFor="question-title"
              className="block text-xs text-red-500"
            >
              COMPETENCIA
            </label>
            <div className="mt-1">
              <select
                id="boss-position"
                name="boss-position"
                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                value={competence}
                onChange={(e) => setCompetence(e.target.value)}
              >
                <option value={0} disabled>
                  Selecciona una opción
                </option>
                {competencesOptions.map((puesto) => (
                  <option
                    key={`option-puesto-agregar-idioma-key-${puesto.id}`}
                    value={puesto.value}
                  >
                    {puesto.label}
                  </option>
                ))}
              </select>
            </div>
          </div>}
          {competenceType == "2" && <div className="mb-4">
            <label
              htmlFor="question-title"
              className="block text-xs text-red-500"
            >
              COMPETENCIA
            </label>
            <div className="mt-1">
              <select
                id="boss-position"
                name="boss-position"
                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                value={competence}
                onChange={(e) => setCompetence(e.target.value)}
              >
                <option value={0} disabled>
                  Selecciona una opción
                </option>
                {competencesCultureOptions.map((puesto) => (
                  <option
                    key={`option-puesto-agregar-idioma-key-${puesto.id}`}
                    value={puesto.value}
                  >
                    {puesto.label}
                  </option>
                ))}
              </select>
            </div>
          </div>}
          {competenceType == "3" && <div className="mb-4">
            <label
              htmlFor="question-title"
              className="block text-xs text-red-500"
            >
              COMPETENCIA
            </label>
            <div className="mt-1">
              <select
                id="boss-position"
                name="boss-position"
                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                value={competence}
                onChange={(e) => setCompetence(e.target.value)}
              >
                <option value={0} disabled>
                  Selecciona una opción
                </option>
                {competencesSalesOptions.map((puesto) => (
                  <option
                    key={`option-puesto-agregar-idioma-key-${puesto.id}`}
                    value={puesto.value}
                  >
                    {puesto.label}
                  </option>
                ))}
              </select>
            </div>
          </div>}
          {competenceType == "TYPE2" && <div className="mb-4">
            <label
              htmlFor="question-title"
              className="block text-xs text-red-500"
            >
              OTRA COMPETENCIA
            </label>
            <div className="mt-1">
              <TextAreaAutosize
                id="title"
                rows="3"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              ></TextAreaAutosize>
            </div>
          </div>}
          <div className="mb-4">
            <label
              htmlFor="question-title"
              className="block text-xs text-red-500"
            >
              NIVEL
            </label>
            <div className="mt-1">
            <select
                id="boss-position"
                name="boss-position"
                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                value={level}
                onChange={(e) => setLevel(e.target.value)}
              >
                <option value={0} disabled>
                  Selecciona una opción
                </option>
                {competenceLevelsOptions.map((puesto) => (
                  <option
                    key={`option-puesto-agregar-idioma-key-${puesto.id}`}
                    value={puesto.value}
                  >
                    {puesto.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="pt-6">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={(e) => newBusinessCompetitionRef.current.hideModal()}
                className="rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                Cancelar
              </button>
              <button
                type="button"
                onClick={(e) =>
                  saveNewPositionBusinessCompetition(ap.tra_ap_usuario_id, {
                    titulo: competence,
                    nivel: level,
                  })
                }
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                Agregar
              </button>
            </div>
          </div>
        </div>
      </BigModalTemplate>
      <BigModalTemplate ref={newTechnicalCompetitionRef}>
        <div className="flex justify-between items-top mb-8">
          <p className="text-3xl font-medium">Agregar competencia técnica</p>
          <button
            type="button"
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            onClick={(e) => newTechnicalCompetitionRef.current.hideModal()}
          >
            <span className="sr-only">Cerrar</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="">
          <div className="mb-4">
            <label
              htmlFor="question-title"
              className="block text-xs text-red-500"
            >
              COMPETENCIA
            </label>
            <div className="mt-1">
              <TextAreaAutosize
                id="title"
                rows="3"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              ></TextAreaAutosize>
            </div>
          </div>
          <div className="mb-4">
            <label
              htmlFor="question-title"
              className="block text-xs text-red-500"
            >
              NIVEL
            </label>
            <div className="mt-1">
            <select
                id="boss-position"
                name="boss-position"
                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                value={level}
                onChange={(e) => setLevel(e.target.value)}
              >
                <option value={0} disabled>
                  Selecciona una opción
                </option>
                {competenceLevelsOptions.map((puesto) => (
                  <option
                    key={`option-puesto-agregar-idioma-key-${puesto.id}`}
                    value={puesto.value}
                  >
                    {puesto.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="pt-6">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={(e) => newTechnicalCompetitionRef.current.hideModal()}
                className="rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                Cancelar
              </button>
              <button
                type="button"
                onClick={(e) =>
                  saveNewPositionTechnicalCompetition(ap.tra_ap_usuario_id, {
                    titulo: title,
                    nivel: "",
                  })
                }
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                Agregar
              </button>
            </div>
          </div>
        </div>
      </BigModalTemplate>
      <BigModalTemplate ref={newPositionRef}>
        <div className="flex justify-between items-top mb-8">
          <p className="text-3xl font-medium">Agregar puesto</p>
          <button
            type="button"
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            onClick={(e) => newPositionRef.current.hideModal()}
          >
            <span className="sr-only">Cerrar</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="">
          <div className="mb-4">
            <label
              htmlFor="question-title"
              className="block text-xs text-red-500"
            >
              PUESTO
            </label>
            <div className="mt-1">
              <select
                id="boss-position"
                name="boss-position"
                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                value={newPositionSelected}
                onChange={(e) => setNewPositionSelected(e.target.value)}
              >
                <option value={0} disabled>
                  Selecciona una opción
                </option>
                {positionsAll.map((puesto) => (
                  <option
                    key={`option-puesto-agregar-key-${puesto.puesto_region_area_id}-${puesto.puesto_id}`}
                    value={puesto.puesto_id}
                  >
                    {puesto.puesto_nombre}
                    {positionsAll.filter(
                      (p) => p.puesto_nombre === puesto.puesto_nombre
                    )?.length > 1
                      ? `(${puesto.puesto_region_area_id})`
                      : ""}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="pt-6">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={(e) => newPositionRef.current.hideModal()}
                className="rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                Cancelar
              </button>
              <button
                type="button"
                onClick={(e) =>
                  saveNewPositionPosition(ap.tra_ap_usuario_id)
                }
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                Agregar
              </button>
            </div>
          </div>
        </div>
      </BigModalTemplate>
      <BigModalTemplate ref={newInteractionRef}>
        <div className="flex justify-between items-top mb-8">
          <p className="text-3xl font-medium">Agregar Interacción</p>
          <button
            type="button"
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            onClick={(e) => newInteractionRef.current.hideModal()}
          >
            <span className="sr-only">Cerrar</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="">
          <div className="mb-4">
            <label
              htmlFor="question-title"
              className="block text-xs text-red-500"
            >
              ACTIVIDAD
            </label>
            <div className="mt-1">
              <TextAreaAutosize
                id="title"
                rows="3"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              ></TextAreaAutosize>
            </div>
          </div>
          <div className="mb-4">
            <label
              htmlFor="question-title"
              className="block text-xs text-red-500"
            >
              ENTREGABLE
            </label>
            <div className="mt-1">
              <TextAreaAutosize
                id="title"
                rows="3"
                value={attachments}
                onChange={(e) => setAttachments(e.target.value)}
              ></TextAreaAutosize>
            </div>
          </div>
          <div className="mb-4">
            <label
              htmlFor="question-title"
              className="block text-xs text-red-500"
            >
              FRECUENCIA
            </label>
            <div className="mt-1">
              <select
                id="frequency"
                name="frequency"
                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                onChange={(e) => setFrequency(e.target.value)}
                value={frequency}
              >
                <option value={0} disabled>
                  Selecciona una opción
                </option>
                {frecuencias.map((puesto) => (
                  <option
                    key={`option-frequency-key-${puesto.frecuencia_id}`}
                    value={puesto.frecuencia_id}
                  >
                    {puesto.frecuencia_label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mb-4">
            <label
              htmlFor="question-title"
              className="block text-xs text-red-500"
            >
              PUESTOS
            </label>
            <div className="mt-1">
              <Select
                isMulti
                name="colors"
                options={positionsAll}
                className=""
                defaultValue={positionSelected}
                onChange={setPositionSelected}
                classNamePrefix=""
                placeholder="Selecciona uno o más puestos"
              />
            </div>
          </div>
          <div className="pt-6">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={(e) => newInteractionRef.current.hideModal()}
                className="rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                Cancelar
              </button>
              <button
                type="button"
                onClick={(e) =>
                  saveNewPositionInteraction(ap.tra_ap_usuario_id)
                }
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                Agregar
              </button>
            </div>
          </div>
        </div>
      </BigModalTemplate>
    </>
  );
};

const AgeRange = ({ age, onChange, onFinalChange }) => {
  const [values, setValues] = useState(age);
  const STEP = 1;
  const MIN = 18;
  const MAX = 60;
  useEffect(() => {
    setValues(age);
  }, [age]);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
      }}
    >
      <Range
        values={values}
        step={STEP}
        min={MIN}
        max={MAX}
        rtl={false}
        onChange={(values) => {
          setValues(values);
          onChange(values);
        }}
        onFinalChange={onFinalChange}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "29px",
              display: "flex",
              width: "100%",
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "5px",
                width: "100%",
                borderRadius: "4px",
                background: getTrackBackground({
                  values,
                  colors: ["#ccc", "#EE242F", "#ccc"],
                  min: MIN,
                  max: MAX,
                  rtl: false,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ index, props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "24px",
              width: "24px",
              borderRadius: "50%",
              backgroundColor: "#FFF",
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor: isDragged ? "#EE242F" : "#fff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 2px 6px #AAA",
              fontWeight: "bold",
              fontSize: "14px",
              fontFamily: "Arial,Helvetica Neue,Helvetica,sans-serif",
            }}
          >
            {values[index]}
          </div>
        )}
      />
    </div>
  );
};

AgeRange.propTypes = {
  age: PropTypes.array,
};

AgeRange.defaultProps = {
  age: [18, 60],
};

export default UpdatePositionProfileFormat;
