import { useCallback, useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRef } from "react";
import logo from "./../../assets/img/brand/logo.svg";
import API from "../../services/API";
import { StarIcon } from "@heroicons/react/24/solid";
import RequestPositionProfileChanges from "../../components/modals/RequestPositionProfileChanges";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const PrintPositionProfile = () => {
  const navigate = useNavigate();
  const pathParams = useParams();
  const { positionId, printType, assessmentId } = pathParams;
  const { user, token } = useSelector((state) => state.authentication);
  let printComponent = useRef();
  let changesModalRef = useRef();

  const [sectionActive, setSectionActive] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [answers, setAnswers] = useState({});
  const [assessmentUser, setAssessmentUser] = useState({});
  const [ap, setAP] = useState({});
  const [areas, setAreas] = useState([]);
  const [puestos, setPuestos] = useState([]);

  let query = useQuery();

  let superAdministrador = user?.certificados.filter(c => c.cat_permiso_id == 9 && c.estatus == 1)?.length > 0;
  let administradorRegional = user?.certificados.filter(c => c.cat_permiso_id == 6 && c.estatus == 1)?.length > 0;

  useEffect(()=>{
    const fetchAPs = (ne) => {
      setLoading(true);
      let params = { dp_usuario_id: ne }
      const options = { params, headers: { Authorization: token } };
      API.get('/dp/formats/users', options)
      .then( response => {
        setLoading(false);
        if(response){
          const { data, status } = response;
          if(status===200){
            setAP(data);
          } else {
            setAP({});
          }
        } else {
          setAP({});
        }
      })
      .catch( error => {
        setAP({});
        setLoading(false);
      });
    }
    if(positionId){
      fetchAPs(positionId);
    }
  }, [positionId]);

  useEffect(()=>{
    const fetchAPAnswers = (ne) => {
      setLoading(true);
      let params = { tra_ap_id: ne }
      const options = { params, headers: { Authorization: token } };
      API.get('/dp/formats/answers', options)
      .then( response => {
        setLoading(false);
        if(response){
          const { data, status } = response;
          if(status===200){
            let transformed = {}
            for (let obj of Object.entries(data)) {
              let value = obj[1];
              let setValue = value;
              if(setValue.tipo_respuesta_id===2){
                setValue.respuesta_texto = JSON.parse(setValue.respuesta_texto);
                setValue.respuesta_json = JSON.parse(setValue.respuesta_json);
              }
              transformed[setValue.pregunta_id] = setValue;
            }
            setAnswers(transformed);
          } else {
            setAnswers({});
          }
        } else {
          setAnswers({});
        }
      })
      .catch( error => {
        setAnswers({});
        setLoading(false);
      });
    }
    if(ap.tra_ap_id){
      fetchAPAnswers(ap.tra_ap_id);
    }
  }, [ap]);

  useEffect(()=>{
    let params = {};
    const options = { params, headers: { Authorization: token } };
    API.get('/areas',options)
    .then(({ data, status}) => {
      if(status===200){
        setAreas(data.map( f =>{
          f.id = f.area_id;
          f.label = f.area_nombre;
          return f;
        }))
      }
    })
    API.get('/positions',options)
    .then(({ data, status}) => {
      if(status===200){
        setPuestos(data.map( f =>{
          f.id = f.puesto_id;
          f.label = f.puesto_nombre;
          return f;
        }))
      }
    })
  },[])

  const reactToPrintContent = useCallback(() => {
    return printComponent.current;
  }, [printComponent.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: ap.puesto_nombre,
    removeAfterPrint: true,
    copyStyles: true,
    pageStyle: "{ size: letter; margin: 0mm; }",
  });


  const checkJSON = (pregunta_id) => {
    if(answers[pregunta_id]){
      return true
    } else if (answers[pregunta_id]) {
      return true
    } else {
      return false
    }
  }
  const returnJSON = (pregunta_id) => {
    if(answers[pregunta_id]){
      return answers[pregunta_id].respuesta_json
    } else if (answers[pregunta_id]) {
      return answers[pregunta_id].respuesta_json
    } else {
      return false
    }
  }

  const toggleSectionActive = (section) => {
    if(section==sectionActive){
      setSectionActive("");
    } else {
      setSectionActive(section);
    }
  }

  return (
    <>
      <div className="w-full flex flex-col justify-center items-center px-2 sm:px-4 md:px-8 py-8">
        <div className="w-full max-w-7xl mt-8 flex justify-end gap-4">
          {query.get("changes") == "yes" &&
            <div>
              <button
                type="button"
                onClick={e => changesModalRef.current.showModal()}
                className="col-span-1 flex items-center justify-center rounded-md border border-transparent bg-yellow-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
              >
                Solicitar cambios
              </button>
            </div>
          }
          {superAdministrador &&
            <div>
              <button
                type="button"
                onClick={handlePrint}
                className="col-span-1 flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                Imprimir
              </button>
            </div>
          }
        </div>
        <div className="bg-white max-w-7xl mt-4 shadow border w-full p-3 select-none">
          <div ref={printComponent}>
            <div className="px-4 py-2 flex justify-between items-center">
              <p className="text-red-700 text-lg font-extrabold">
                DESCRIPCIÓN DE PUESTO / {ap.puesto_nombre}
              </p>
              <img
                src={logo}
                alt=""
                className="h-24 w-24"
              />
            </div>
            <div className="px-4 py-6 grid grid-cols-1 md:grid-cols-2 gap-4">
              <p className="text-gray-900 font-bold flex-1">
                <span>Reporta a: </span>
                <span>{(answers[4] ? puestos.find(p => Number(answers[4].respuesta_texto)===Number(p.puesto_id))?.puesto_nombre : 'NA')}</span>
              </p>
              <p className="text-gray-900 font-bold flex-1">
                <span>Supervisa a:&nbsp;
                {
                  checkJSON(5)
                  ? !returnJSON(5)
                    ? "NA"
                    : returnJSON(5).map((item)=>{ return puestos.find(p => Number(item.puesto_id)==Number(p.puesto_id))?.puesto_nombre}).join()
                  : "NA"
                }</span>
              </p>
            </div>
            <div className="border-b-2">
            </div>
            <div className="px-4 py-6 space-y-2">
              <button onClick={e => toggleSectionActive(1)}>
                <p className="text-red-700 text-lg font-bold mb-6 print:text-base">
                  OBJETIVO DEL PUESTO
                </p>
              </button>
              {(superAdministrador || sectionActive == 1) && <p className="text-gray-800 print:text-sm whitespace-pre-wrap">
              {(answers[3] ? answers[3].respuesta_texto : <span className="text-red-500 text-sm">La descripción actual no cuenta con objetivo del puesto, solicita una actualización para definirlo.</span>)}
              </p>}
            </div>
            <div className="border-b-2">
            </div>
            <div className="px-4 py-6 space-y-2">
              <button onClick={e => toggleSectionActive(2)}>
                <p className="text-red-700 text-lg font-bold flex-1 mb-6 print:text-base">
                  RESPONSABILIDADES Y DECISIONES ESPECÍFICAS
                </p>
              </button>
              {(superAdministrador || sectionActive == 2) && <ul className="list-disc list-inside">
                {
                  checkJSON(9)
                  ? !returnJSON(9)
                    ? <span className="text-red-500 text-sm">La descripción actual no cuenta con responsabilidades y decisiones específicas del puesto, solicita una actualización para definirlas.</span>
                    : returnJSON(9).map((item, key)=>(
                      <li className="text-gray-800 leading-8 red-li whitespace-pre-wrap print:text-sm" key={`functions-container-item-key-${key}`}>
                        {item.titulo}
                      </li>
                    ))
                  : <span className="text-red-500 text-sm">La descripción actual no cuenta con responsabilidades y decisiones específicas del puesto, solicita una actualización para definirlas.</span>
                }
              </ul>}
            </div>
            <div className="border-b-2">
            </div>
            <div className="px-4 py-6 space-y-2">
              <button onClick={e => toggleSectionActive(3)}>
                <p className="text-red-700 text-lg font-bold flex-1 mb-6 print:text-base">
                  CULTURA 3B
                </p>
              </button>
              {(superAdministrador || sectionActive == 3) && <p className="text-gray-800 leading-8 print:text-sm">
                <ul className="list-disc list-inside">
                  <li className="red-li">
                      Ser un ejemplo de la <b>Cultura 3B</b> cumpliendo nuestros principios al realizar sus actividades diarias.
                  </li>
                  <li className="red-li">
                      Ser un embajador <b>“No dejar pasar”</b> promoviendo con toda su gente la resolución de problemas, escuchando sus necesidades y propuestas.
                  </li>
                  <li className="red-li">
                      Dar respuesta y solución a las inquietudes y propuestas de “No dejar pasar” que surgen en su equipo.
                  </li>
                  <li className="red-li">
                      Tener “actitud de dueño”, no limitándose a informar si no dar solución de forma oportuna.
                  </li>
                  <li className="red-li">
                      Cumplir con los cuatro pasos del No dejar pasar:
                      <ul className="list-inside">
                          <li className="star-li"><span className="text-red-700">Detectar</span> un problema o sugerir una mejora. </li>
                          <li className="star-li"><span className="text-red-700">Validar o analizar</span> la problemática y su posible solución. </li>
                          <li className="star-li"><span className="text-red-700">Tomar las acciones</span> necesarias y escalar si es necesario. </li>
                          <li className="star-li"><span className="text-red-700">Informar</span> a su Director Regional y responsables y dar seguimiento posterior. </li>
                      </ul>
                  </li>
                </ul>
              </p>}
              {(superAdministrador || sectionActive == 3) && <p className="text-gray-800 leading-8 print:text-sm">Realizar controles de puntos aleatorios evitando buscar errores, evaluando el criterio del colaborador, reconociendo, retroalimentando y generando un plan de acción por escrito con las propuestas y mejoras.</p>}
            </div>
            <div className="border-b-2">
            </div>
            <div className="px-4 py-6 space-y-2"  style={{pageBreakBefore: 'always'}}>
              <button onClick={e => toggleSectionActive(4)}>
                <p className="text-red-700 text-lg font-bold flex-1 mb-6 print:text-base">
                PERFIL DE PUESTO
                </p>
              </button>
              {(superAdministrador || sectionActive == 4) && <div className="flex justify-start items-top space-x-8">
                <p className="text-gray-900 print:text-sm">
                  <span className="text-red-700 font-bold">Edad: </span>
                  <span className="">{(answers[13] ? `${answers[13].respuesta_texto} años` : 'NA')}</span>
                </p>
                <p className="text-gray-900 print:text-sm">
                  <span className="text-red-700 font-bold">Sexo: </span>
                  <span className="">{(answers[14] ? answers[14].respuesta_texto : 'NA')}</span>
                </p>
              </div>}
              {(superAdministrador || sectionActive == 4) && <div className="flex justify-start items-top space-x-8">
                <p className="text-gray-900 print:text-sm">
                  <span className="text-red-700 font-bold">Escolaridad: </span>
                  <span className="">{(answers[26] ? answers[26].respuesta_texto : 'NA')}</span>
                </p>
              </div>}
              {(superAdministrador || sectionActive == 4) && <div className="">
                <p className="text-gray-900 print:text-sm">
                  <span className="text-red-700 font-bold">Idiomas: </span>
                  <span className="">{
                    checkJSON(22)
                    ? !returnJSON(22)
                      ? "NA"
                      : returnJSON(22).map((item, key)=>{
                        return item.titulo + " " + item.nivel
                      }).join()
                    : "NA"
                  }</span>
                </p>
              </div>}
              {(superAdministrador || sectionActive == 4) && <div className="">
                <p className="text-gray-900 print:text-sm">
                  <span className="text-red-700 font-bold">Experiencia: </span>
                  <span className="">{(answers[25] ? answers[25].respuesta_texto : 'NA')}</span>
                </p>
                <p className="text-gray-900 whitespace-pre-wrap">{(answers[27] ? answers[27].respuesta_texto : '')}</p>
              </div>}
            </div>
            <div className="border-b-2">
            </div>
            <div className="px-4 py-6 space-y-2">
              <button onClick={e => toggleSectionActive(5)}>
                <p className="text-red-700 text-lg font-bold flex-1 mb-6 print:text-base">
                HABILIDADES / COMPETENCIAS NECESARIAS
                </p>
              </button>
              {(superAdministrador || sectionActive == 5) && <div className="flex justify-start items-top space-x-8">
                <ul className="">
                  {
                    checkJSON(29)
                    ? !returnJSON(29)
                      ? "NA"
                      : returnJSON(29).map((item, key)=>(
                        <li className="text-gray-900 leading-10 print:text-sm" key={`functions-container-item-key-${key}`}>
                          <p className="flex items-center space-x-4">
                            <span>{item.titulo}</span>
                            <div className="flex space-x-2">
                              <StarIcon className={`h-5 w-5 ${item.nivel > 0 ? "text-red-700" : "text-red-200"}`} />
                              <StarIcon className={`h-5 w-5 ${item.nivel > 1 ? "text-red-700" : "text-red-200"}`} />
                              <StarIcon className={`h-5 w-5 ${item.nivel > 2 ? "text-red-700" : "text-red-200"}`} />
                            </div>
                          </p>
                        </li>
                      ))
                    : "NA"
                  }
                </ul>
              </div>}
            </div>
            <div className="border-b-2">
            </div>
            <div className="px-4 py-6 space-y-2">
              <button onClick={e => toggleSectionActive(6)}>
                <p className="text-red-700 text-lg font-bold flex-1 mb-6 print:text-base">
                COMPETENCIAS TÉCNICAS
                </p>
              </button>
              {(superAdministrador || sectionActive == 6) && <div className="flex justify-start items-top space-x-8">
                <ul className="">
                  {
                    checkJSON(30)
                    ? !returnJSON(30)
                      ? ""
                      : returnJSON(30).map((item, key)=>(
                        <li className="text-gray-900 leading-10 print:text-sm" key={`functions-container-item-key-${key}`}>
                          <p className="flex items-center space-x-4">
                            <span>{item.titulo}</span>
                            <div className="flex space-x-2">
                              <StarIcon className={`h-5 w-5 ${item.nivel > 0 ? "text-red-700" : "text-red-200"}`} />
                              <StarIcon className={`h-5 w-5 ${item.nivel > 1 ? "text-red-700" : "text-red-200"}`} />
                              <StarIcon className={`h-5 w-5 ${item.nivel > 2 ? "text-red-700" : "text-red-200"}`} />
                            </div>
                          </p>
                        </li>
                      ))
                    : ""
                  }
                </ul>
              </div>}
            </div>
            <div className="border-b-2">
            </div>
            <div className="px-4 py-6 space-y-2">
              <p className="text-red-700 text-lg font-bold flex-1 mb-6">
                Rango de sueldo:
              </p>
            </div>
          </div>
        </div>
      </div>
      <RequestPositionProfileChanges ap={ap} ref={changesModalRef} />
    </>
  );
};

export default PrintPositionProfile;
