import { useEffect, useState } from "react";
import { Combobox } from "@headlessui/react";
import API from "../../services/API";
import { useSelector } from "react-redux";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function FindExpertsPalette({ value, callback }) {
  const [query, setQuery] = useState("");
  const [people, setPeople] = useState([]);
  const { token } = useSelector((state) => state.authentication);
  const [loadingUsers, setLoadingUsers] = useState(false);

  useEffect(() => {
    let delayDebounceFn = null;
    if (query.length > 0) {
      setLoadingUsers(true);
      delayDebounceFn = setTimeout(() => {
        const headers = { Authorization: token };
        let params = {
          search: query,
        };
        API.get("/usersearch", { params, headers })
          .then(({ data }) => {
            setPeople(data);
            setLoadingUsers(false);
          })
          .catch((err) => {
            setPeople([]);
            setLoadingUsers(false);
          });
      }, 3000);
    }

    return () => clearTimeout(delayDebounceFn);
  }, [query]);

  return (
    <Combobox as="div" value={value} onChange={callback}>
      <Combobox.Label className="block text-sm font-medium text-gray-700">Assigned to</Combobox.Label>
      <div className="relative mt-1">
        <Combobox.Input
          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(person) => person?.nombre_completo}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {
          loadingUsers
          ? <div className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <p className="relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900">Cargando usuarios</p>
          </div>
          : people.length > 0 && (
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {people.map((person) => (
                <Combobox.Option
                  key={person.numero_empleado}
                  value={person}
                  className={({ active }) =>
                    classNames(
                      'relative cursor-default select-none py-2 pl-8 pr-4',
                      active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <span className={classNames('block truncate', selected && 'font-semibold')}>{person.nombre_completo}</span>

                      {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 left-0 flex items-center pl-1.5',
                            active ? 'text-white' : 'text-indigo-600'
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )
        }
      </div>
    </Combobox>
  );
}
