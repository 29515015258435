/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react";
import { Popover, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import logo from "./../assets/img/brand/logo.png";
import dp from "./../assets/img/brand/dp.png";
import face from "./../assets/img/faces/avatar.png";
import integra from "./../assets/img/brand/integra.png";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { logout } from "../redux/reducers/auth";
import Notification from "../components/notifications/Notification";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const activeClassName = "inline-flex items-center bg-red-800 px-3 py-2 text-sm font-medium text-white rounded";
const inactiveClassName = "inline-flex items-center px-3 py-2 text-sm font-medium hover:text-white text-gray-50 hover:bg-red-700 rounded";

const activeMobileClassName = "block rounded-md px-3 py-2 text-base font-medium text-white bg-t3b hover:bg-gray-100 hover:text-gray-800";
const inactiveMobileClassName = "block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800";

export default function HomeLayout() {
  const location = useLocation();
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const { user, token } = useSelector(state => state.authentication);
  const [notificationMessage, setNotificationMessage] = useState("Hubo un error con el servicio");
  const [notificationTitle, setNotificationTitle] = useState("Error");
  const [notificationType, setNotificationType] = useState("danger");
  const notificationRef = useRef();

  useEffect(() => {
    if (!user) {
      dispatch(logout("Inicia sesión para ingresar al portal"));
      navigate("/", { state: { from: location } });
    } else {
      let certificados = user?.certificados?.filter(c => c.cat_proceso_id == 3 && c.estatus == 1);
      if(certificados?.length < 1){
        dispatch(logout("No tienes permiso para ingresar a este proceso"));
        navigate("/");
      }
      // if (!user.email || user.email=="") {
      //   navigate("/t3b/profile");
      // }
    }
  }, [user]);

  const logoutSession = (e) => {
    e.preventDefault();
    dispatch(logout(null));
    navigate("/");
  }

  const showNotification = (title="Error", message="Hubo un error con el servicio", type="danger") => {
    setNotificationTitle(title);
    setNotificationMessage(message);
    setNotificationType(type);
    if(notificationRef.current){
      notificationRef.current.showNotification();
      setTimeout(()=>{
        notificationRef.current.hideNotification();
      }, 5000)
    }
  }

  let superAdministrador = user?.certificados?.filter(c => c.cat_permiso_id == 9 && c.estatus == 1)?.length > 0;
  let administradorRegional = user?.certificados?.filter(c => [27,28].includes(c.cat_permiso_id) && c.estatus == 1)?.length > 0;

  return (
    <div className="flex flex-col flex-1 min-h-screen">
      <Popover as="nav" className="bg-t3b shadow sticky top-0 z-20">
        {({ open }) => (
          <>
            <div className="px-2 py-2">
              <div className="relative flex flex-1 justify-between">
                <div className="flex gap-2">
                  {/* Menu button */}
                  <div className="left-0 flex items-center sm:hidden">
                    {/* Mobile menu button */}
                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-transparent p-2 text-white hover:bg-white hover:bg-opacity-10 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                      <span className="sr-only">Abrir menú principal</span>
                      {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Popover.Button>
                  </div>

                  <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                    <div className="flex flex-shrink-0 items-center gap-4">
                      <a href={`https://integrarh3b.com/token-login?intsession=${token}`} target={"_self"}>
                        <img
                          className="h-8 w-auto"
                          src={integra}
                          alt="Tiendas3B"
                        />
                      </a>
                      <img
                        className="block h-8 w-auto lg:hidden"
                        src={dp}
                        alt="Descripciones de Puesto"
                      />
                      <img
                        className="hidden h-8 w-auto lg:block"
                        src={dp}
                        alt="Descripciones de Puesto"
                      />
                      <p className='pl-4 text-xl font-medium text-white hidden md:block'>DESCRIPCIONES DE PUESTO</p>
                    </div>
                  </div>
                </div>

                {/* Right section on desktop */}
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                      <div className='flex items-center space-x-2'>
                        <img
                          className="h-9 w-9 rounded-lg bg-white object-cover"
                          src={user?.imagen_perfil ? `https://dfh9lj2viqrbd.cloudfront.net/profile/${user.imagen_perfil}` : logo}
                          alt="Profile"
                        />
                        <div className="hidden md:block">
                          <p className='font-semibold text-white whitespace-nowrap'>{user?.nombre}</p>
                          <p className='text-xs text-white whitespace-nowrap'>{user?.puesto}</p>
                        </div>
                        <Menu.Button className="flex rounded-full items-center bg-white text-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
                          <span className="sr-only">Abrir menu</span>
                          <ChevronDownIcon className='h-6 w-6' />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                onClick={logoutSession}
                                className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-red-500')}
                              >
                                Cerrar sesión
                              </a>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                </div>
              </div>
            </div>
            {(superAdministrador || administradorRegional) && <nav className="hidden lg:flex sm:flex sm:space-x-8 bg-white px-8" aria-label="Global">
              <Link
                to="/t3b/home"
                className={classNames(
                  location.pathname.includes("/t3b/home")
                    ? 'border-red-500 text-gray-900'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'inline-flex items-center border-b-2 px-1 py-3 text-sm font-medium'
                )}
              >
                Inicio
              </Link>
              {(superAdministrador || administradorRegional) && <Link
                to="/t3b/admin/regions"
                className={classNames(
                  location.pathname.includes("/t3b/admin/regions")
                    ? 'border-red-500 text-gray-900'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'inline-flex items-center border-b-2 px-1 py-3 text-sm font-medium'
                )}
              >
                Descripciones
              </Link>}
              {superAdministrador && <Link
                to="/t3b/admin/update-process"
                className={classNames(
                  location.pathname.includes("/t3b/admin/update-process")
                    ? 'border-red-500 text-gray-900'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'inline-flex items-center border-b-2 px-1 py-3 text-sm font-medium'
                )}
              >
                Actualizar
              </Link>}
              {superAdministrador && <Link
                to="/t3b/admin/inbox"
                className={classNames(
                  location.pathname.includes("/t3b/admin/inbox")
                    ? 'border-red-500 text-gray-900'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'inline-flex items-center border-b-2 px-1 py-3 text-sm font-medium'
                )}
              >
                Solicitudes
              </Link>}
              {superAdministrador && <Link
                to="/t3b/admin/users"
                className={classNames(
                  location.pathname.includes("/t3b/admin/users")
                    ? 'border-red-500 text-gray-900'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'inline-flex items-center border-b-2 px-1 py-3 text-sm font-medium'
                )}
              >
                Usuarios
              </Link>}
            </nav>}

            <Transition.Root as={Fragment}>
              <div className="lg:hidden">
                <Transition.Child
                  as={Fragment}
                  enter="duration-150 ease-out"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="duration-150 ease-in"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Popover.Overlay className="fixed inset-0 z-20 bg-black bg-opacity-25" />
                </Transition.Child>

                <Transition.Child
                  as={Fragment}
                  enter="duration-150 ease-out"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="duration-150 ease-in"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Popover.Panel
                    focus
                    className="absolute inset-x-0 top-0 z-30 mx-auto w-full max-w-3xl origin-top transform p-2 transition"
                  >
                    <div className="divide-y divide-gray-200 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="pt-3 pb-2">
                        <div className="flex items-center justify-between px-4">
                          <div>
                            <img
                              className="h-8 w-auto"
                              src={logo}
                              alt="Tiendas3B"
                            />
                          </div>
                          <div className="-mr-2">
                            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-t3b">
                              <span className="sr-only">Cerrar menú</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </Popover.Button>
                          </div>
                        </div>
                        <div className="mt-3 space-y-1 px-2">
                          <Link to="/t3b/home">
                            <Popover.Button
                              as="div"
                              className={
                                location.pathname.includes("/t3b/home")
                                  ? activeMobileClassName
                                  : inactiveMobileClassName
                              }
                            >
                                Inicio
                            </Popover.Button>
                          </Link>
                          {(superAdministrador || administradorRegional) && <Link to="/t3b/admin/regions">
                            <Popover.Button
                              as="div"
                              className={
                                location.pathname.includes("/t3b/admin/regions")
                                  ? activeMobileClassName
                                  : inactiveMobileClassName
                              }
                            >
                                Descripciones
                            </Popover.Button>
                          </Link>}
                          {(superAdministrador) && <Link to="/t3b/admin/update-process">
                            <Popover.Button
                              as="div"
                              className={
                                location.pathname.includes("/t3b/admin/update-process")
                                  ? activeMobileClassName
                                  : inactiveMobileClassName
                              }
                            >
                                Actualizar
                            </Popover.Button>
                          </Link>}
                          {(superAdministrador) && <Link to="/t3b/admin/inbox">
                            <Popover.Button
                              as="div"
                              className={
                                location.pathname.includes("/t3b/admin/inbox")
                                  ? activeMobileClassName
                                  : inactiveMobileClassName
                              }
                            >
                                Solicitudes
                            </Popover.Button>
                          </Link>}
                          {(superAdministrador) && <Link to="/t3b/admin/users">
                            <Popover.Button
                              as="div"
                              className={
                                location.pathname.includes("/t3b/admin/users")
                                  ? activeMobileClassName
                                  : inactiveMobileClassName
                              }
                            >
                                Usuarios
                            </Popover.Button>
                          </Link>}
                        </div>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition.Child>
              </div>
            </Transition.Root>
          </>
        )}
      </Popover>
      <Notification
        title={notificationTitle}
        message={notificationMessage}
        type={notificationType}
        ref={notificationRef}
      />
      <Outlet context={{ showNotification }}/>
    </div>
  );
}
