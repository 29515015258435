import { Fragment, useEffect, useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import API from "../../services/API";
import { useSelector } from "react-redux";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function FindUsersPalette({ callback }) {
  const [query, setQuery] = useState("");
  const [people, setPeople] = useState([]);
  const { user, token } = useSelector((state) => state.authentication);
  const [loadingUsers, setLoadingUsers] = useState(false);

  useEffect(() => {
    let delayDebounceFn = null;
    if (query.length > 0) {
      setLoadingUsers(true);
      delayDebounceFn = setTimeout(() => {
        const headers = { Authorization: token };
        let params = {
          search: query,
        };
        API.get("/usersearch", { params, headers })
          .then(({ data }) => {
            setPeople(data);
            setLoadingUsers(false);
          })
          .catch((err) => {
            setPeople([]);
            setLoadingUsers(false);
          });
      }, 3000);
    }

    return () => clearTimeout(delayDebounceFn);
  }, [query]);

  return (
    <div className="transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
      <Combobox onChange={(person) => callback(person)}>
        <div className="relative">
          <MagnifyingGlassIcon
            className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <Combobox.Input
            className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
            placeholder="Buscar..."
            onChange={(event) => setQuery(event.target.value)}
          />
        </div>

        {people.length > 0 && (
          <Combobox.Options
            static
            className="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800"
          >
            {people.map((person) => (
              <Combobox.Option
                key={`key-numero-boss-${person.numero_empleado}`}
                value={person}
                className={({ active }) =>
                  classNames(
                    "select-none px-4 py-2",
                    active && "bg-red-200 cursor-pointer"
                  )
                }
              >
                {person.nombre_completo} -{" "}
                <span className="text-red-500">{person.puesto}</span>
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}

        {query !== "" && people.length === 0 && (
          <p className={`p-4 text-sm text-gray-500 ${loadingUsers ? "animate-pulse" : ""}`}>
            {loadingUsers
            ? "Cargando usuarios"
            : "Ingresa una búsqueda en el recuadro."}
          </p>
        )}
      </Combobox>
    </div>
  );
}
