import {
  ChevronRightIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/solid';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import API from '../../services/API';
import InfinityLoader from '../../components/loaders/InfinityLoader';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function PositionsRegions() {
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [positions, setPositions] = useState([]);
  const [areas, setAreas] = useState([]);
  const [search, setSearch] = useState([]);
  const [regionsTypes, setRegionsTypes] = useState([]);
  const [openedRegions, setOpenedRegions] = useState([]);
  const [openedAreas, setOpenedAreas] = useState([]);
  const { user, token } = useSelector((state) => state.authentication);

  let isAdmin =
    user?.certificados?.filter(
      (c) => [9].includes(c.cat_permiso_id) && c.estatus == 1
    )?.length > 0;
  let administradorRegional =
    user?.certificados?.filter(
      (c) => [28].includes(c.cat_permiso_id) && c.estatus == 1
    )?.length > 0;

  const fetchPositions = useCallback(() => {
    setLoadingInfo(true);
    const options = { headers: { Authorization: token } };
    API.get('/positions', options)
      .then((result) => {
        setLoadingInfo(false);
        const { data, status } = result;
        if (status == 200) {
          setPositions(data);
        }
      })
      .catch((error) => {
        setLoadingInfo(false);
      });
  }, []);

  const fetchAreas = useCallback(() => {
    const options = { headers: { Authorization: token } };
    API.get('/areas', options)
      .then((result) => {
        const { data, status } = result;
        if (status == 200) {
          setAreas(data);
        }
      })
      .catch((error) => {});
  }, []);

  const fetchRegionsTypes = useCallback(() => {
    const options = { headers: { Authorization: token } };
    API.get('/regions/types', options)
      .then((result) => {
        const { data, status } = result;
        if (status == 200) {
          setRegionsTypes(data);
        }
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    fetchRegionsTypes();
    fetchAreas();
    fetchPositions();
  }, []);

  const toggleRegion = (tipo_id) => {
    let currentRegions = [...openedRegions];
    let index = currentRegions.indexOf(tipo_id);
    if (index < 0) {
      currentRegions.push(tipo_id);
    } else {
      currentRegions.splice(index, 1);
    }
    setOpenedRegions(currentRegions);
  };

  const toggleArea = (area_id) => {
    let currentAreas = [...openedAreas];
    let index = currentAreas.indexOf(area_id);
    if (index < 0) {
      currentAreas.push(area_id);
    } else {
      currentAreas.splice(index, 1);
    }
    setOpenedAreas(currentAreas);
  };

  const getRegionType = (tipo) => {
    if (tipo == 3) {
      return 'YEMA';
    } else if (tipo == 2) {
      return 'REGION';
    } else {
      return 'CORPORATIVO';
    }
  };

  const getRegionsTypes = () => {
    if (isAdmin) {
      return regionsTypes;
    } else if (administradorRegional) {
      return regionsTypes.filter(r => r.tipo_id == 2);
    } else {
      return regionsTypes.filter(r => r.tipo_id == 1);
    }
  }

  return (
    <div className="flex justify-center w-full">
      <div className="px-4 sm:px-6 lg:px-8 py-8 flex-1 max-w-6xl">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              Descripciones de puesto
            </h1>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              {loadingInfo ? (
                <>
                  <InfinityLoader />
                </>
              ) : (
                <div>
                  {isAdmin && (
                    <div className="mt-8">
                      <label
                        htmlFor="usersearch"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Buscar puestos
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm mb-4">
                        <div className="relative flex flex-grow items-stretch focus-within:z-10">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <MagnifyingGlassIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </div>
                          <input
                            type="text"
                            name="usersearch"
                            id="usersearch"
                            autoComplete="off"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            placeholder="Busca por nombre de puesto"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {search.length > 0 ? (
                    <div className="mt-8 flow-root bg-white sm:rounded-lg">
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                                >
                                  Nombre
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                                >
                                  Área
                                </th>
                                <th
                                  scope="col"
                                  className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                                >
                                  <span className="sr-only">Editar</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-white">
                              {positions
                                .filter((pos) =>
                                  pos.puesto_nombre
                                    ?.toString()
                                    .toUpperCase()
                                    .includes(search.toString().toUpperCase())
                                )
                                .map((pos, posIdx) => (
                                  <tr
                                    key={pos.puesto_id}
                                    className={classNames(
                                      posIdx === 0
                                        ? 'border-gray-300'
                                        : 'border-gray-200',
                                      'border-t'
                                    )}
                                  >
                                    <td className="whitespace-nowrap py-4 pl-12 pr-3 text-sm font-medium text-gray-900 sm:pl-12">
                                      {pos.puesto_nombre}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">
                                      {
                                        areas.find(
                                          (a) => a.area_id == pos.area_id
                                        )?.area_nombre
                                      }
                                    </td>
                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                                      <Link
                                        to={`/t3b/admin/regions/${getRegionType(
                                          areas.find(
                                            (a) => a.area_id == pos.area_id
                                          )?.tipo_region_id
                                        )}/areas/${pos.area_id}/positions/${
                                          pos.puesto_id
                                        }?area_nombre=${
                                          areas.find(
                                            (a) => a.area_id == pos.area_id
                                          )?.area_nombre
                                        }&puesto_nombre=${pos.puesto_nombre}`}
                                        className="text-indigo-600 hover:text-indigo-900"
                                      >
                                        Información
                                        <span className="sr-only">
                                          , {pos.puesto_nombre}
                                        </span>
                                      </Link>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="mt-8 flow-root bg-white sm:rounded-lg pb-4">
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                                >
                                  Nombre
                                </th>
                                <th
                                  scope="col"
                                  className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                                >
                                  <span className="sr-only">Editar</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-white">
                              {getRegionsTypes().map((regType) => (
                                <Fragment key={`type-${regType.tipo_id}`}>
                                  <tr
                                    className="border-t border-gray-200 group hover:cursor-pointer"
                                    onClick={(e) =>
                                      toggleRegion(regType.tipo_id)
                                    }
                                  >
                                    <th
                                      colSpan={5}
                                      scope="colgroup"
                                      className="bg-gray-200 group-hover:bg-gray-300 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3 align-middle group-hover:text-indigo-600"
                                    >
                                      <ChevronRightIcon
                                        className={`inline h-5 w-5 ${
                                          openedRegions.includes(
                                            regType.tipo_id
                                          )
                                            ? 'rotate-90 transform'
                                            : ''
                                        }`}
                                      />
                                      {regType.nombre}
                                    </th>
                                  </tr>
                                  {openedRegions.includes(regType.tipo_id) &&
                                    areas
                                      .filter(
                                        (area) =>
                                          area.tipo_region_id == regType.tipo_id
                                      )
                                      .map((area, areaIdx) => (
                                        <Fragment key={`area-${area.area_id}`}>
                                          <tr
                                            className="border-t border-gray-200 group hover:cursor-pointer"
                                            onClick={(e) =>
                                              toggleArea(area.area_id)
                                            }
                                          >
                                            <th
                                              colSpan={5}
                                              scope="colgroup"
                                              className="bg-gray-50 group-hover:bg-gray-100 py-2 pl-8 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-8 align-middle group-hover:text-indigo-600"
                                            >
                                              <ChevronRightIcon
                                                className={`inline h-5 w-5 ${
                                                  openedAreas.includes(
                                                    area.area_id
                                                  )
                                                    ? 'rotate-90 transform'
                                                    : ''
                                                }`}
                                              />
                                              {area.area_nombre}
                                            </th>
                                          </tr>
                                          {openedAreas.includes(area.area_id) &&
                                            positions
                                              .filter(
                                                (pos) =>
                                                  area.area_id == pos.area_id
                                              )
                                              .map((pos, posIdx) => (
                                                <tr
                                                  key={pos.puesto_id}
                                                  className={classNames(
                                                    posIdx === 0
                                                      ? 'border-gray-300'
                                                      : 'border-gray-200',
                                                    'border-t'
                                                  )}
                                                >
                                                  <td className="whitespace-nowrap py-4 pl-12 pr-3 text-sm font-medium text-gray-900 sm:pl-12">
                                                    {pos.puesto_nombre}
                                                  </td>
                                                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                                                    <Link
                                                      to={`/t3b/admin/regions/${getRegionType(
                                                        regType.tipo_id
                                                      )}/areas/${
                                                        pos.area_id
                                                      }/positions/${
                                                        pos.puesto_id
                                                      }?area_nombre=${
                                                        area.area_nombre
                                                      }&puesto_nombre=${
                                                        pos.puesto_nombre
                                                      }`}
                                                      className="text-indigo-600 hover:text-indigo-900"
                                                    >
                                                      Información
                                                      <span className="sr-only">
                                                        {' '}
                                                        de, {pos.puesto_nombre}
                                                      </span>
                                                    </Link>
                                                  </td>
                                                </tr>
                                              ))}
                                        </Fragment>
                                      ))}
                                </Fragment>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
