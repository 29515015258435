import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchUser, logout } from "../redux/reducers/auth";
import { XCircleIcon } from '@heroicons/react/20/solid'
import logo from "./../assets/img/brand/logo.svg";
import { EyeIcon } from "@heroicons/react/24/solid";
export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let location = useLocation();
  const { user, status, error } = useSelector(state => state.authentication)
  const login = (e) => {
    e.preventDefault();
    dispatch(fetchUser({username, password}));
  }

  useEffect(() => {
    if (status === "idle" && user) {
      let certificados = user.certificados.filter(c => c.cat_proceso_id == 3 && c.estatus == 1);
      if(certificados.length > 0){
        let navigationPath = "/t3b/home";
        if(location?.state?.from){
          navigationPath = location.state.from.pathname;
        }
        navigate(navigationPath);
      } else {
        dispatch(logout("No tienes permiso para ingresar a este proceso"));
      }
    }
  }, [user, status, navigate]);

  return (
    <>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img className="mx-auto h-32 w-auto" src={logo} alt="Tiendas 3B" />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Descripciones de puesto
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              Por{" "}
              <a
                href="/#"
                className="font-medium text-red-600 hover:text-red-500"
              >
                IntegraRH
              </a>
            </p>
          </div>
          <form className="mt-8 space-y-6" action="#" method="POST" onSubmit={login}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="username" className="sr-only">
                  Usuario
                </label>
                <input
                  id="username"
                  name="username"
                  type="username"
                  required
                  value={username}
                  onChange={ e => setUsername(e.target.value)}
                  autoComplete="username"
                  className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                  placeholder="Usuario"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Contraseña
                </label>
                <div className="relative">
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    required
                    value={password}
                    onChange={ e => setPassword(e.target.value)}
                    autoComplete="current-password"
                    className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                    placeholder="Contraseña"
                  />
                  <button type="button" className="absolute right-2 top-2 z-10" onClick={e => setShowPassword(!showPassword)}>
                    <EyeIcon className="h-5 w-5 text-gray-400 hover:text-gray-600"/>
                  </button>
                </div>
              </div>
            </div>

            {/* <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Recuerdame
                </label>
              </div>

              <div className="text-sm">
                <a href="#" className="font-medium text-red-600 hover:text-red-500">
                  Forgot your password?
                </a>
              </div>
            </div> */}

            <div className="flex flex-1 items-center justify-between">
              {error && <div className="rounded-md bg-red-50 p-4 flex-1">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">{error}</h3>
                  </div>
                </div>
              </div>}
              {/* <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Recuerdame
                </label>
              </div> */}

              {/* <div className="text-sm">
                <a href="#" className="font-medium text-red-600 hover:text-red-500">
                  Forgot your password?
                </a>
              </div> */}
            </div>

            <div>
              <button
                type="submit"
                disabled={status == "loading"}
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                {status == "loading" ? "Obteniendo credenciales" : 'Inicia Sesión'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
