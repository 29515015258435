import { Fragment, useEffect, useState } from "react";
import {
  Dialog,
  Menu,
  Transition,
} from "@headlessui/react";
import {
  MagnifyingGlassIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import Notification from "../../components/notifications/Notification";
import { useSelector } from "react-redux";
import { useRef } from "react";
import API from "../../services/API";
import PlusFolder from "../../components/vectors/PlusFolder";
import face from "./../../assets/img/faces/avatar.png";
import InfinityLoader from "../../components/loaders/InfinityLoader";
import BigModalTemplate from "../../components/modals/BigModalTemplate";

const sortOptions = [
  { name: "Nombre", href: "#", current: true },
  { name: "Posición", href: "#", current: false },
  { name: "Área", href: "#", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Users() {
  const { user, token } = useSelector((state) => state.authentication);
  const [open, setOpen] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingEditUsers, setLoadingEditUsers] = useState(false);
  const [loadingEditBoss, setLoadingEditBoss] = useState(false);
  const [error, setError] = useState("Hubo un error con el servicio");
  const [currentUser, setCurrentUser] = useState({});
  const [currentBoss, setCurrentBoss] = useState({});
  const [users, setUsers] = useState([]);
  const [usersBoss, setUsersBoss] = useState([]);
  const [statusCredentials, setStatusCredentials] = useState(8);
  const [errorTitle, setErrorTitle] = useState("Error");
  const [search, setSearch] = useState("");
  const [regions, setRegions] = useState([]);
  const [currentCredentials, setCurrentCredentials] = useState([]);
  const [areas, setAreas] = useState([]);
  const [positions, setPositions] = useState([]);
  const notificationRef = useRef();
  const editUserRef = useRef();
  const [employeeRegion, setEmployeeRegion] = useState("");
  const [employeeArea, setEmployeeArea] = useState("");
  const [employeePosition, setEmployeePosition] = useState("");

  useEffect(() => {
    const options = { headers: { Authorization: token } };
    API.get("/regions", options).then(({ data, status }) => {
      if (status === 200) {
        setRegions(
          data.map((f) => {
            f.id = f.region_id;
            f.label = f.region_nombre;
            return f;
          })
        );
      }
    });
  }, []);

  useEffect(() => {
    let params = {};
    if (currentUser.region_id !== 0) {
      params.region = currentUser.region_id;
      const options = { headers: { Authorization: token }, params };
      API.get("/areas", options).then(({ data, status }) => {
        if (status === 200) {
          setAreas(
            data.map((f) => {
              f.id = f.area_id;
              f.label = f.area_nombre;
              return f;
            })
          );
        }
      });
    }
    if (employeeRegion !== "") {
      params.region = employeeRegion;
      const options = { headers: { Authorization: token }, params };
      API.get("/areas", options).then(({ data, status }) => {
        if (status === 200) {
          setAreas(
            data.map((f) => {
              f.id = f.area_id;
              f.label = f.area_nombre;
              return f;
            })
          );
          setEmployeeArea("");
          setEmployeePosition("");
        }
      });
    }
  }, [currentUser.region_id, employeeRegion]);

  useEffect(() => {
    let params = {};
    if (currentUser.region_id && currentUser.area_id) {
      if (currentUser.region_id !== 0) {
        params.region = currentUser.region_id;
      }
      if (currentUser.area_id !== 0) {
        setPositions([]);
        params.area = currentUser.area_id;
        const options = { headers: { Authorization: token }, params };
        API.get("/positions", options).then(({ data, status }) => {
          if (status === 200) {
            setPositions(
              data.map((f) => {
                f.id = f.puesto_id;
                f.label = f.puesto_nombre;
                return f;
              })
            );
          }
        });
      }
    }
    if (employeeRegion && employeeArea) {
      if (employeeRegion !== "") {
        params.region = employeeRegion;
      }
      if (employeeArea !== "") {
        setPositions([]);
        searchUsersWithArea();
        params.area = employeeArea;
        const options = { headers: { Authorization: token }, params };
        API.get("/positions", options).then(({ data, status }) => {
          if (status === 200) {
            setPositions(
              data.map((f) => {
                f.id = f.puesto_id;
                f.label = f.puesto_nombre;
                return f;
              })
            );
          }
        });
        setEmployeePosition("");
      }
    }
  }, [
    currentUser.region_id,
    currentUser.area_id,
    employeeRegion,
    employeeArea,
  ]);

  useEffect(() => {
    if (currentUser.jefe_inmediato_id) {
      const headers = { Authorization: token };
      let params = {
        search: currentUser.jefe_inmediato_id,
      };
      API.get("/usersearch", { params, headers })
        .then(({ data }) => {
          setCurrentBoss(data[0]);
        })
        .catch((err) => {
          setCurrentBoss({});
        });
    }
  }, [currentUser.jefe_inmediato_id]);

  useEffect(() => {
    if (currentUser.numero_empleado) {
      const headers = { Authorization: token };
      let params = {
        proceso: 3
      };
      API.get(`/users/${currentUser.numero_empleado}/credentials`, { params, headers })
        .then(({ status, data }) => {
          if(status==200){
            setCurrentCredentials(data);
            if(data.filter(d => d.cat_permiso_id == 12).length > 0){
              setStatusCredentials(12);
            }
            if(data.filter(d => d.cat_permiso_id == 27).length > 0){
              setStatusCredentials(27);
            }
            if(data.filter(d => d.cat_permiso_id == 28).length > 0){
              setStatusCredentials(28);
            }
            if(data.filter(d => d.cat_permiso_id == 9).length > 0){
              setStatusCredentials(9);
            }
          } else {
            setCurrentCredentials([]);
          }
        })
        .catch((err) => {
          setCurrentCredentials([]);
        });
    }
  }, [currentUser.numero_empleado]);

  const searchUsers = (e) => {
    e.preventDefault();
    const headers = { Authorization: token };
    let params = {
      search: search,
    };
    if (
      user?.certificados?.filter((c) => c.cat_permiso_id == 9 && c.estatus == 1)
        ?.length < 1
    ) {
      params.region = user.region_id;
    }
    setLoadingUsers(true);
    API.get("/usersearch", { params, headers })
      .then(({ data }) => {
        setUsers(data);
        setLoadingUsers(false);
      })
      .catch((err) => {
        setUsers([]);
        setLoadingUsers(false);
      });
  };

  const searchUsersWithArea = () => {
    const headers = { Authorization: token };
    let params = {
      with_area: employeeArea,
      with_region: employeeRegion,
    };
    API.get("/usersearch", { params, headers })
      .then(({ data }) => {
        setUsersBoss(data);
      })
      .catch((err) => {
        setUsersBoss([]);
      });
  };

  const selectUser = (current) => {
    setCurrentUser(current);
    editUserRef.current.showModal();
  };

  const changeCurrentUser = (field, value) => {
    let newCurrentUser = { ...currentUser };
    newCurrentUser[field] = value;
    setCurrentUser(newCurrentUser);
  };

  const updateUser = async () => {
    let body = {
      numero_empleado: currentUser.numero_empleado,
      nombre: currentUser.nombre,
      apellido_paterno: currentUser.apellido_paterno,
      apellido_materno: currentUser.apellido_materno,
      escolaridad: currentUser.escolaridad_id,
      puesto_region_area_id: positions.find(
        (p) => Number(p.puesto_id) === Number(currentUser.puesto_id)
      ).puesto_region_area_id,
      is_admin: currentUser.isAdmin,
      jefe_inmediato_id: currentUser.jefe_inmediato_id,
      is_region_admin: currentUser.is_region_admin,
      nuevo_permiso: statusCredentials,
      proceso: 3,
    };
    const options = {
      headers: { Authorization: token },
    };
    setLoadingEditUsers(true);
    API.patch(`/users/${currentUser.numero_empleado}`, body, options)
      .then(async (result) => {
        setLoadingEditUsers(false);
        editUserRef.current.hideModal();
      })
      .catch((error) => {
        setLoadingEditUsers(false);
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          } else {
            setError(error.response.data);
          }
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al resetear la contraseña"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al resetear la contraseña"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  return (
    <div className="flex-1 max-w-full">
      <main className="py-8">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col">
            <Transition.Root show={open} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-40 sm:hidden"
                onClose={setOpen}
              >
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 z-40 flex">
                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                      <div className="flex items-center justify-between px-4">
                        <h2 className="text-lg font-medium text-gray-900">
                          Filtros
                        </h2>
                        <button
                          type="button"
                          className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close menu</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root>

            <div className="flex sm:flex md:flex lg:flex flex-col sm:flex-col md:flex-row lg:flex-row items-center sm:items-center md:items-center lg:items-center justify-start sm:justify-start md:justify-between lg:justify-between pb-6">
              <div className="sm:flex-auto">
                <h1 className="text-3xl font-bold tracking-tight text-gray-900">
                  Usuarios
                </h1>
                <p className="mt-4 text-sm text-gray-700">
                  Una lista de todos los usuarios dentro de tu región con su
                  puesto en la organización y rol en el sistema.
                </p>
              </div>
            </div>
            <form onSubmit={searchUsers}>
              <label
                htmlFor="usersearch"
                className="block text-sm font-medium text-gray-700"
              >
                Buscar usuarios
              </label>
              <div className="mt-1 flex rounded-md shadow-sm mb-4">
                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <UsersIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="text"
                    name="usersearch"
                    id="usersearch"
                    required
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="block w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="Número de empleado, nombre o apellidos"
                  />
                </div>
                <button
                  type="submit"
                  onClick={searchUsers}
                  className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                >
                  <MagnifyingGlassIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span>Buscar</span>
                </button>
              </div>
            </form>

            {/* Filters */}
            <section
              aria-labelledby="filter-heading"
              className="-my-2 -mx-4 flex-1 sm:-mx-6 lg:-mx-8"
            >
              <h2 id="filter-heading" className="sr-only">
                Filtros
              </h2>

              <div className="">
                <div className="mx-auto flex max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                        Ordenar por
                        <ChevronDownIcon
                          className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute left-0 z-10 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {sortOptions.map((option) => (
                            <Menu.Item key={`key-menu-item-${option.name}`}>
                              {({ active }) => (
                                <a
                                  href={option.href}
                                  className={classNames(
                                    option.current
                                      ? "font-medium text-gray-900"
                                      : "text-gray-500",
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  {option.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </section>
          </div>
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle">
                {loadingUsers ? (
                  <>
                    <InfinityLoader />
                  </>
                ) : (
                  <>
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Nombre
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Posición
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Región
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Estatus
                            </th>
                            <th
                              scope="col"
                              className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                            >
                              <span className="sr-only">Edit</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {users.map((person) => (
                            <tr
                              key={`key-row-employee-${person.numero_empleado}`}
                            >
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                <div className="flex items-center">
                                  <div className="h-10 w-10 flex-shrink-0">
                                    <img
                                      className="h-10 w-10 rounded-full object-cover"
                                      src={
                                        person?.imagen_perfil
                                          ? `https://dfh9lj2viqrbd.cloudfront.net/profile/${person?.imagen_perfil}`
                                          : face
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="ml-4">
                                    <div className="font-medium text-gray-900">
                                      {person.nombre_completo}
                                    </div>
                                    <div className="text-gray-500">
                                      {person.email}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                <div className="text-gray-900">
                                  {person.puesto}
                                </div>
                                <div className="text-gray-500">
                                  {person.area}
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {person.region}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                <span
                                  className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${
                                    person.is_active
                                      ? "bg-green-100 text-green-800"
                                      : "bg-red-100 text-red-800"
                                  }`}
                                >
                                  {person.is_active ? "Activo" : "Inactivo"}
                                </span>
                              </td>
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <button
                                  onClick={(e) => selectUser(person)}
                                  className="text-red-600 hover:text-red-900"
                                >
                                  Ver
                                  <span className="sr-only">
                                    , {person.name}
                                  </span>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    {users.length < 1 && (
                      <div className="text-center mt-3">
                        <PlusFolder />
                        <h3 className="mt-2 text-sm font-medium text-gray-900">
                          No hay usuarios con los filtros seleccionados
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          Utiliza el campo de búsqueda para buscar usuarios.
                        </p>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <BigModalTemplate ref={editUserRef}>
          <div className="flex justify-between items-top mb-8">
            <p className="text-3xl font-medium">Editar usuario</p>
            <button
              type="button"
              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              onClick={(e) => editUserRef.current.hideModal()}
            >
              <span className="sr-only">Cerrar</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-6 gap-3">
            <div className="mb-4 col-span-1 sm:col-span-full">
              <label
                htmlFor="question-title"
                className="block text-xs text-red-500"
              >
                NUMERO EMPLEADO
              </label>
              <div className="mt-1 sm:text-sm">
                {currentUser.numero_empleado}
              </div>
            </div>
            <div className="mb-4 col-span-1 sm:col-span-2">
              <label
                htmlFor="question-title"
                className="block text-xs text-red-500"
              >
                NOMBRE
              </label>
              <div className="mt-1 sm:text-sm">
                {currentUser.nombre}
              </div>
            </div>
            <div className="mb-4 col-span-1 sm:col-span-2">
              <label
                htmlFor="question-title"
                className="block text-xs text-red-500"
              >
                APELLIDO PATERNO
              </label>
              <div className="mt-1 sm:text-sm">
                {currentUser.apellido_paterno}
              </div>
            </div>
            <div className="mb-4 col-span-1 sm:col-span-2">
              <label
                htmlFor="question-title"
                className="block text-xs text-red-500"
              >
                APELLIDO MATERNO
              </label>
              <div className="mt-1 sm:text-sm">
                {currentUser.apellido_materno}
              </div>
            </div>
            <div className="mb-4 col-span-1 sm:col-span-6">
              <label
                htmlFor="question-title"
                className="block text-xs text-red-500"
              >
                EMAIL
              </label>
              <div className="mt-1 sm:text-sm">
              {currentUser.email}
              </div>
            </div>
            <div className="mb-4 col-span-1 sm:col-span-2">
              <label
                htmlFor="question-title"
                className="block text-xs text-red-500"
              >
                REGION
              </label>
              <div className="mt-1 sm:text-sm">
              {currentUser.region}
              </div>
            </div>
            <div className="mb-4 col-span-1 sm:col-span-2">
              <label
                htmlFor="question-title"
                className="block text-xs text-red-500"
              >
                AREA
              </label>
              <div className="mt-1 sm:text-sm">
                {currentUser.area}
              </div>
            </div>
            <div className="mb-4 col-span-1 sm:col-span-2">
              <label
                htmlFor="question-title"
                className="block text-xs text-red-500"
              >
                PUESTO
              </label>
              <div className="mt-1 sm:text-sm">
                {currentUser.puesto}
              </div>
            </div>
            <div className="mb-4 col-span-1 sm:col-span-6">
              <label
                htmlFor="question-title"
                className="block text-xs text-red-500"
              >
                JEFE INMEDIATO
              </label>
              <div className="mt-1 flex items-baseline">
                <div className="mt-1 sm:text-sm mr-8">
                  {currentBoss.nombre_completo}
                </div>
              </div>
            </div>
          </div>
          <div className="py-2">
            <p className="text-xs text-red-500 uppercase">PERMISOS</p>
            <fieldset className="mt-2">
              <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                <div className="flex items-center">
                  <input
                    id={"superadmin"}
                    name="permisos"
                    type="radio"
                    value={9}
                    checked={statusCredentials==9}
                    onChange={e => setStatusCredentials(e.target.value)}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <label htmlFor={"superadmin"} className="ml-3 block text-sm font-medium text-gray-700">
                    Super Administrador
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    id={"adminregional"}
                    name="permisos"
                    type="radio"
                    value={27}
                    checked={statusCredentials==27}
                    onChange={e => setStatusCredentials(e.target.value)}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <label htmlFor={"adminregional"} className="ml-3 block text-sm font-medium text-gray-700">
                    Administrador corporativo
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    id={"colaborador"}
                    name="permisos"
                    type="radio"
                    value={28}
                    checked={statusCredentials==28}
                    onChange={e => setStatusCredentials(e.target.value)}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <label htmlFor={"colaborador"} className="ml-3 block text-sm font-medium text-gray-700">
                    Administrador regional
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    id={"colaborador"}
                    name="permisos"
                    type="radio"
                    value={12}
                    checked={statusCredentials==12}
                    onChange={e => setStatusCredentials(e.target.value)}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <label htmlFor={"colaborador"} className="ml-3 block text-sm font-medium text-gray-700">
                    Colaborador
                  </label>
                </div>
              </div>
            </fieldset>
          </div>
          <div className="pt-6">
            <div className="flex justify-end">
              <button
                type="button"
                disabled={loadingEditUsers}
                onClick={updateUser}
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                {loadingEditUsers ? "Guardando usuario" : "Guardar"}
              </button>
            </div>
          </div>
        </BigModalTemplate>
      </main>
      <Notification
        title={errorTitle}
        message={error}
        type="danger"
        ref={notificationRef}
      />
    </div>
  );
}
