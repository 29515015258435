export const competencesOptions = [
	{
		id: 'Análisis y atención al detalle.',
		value: 'Análisis y atención al detalle.',
		label: 'Análisis y atención al detalle.'
	},
	{
		id: 'Autonomía y autogestión.',
		value: 'Autonomía y autogestión.',
		label: 'Autonomía y autogestión.'
	},
	{
		id: 'Comunicación.',
		value: 'Comunicación.',
		label: 'Comunicación.'
	},
	{
		id: 'Colaboración.',
		value: 'Colaboración.',
		label: 'Colaboración.'
	},
	{
		id: 'Sentido Común y Pensamiento Crítico',
		value: 'Sentido Común y Pensamiento Crítico',
		label: 'Sentido Común y Pensamiento Crítico'
	}
]

export const estadoCivilOptions = [
	{
		id: 'Indistinto',
		value: 'Indistinto',
		label: 'Indistinto'
	},
	{
		id: 'Casado(a)',
		value: 'Casado(a)',
		label: 'Casado(a)'
	},
	{
		id: 'Soltero(a)',
		value: 'Soltero(a)',
		label: 'Soltero(a)'
	},
	{
		id: 'Unión Libre',
		value: 'Unión Libre',
		label: 'Unión Libre'
	}
]

export const generoOptions = [
	{
		id: 'Indistinto',
		value: 'Indistinto',
		label: 'Indistinto'
	},
	{
		id: 'Masculino',
		value: 'Masculino',
		label: 'Masculino'
	},
	{
		id: 'Femenino',
		value: 'Femenino',
		label: 'Femenino'
	}
]

export const horarioOptions = [
	{
		id: 'Tiempo Completo',
		value: 'Tiempo Completo',
		label: 'Tiempo Completo'
	},
	{
		id: 'Medio Tiempo',
		value: 'Medio Tiempo',
		label: 'Medio Tiempo'
	}
]

export const modalidadOptions = [
	{
		id: 'Homeoffice',
		value: 'Homeoffice',
		label: 'Homeoffice'
	},
	{
		id: 'Oficina',
		value: 'Oficina',
		label: 'Oficina'
	},
	{
		id: 'Campo',
		value: 'Campo',
		label: 'Campo'
	},
	{
		id: 'Mixto',
		value: 'Mixto',
		label: 'Mixto'
	}
]

export const travelOptions = [
	{
		id: 'Sí',
		value: 'Sí',
		label: 'Sí'
	},
	{
		id: 'No',
		value: 'No',
		label: 'No'
	},
	{
		id: 'Ocasional',
		value: 'Ocasional',
		label: 'Ocasional'
	}
]

export const weekendOptions = [
	{
		id: 'Sí',
		value: 'Sí',
		label: 'Sí'
	},
	{
		id: 'No',
		value: 'No',
		label: 'No'
	}
]

export const escolaridadOptions = [
	{
		id: 'Secundaria',
		value: 'Secundaria',
		label: 'Secundaria'
	},
	{
		id: 'Bachillerato',
		value: 'Bachillerato',
		label: 'Bachillerato'
	},
	{
		id: 'Carrera Técnica',
		value: 'Carrera Técnica',
		label: 'Carrera Técnica'
	},
	{
		id: 'Licenciatura',
		value: 'Licenciatura',
		label: 'Licenciatura'
	},
	{
		id: 'Maestría',
		value: 'Maestría',
		label: 'Maestría'
	},
	{
		id: 'Doctorado',
		value: 'Doctorado',
		label: 'Doctorado'
	}
]

export const estatusEstudiosOptions = [
	{
		id: 'No concluido',
		value: 'No concluido',
		label: 'No concluido'
	},
	{
		id: 'Pasante',
		value: 'Pasante',
		label: 'Pasante'
	},
	{
		id: 'Título/Certificado',
		value: 'Título/Certificado',
		label: 'Título/Certificado'
	},
	{
		id: 'Cédula Profesional',
		value: 'Cédula Profesional',
		label: 'Cédula Profesional'
	}
]

export const languageOptions = [
	{
		id: 'Sí',
		value: 'Sí',
		label: 'Sí'
	},
	{
		id: 'Deseable',
		value: 'Deseable',
		label: 'Deseable'
	},
	{
		id: 'NA',
		value: 'NA',
		label: 'NA'
	}
]

export const languageLevelOptions = [
	{
		id: 'Básico',
		value: 'Básico',
		label: 'Básico'
	},
	{
		id: 'Intermedio',
		value: 'Intermedio',
		label: 'Intermedio'
	},
	{
		id: 'Avanzado',
		value: 'Avanzado',
		label: 'Avanzado'
	}
]

export const experienceOptions = [
	{
		id: 'Sí',
		value: 'Sí',
		label: 'Sí'
	},
	{
		id: 'Deseable',
		value: 'Deseable',
		label: 'Deseable'
	},
	{
		id: 'No',
		value: 'No',
		label: 'No'
	}
]

export const experienceYearsOptions = [
	{
		id: 'Sin experiencia',
		value: 'Sin experiencia',
		label: 'Sin experiencia'
	},
	{
		id: 'Menos de 1 año',
		value: 'Menos de 1 año',
		label: 'Menos de 1 año'
	},
	{
		id: 'De 1 a 2 años',
		value: 'De 1 a 2 años',
		label: 'De 1 a 2 años'
	},
	{
		id: 'De 2 a 3 años',
		value: 'De 2 a 3 años',
		label: 'De 2 a 3 años'
	},
	{
		id: 'De 3 a 5 años',
		value: 'De 3 a 5 años',
		label: 'De 3 a 5 años'
	},
	{
		id: 'Más de 5 años',
		value: 'Más de 5 años',
		label: 'Más de 5 años'
	}
]

export const competenceLevelsOptions = [
	{
		id: '1',
		value: '1',
		label: 'Desarrollable'
	},
	{
		id: '2',
		value: '2',
		label: 'Competente'
	},
	{
		id: '3',
		value: '3',
		label: 'Experto'
	}
]

export const frecuencias = [
	{
		frecuencia_id: 1,
		frecuencia_label: 'Diaria'
	},
	{
		frecuencia_id: 2,
		frecuencia_label: 'Semanal'
	},
	{
		frecuencia_id: 3,
		frecuencia_label: 'Quincenal'
	},
	{
		frecuencia_id: 4,
		frecuencia_label: 'Mensual'
	},
	{
		frecuencia_id: 5,
		frecuencia_label: 'Bimestral'
	},
	{
		frecuencia_id: 6,
		frecuencia_label: 'Trimestral'
	},
	{
		frecuencia_id: 7,
		frecuencia_label: 'Semestral'
	},
	{
		frecuencia_id: 8,
		frecuencia_label: 'Anual'
	}
]

export const competencesSalesOptions = [
	{
		id: 'Ejecución.',
		value: 'Ejecución.',
		label: 'Ejecución.'
	},
	{
		id: 'Gestión de categoría.',
		value: 'Gestión de categoría.',
		label: 'Gestión de categoría.'
	},
	{
		id: 'Gestión de Compras Regionales.',
		value: 'Gestión de Compras Regionales.',
		label: 'Gestión de Compras Regionales.'
	},
	{
		id: 'Gestión de Comunicación.',
		value: 'Gestión de Comunicación.',
		label: 'Gestión de Comunicación.'
	},
	{
		id: 'Gestión de la Logística.',
		value: 'Gestión de la Logística.',
		label: 'Gestión de la Logística.'
	},
	{
		id: 'Gestión de proveedores.',
		value: 'Gestión de proveedores.',
		label: 'Gestión de proveedores.'
	},
	{
		id: 'Gestión de Riesgo.',
		value: 'Gestión de Riesgo.',
		label: 'Gestión de Riesgo.'
	},
	{
		id: 'Gestión de sus Jefes y PPC.',
		value: 'Gestión de sus Jefes y PPC.',
		label: 'Gestión de sus Jefes y PPC.'
	}
]

export const competencesCultureOptions = [
	{
		id: 'Actitud de Dueño.',
		value: 'Actitud de Dueño.',
		label: 'Actitud de Dueño.'
	},
	{
		id: 'Curiosidad.',
		value: 'Curiosidad.',
		label: 'Curiosidad.'
	},
	{
		id: 'Humildad.',
		value: 'Humildad.',
		label: 'Humildad.'
	},
	{
		id: 'Impacto.',
		value: 'Impacto.',
		label: 'Impacto.'
	},
	{
		id: 'Mejora Continua.',
		value: 'Mejora Continua.',
		label: 'Mejora Continua.'
	},
	{
		id: 'Modelo Hard Discount.',
		value: 'Modelo Hard Discount.',
		label: 'Modelo Hard Discount.'
	},
	{
		id: 'No dejar pasar.',
		value: 'No dejar pasar.',
		label: 'No dejar pasar.'
	},
	{
		id: 'Pasión.',
		value: 'Pasión.',
		label: 'Pasión.'
	},
	{
		id: 'Sinceridad.',
		value: 'Sinceridad.',
		label: 'Sinceridad.'
	},
	{
		id: 'Valentía.',
		value: 'Valentía.',
		label: 'Valentía.'
	}
]