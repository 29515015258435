import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { useNavigate, useParams } from "react-router-dom";
import { useRef } from "react";
import logo from "./../../assets/img/brand/logo.svg";
import API from "../../services/API";
import { CalendarIcon, ChevronLeftIcon, ChevronRightIcon, StarIcon, XMarkIcon } from "@heroicons/react/24/solid";
import RequestPositionProfileChanges from "../../components/modals/RequestPositionProfileChanges";
import BigModalTemplate from "../../components/modals/BigModalTemplate";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import face from "./../../assets/img/faces/avatar.png";
import { getDateLabel } from "../../utils/date_utils";
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/DateRangePicker";
import FindExpertsPalette from "../../components/commandpalettes/FindExpertsPalette";

const PositionProfileHistory = () => {
  const navigate = useNavigate();
  const pathParams = useParams();
  const { user, token } = useSelector((state) => state.authentication);
  let printComponent = useRef();
  let changesModalRef = useRef();
  let deleteFormatRef = useRef();
  let editDatesRef = useRef();
  let addExpertRef = useRef();
  let deleteExpertRef = useRef();

  const [selectedUser, setSelectedUser] = useState(null);
  const [deleteUser, setDeleteUser] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [userType, setUserType] = useState(1);
  const [answers, setAnswers] = useState({});
  const [ap, setAP] = useState({});
  const [areas, setAreas] = useState([]);
  const [experts, setExperts] = useState([]);
  const [puestos, setPuestos] = useState([]);
  const [updateDate, setUpdateDate] = useState([new Date(), new Date()]);
  const [validateDate, setValidateDate] = useState([new Date(), new Date()]);
  const [authorizeDate, setAuthorizeDate] = useState([new Date(), new Date()]);

  useEffect(() => {
    const fetchAPs = () => {
      setLoading(true);
      let params = { tra_ap_id: pathParams.positionProfileId };
      const options = { params, headers: { Authorization: token } };
      API.get("/dp/formats", options)
        .then((response) => {
          setLoading(false);
          if (response) {
            const { data, status } = response;
            if (status === 200) {
              setAP(data);
              setUpdateDate([new Date(data.actualizacion_inicio),new Date(data.actualizacion_inicio)]);
              setValidateDate([new Date(data.validacion_inicio),new Date(data.validacion_inicio)]);
              setAuthorizeDate([new Date(data.autorizacion_inicio),new Date(data.autorizacion_inicio)]);
            } else {
              setAP({});
            }
          } else {
            setAP({});
          }
        })
        .catch((error) => {
          setAP({});
          setLoading(false);
        });
    };
    if (pathParams.positionProfileId) {
      fetchAPs(pathParams.positionProfileId);
      fetchActiveDPUsers(pathParams.positionProfileId);
    }
  }, [pathParams.positionProfileId]);

  const fetchActiveDPUsers = useCallback((tra_ap_id) => {
    let params = {
      tra_ap_id_new: tra_ap_id,
    };
    const options = { params, headers: { Authorization: token } };
    API.get("/dp/formats/users", options)
      .then((response) => {
        const { data, status } = response;
        if (status === 200) {
          setExperts(data);
        } else if (status === 204) {
          setExperts([]);
        } else {
          setExperts([]);
        }
      })
      .catch((err) => {
        setExperts([]);
      });
  }, [token]);

  useEffect(() => {
    const fetchAPAnswers = (ne) => {
      setLoading(true);
      let params = { tra_ap_id: ne };
      const options = { params, headers: { Authorization: token } };
      API.get("/dp/formats/answers", options)
        .then((response) => {
          setLoading(false);
          if (response) {
            const { data, status } = response;
            if (status === 200) {
              let transformed = {};
              for (let obj of Object.entries(data)) {
                let value = obj[1];
                let setValue = value;
                if (setValue.tipo_respuesta_id === 2) {
                  setValue.respuesta_texto = JSON.parse(
                    setValue.respuesta_texto
                  );
                  setValue.respuesta_json = JSON.parse(setValue.respuesta_json);
                }
                transformed[setValue.pregunta_id] = setValue;
              }
              setAnswers(transformed);
            } else {
              setAnswers({});
            }
          } else {
            setAnswers({});
          }
        })
        .catch((error) => {
          setAnswers({});
          setLoading(false);
        });
    };
    if (ap.tra_ap_id) {
      fetchAPAnswers(ap.tra_ap_id);
    }
  }, [ap]);

  useEffect(() => {
    let params = {};
    const options = { params, headers: { Authorization: token } };
    API.get("/areas", options).then(({ data, status }) => {
      if (status === 200) {
        setAreas(
          data.map((f) => {
            f.id = f.area_id;
            f.label = f.area_nombre;
            return f;
          })
        );
      }
    });
    API.get("/positions", options).then(({ data, status }) => {
      if (status === 200) {
        setPuestos(
          data.map((f) => {
            f.id = f.puesto_id;
            f.label = f.puesto_nombre;
            return f;
          })
        );
      }
    });
  }, []);

  const reactToPrintContent = useCallback(() => {
    return printComponent.current;
  }, [printComponent.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: ap.puesto_nombre,
    removeAfterPrint: true,
    copyStyles: true,
    pageStyle: "{ size: letter; margin: 0mm; }",
  });

  const checkJSON = (pregunta_id) => {
    if (answers[pregunta_id]) {
      return true;
    } else if (answers[pregunta_id]) {
      return true;
    } else {
      return false;
    }
  };
  const returnJSON = (pregunta_id) => {
    if (answers[pregunta_id]) {
      return answers[pregunta_id].respuesta_json;
    } else if (answers[pregunta_id]) {
      return answers[pregunta_id].respuesta_json;
    } else {
      return false;
    }
  };

  const sendDeleteDP = (e) => {
    let body = {
      tra_ap_id: ap.tra_ap_id,
      body: {
        usuario_modificacion: user.numero_empleado,
        fecha_modificacion: new Date(),
        estatus_ap_id: 0,
      }
    }
    setLoadingDelete(true);
    const options = { headers: { Authorization: token } };
    API.patch('/dp/formats/users/adps', body, options)
    .then( response => {
      const { status } = response;
      setLoadingDelete(false);
      if(status===200){
        return navigate(-1, { replace: true });

      }
    })
    .catch( error => {
      setLoadingDelete(false);
    })
  }

  const getRoleClassName = (status) => {
    let className = "";
    switch(status){
      case 2:
        className = "bg-green-100 text-green-800";
        break;
      case 3:
        className = "bg-blue-100 text-blue-800";
        break;
      default:
        className = "bg-yellow-100 text-yellow-800";
        break;
    }
    return className;
  };

  const getRoleLabel = (status) => {
    let label = "";
    switch(status){
      case 2:
        label = "Validador";
        break;
      case 3:
        label = "Autorizador";
        break;
      default:
        label = "Experto Funcional";
        break;
    }
    return label;
  };

  const editProcessDates = () => {
    let [ actualizacion_inicio, actualizacion_fin ] = updateDate;
    let [ validacion_inicio, validacion_fin ] = validateDate;
    let [ autorizacion_inicio, autorizacion_fin ] = authorizeDate;
    actualizacion_inicio.setHours(0,0,0);
    actualizacion_fin.setHours(23,59,59);
    validacion_inicio.setHours(0,0,0);
    validacion_fin.setHours(23,59,59);
    autorizacion_inicio.setHours(0,0,0);
    autorizacion_fin.setHours(23,59,59);
    let body = {
      tra_ap_id: pathParams.positionProfileId,
      body: {
        actualizacion_fin,
        actualizacion_inicio,
        validacion_fin,
        validacion_inicio,
        autorizacion_fin,
        autorizacion_inicio,
      },
    };
    const options = { headers: { Authorization: token } };
    setLoadingEdit(true);
    API.patch("/dp/formats/users/adps", body, options)
      .then((response) => {
        const { status } = response;
        setLoadingEdit(false);
        if (status === 200) {
          let newAp = {...ap};
          newAp.actualizacion_fin = actualizacion_fin;
          newAp.actualizacion_inicio = actualizacion_inicio;
          newAp.validacion_fin = validacion_fin;
          newAp.validacion_inicio = validacion_inicio;
          newAp.autorizacion_fin = autorizacion_fin;
          newAp.autorizacion_inicio = autorizacion_inicio;
          setAP(newAp);
          editDatesRef.current.hideModal();
        }
      })
      .catch((err) => {
        setLoadingEdit(false);
      });
  };

  const selectExpert = (person) => {
    setSelectedUser(person);
  }

  const addUser = (selected) => {
    let body = {
      usuario_actualiza_id: selectedUser.numero_empleado,
      tra_ap_id: pathParams.positionProfileId,
      numero_empleado: user.numero_empleado,
      tra_ap_tipo_id: userType,
    };
    const options = { headers: { Authorization: token } };
    setLoadingEdit(true);
    API.post("/dp/formats/users/adps/experts", body, options)
      .then((response) => {
        const { data, status } = response;
        setLoadingEdit(false);
        if (status === 200) {
          setSelectedUser(null);
          setUserType(1);
          fetchActiveDPUsers(pathParams.positionProfileId);
          addExpertRef.current.hideModal();
        }
      })
      .catch((error) => {
        setLoadingEdit(false);
      });
  };

  const selectDeleteUser = (person) => {
    setDeleteUser(person);
    deleteExpertRef.current.showModal();
  };

  const patchDeleteUser = () => {
    let body = {
      numero_empleado: user.numero_empleado,
      estatus_id: 0,
      tra_ap_usuario_id: deleteUser.tra_ap_usuario_id,
    };
    setLoadingDelete(true);
    const options = { headers: { Authorization: token } };
    API.patch("/dp/formats/users/adps/experts", body, options)
      .then((response) => {
        const { status } = response;
        setLoadingDelete(false);
        if (status === 200) {
          fetchActiveDPUsers(pathParams.positionProfileId);
          deleteExpertRef.current.hideModal();
        }
      })
      .catch((error) => {
        setLoadingDelete(false);
      });
  };

  return (
    <>
      <div className="w-full flex flex-col justify-center items-center sm:px-4 md:px-8 py-8">
        <div className="mt-8 mb-4 w-full max-w-7xl px-4 sm:px-0 gap-2 grid grid-cols-1 sm:grid-cols-6">
          <div className="hidden sm:block sm:col-span-3 md:hidden lg:block lg:col-span-3"></div>
          {ap.estatus_ap_id < 4
            ? <button
              type="button"
              onClick={(e) => deleteFormatRef.current.showModal()}
              className="w-full col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-1 flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-3 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            >
              Eliminar
            </button>
            : <div className="w-full col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-1"></div>
          }
          {ap.estatus_ap_id < 4
            ? <button
              type="button"
              onClick={(e) => addExpertRef.current.showModal()}
              className="w-full col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-1 flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-3 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Agregar experto
            </button>
            : <div className="w-full col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-1"></div>
          }
          <button
            type="button"
            onClick={handlePrint}
            className="w-full col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-1 flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-3 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
          >
            Imprimir
          </button>
        </div>
        <div className="my-4 w-full max-w-7xl px-4 sm:px-0">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            {experts.map((expert) => (
              <div
                key={`key-users-format-${expert.tra_ap_usuario_id}`}
                className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 "
              >
                <div className="flex-shrink-0">
                  <img className="h-10 w-10 rounded-full object-cover" src={expert?.actualiza_imagen_perfil ? `https://dfh9lj2viqrbd.cloudfront.net/profile/${expert?.actualiza_imagen_perfil}` : face} alt="" />
                </div>
                <div className="min-w-0 flex-1">
                  <div className="focus:outline-none">
                    <div className="flex items-center space-x-3">
                    <p className="text-sm font-medium text-gray-900">{expert.usuario_actualiza_nombre}</p>
                      <span className={`inline-block flex-shrink-0 rounded-full px-2 py-0.5 text-xs font-medium ${getRoleClassName(expert.tra_ap_tipo_id)}`}>
                        {getRoleLabel(expert.tra_ap_tipo_id)}
                      </span>
                    </div>
                    <p className="truncate text-sm text-gray-500">{expert.usuario_actualiza_puesto}</p>
                    <button onClick={e => selectDeleteUser(expert)} className="font-medium text-red-500 hover:text-red-700 hover:underline">
                      Eliminar
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="my-4 overflow-hidden bg-white shadow sm:rounded-lg w-full max-w-7xl">
          <div className="px-4 py-5 sm:px-6 flex justify-between flex-wrap items-center">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">{ap.puesto_nombre}</h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">Información de las fechas.</p>
            </div>
            {ap.estatus_ap_id < 4 && <div>
              <button
                type="button"
                onClick={(e) => editDatesRef.current.showModal()}
                className="flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 mt-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Editar fechas
              </button>
            </div>}
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Actualización inicio</dt>
                <dd className="mt-1 text-sm text-gray-900">{getDateLabel(ap.actualizacion_inicio)}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Actualización fin</dt>
                <dd className="mt-1 text-sm text-gray-900">{getDateLabel(ap.actualizacion_fin)}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Validación inicio</dt>
                <dd className="mt-1 text-sm text-gray-900">{getDateLabel(ap.validacion_inicio)}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Validación fin</dt>
                <dd className="mt-1 text-sm text-gray-900">{getDateLabel(ap.validacion_fin)}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Autorización inicio</dt>
                <dd className="mt-1 text-sm text-gray-900">{getDateLabel(ap.autorizacion_inicio)}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Autorización fin</dt>
                <dd className="mt-1 text-sm text-gray-900">{getDateLabel(ap.autorizacion_fin)}</dd>
              </div>

            </dl>
          </div>
        </div>
        <div className="bg-white max-w-7xl mt-4 shadow border w-full p-3">
          <div ref={printComponent}>
            <div className="px-4 py-2 flex justify-between items-center">
              <p className="text-red-700 text-lg font-extrabold">
                DESCRIPCIÓN DE PUESTO / {ap.puesto_nombre}
              </p>
              <img src={logo} alt="" className="h-24 w-24" />
            </div>
            <div className="px-4 py-6 grid grid-cols-1 md:grid-cols-2 gap-4">
              <p className="text-gray-900 font-bold flex-1">
                <span>Reporta a: </span>
                <span>
                  {answers[4]
                    ? puestos.find(
                        (p) =>
                          Number(answers[4].respuesta_texto) ===
                          Number(p.puesto_id)
                      )?.puesto_nombre
                    : "NA"}
                </span>
              </p>
              <p className="text-gray-900 font-bold flex-1">
                <span>
                  Supervisa a:&nbsp;
                  {checkJSON(5)
                    ? !returnJSON(5)
                      ? "NA"
                      : returnJSON(5)
                          .map((item) => {
                            return puestos.find(
                              (p) =>
                                Number(item.puesto_id) == Number(p.puesto_id)
                            )?.puesto_nombre;
                          })
                          .join()
                    : "NA"}
                </span>
              </p>
            </div>
            <div className="border-b-2"></div>
            <div className="px-4 py-6 space-y-2">
              <p className="text-red-700 text-lg font-bold mb-6 print:text-base">
                OBJETIVO DEL PUESTO
              </p>
              <p className="text-gray-800 print:text-sm">
                {answers[3] ? (
                  answers[3].respuesta_texto
                ) : (
                  <span className="text-red-500 text-sm">
                    La descripción actual no cuenta con objetivo del puesto,
                    solicita una actualización para definirlo.
                  </span>
                )}
              </p>
            </div>
            <div className="border-b-2"></div>
            <div className="px-4 py-6 space-y-2">
              <p className="text-red-700 text-lg font-bold flex-1 mb-6 print:text-base">
                RESPONSABILIDADES Y DECISIONES ESPECÍFICAS
              </p>
              <ul className="list-disc list-inside">
                {checkJSON(9) ? (
                  !returnJSON(9) ? (
                    <span className="text-red-500 text-sm">
                      La descripción actual no cuenta con responsabilidades y
                      decisiones específicas del puesto, solicita una
                      actualización para definirlas.
                    </span>
                  ) : (
                    returnJSON(9).map((item, key) => (
                      <li
                        className="text-gray-800 leading-8 red-li whitespace-pre-wrap print:text-sm"
                        key={`functions-container-item-key-${key}`}
                      >
                        {item.titulo}
                      </li>
                    ))
                  )
                ) : (
                  <span className="text-red-500 text-sm">
                    La descripción actual no cuenta con responsabilidades y
                    decisiones específicas del puesto, solicita una
                    actualización para definirlas.
                  </span>
                )}
              </ul>
            </div>
            <div className="border-b-2"></div>
            <div className="px-4 py-6 space-y-2">
              <p className="text-red-700 text-lg font-bold flex-1 mb-6 print:text-base">
                CULTURA 3B
              </p>
              <ul className="text-gray-800 leading-8 print:text-sm list-disc list-inside">
                <li className="red-li">
                  Ser un ejemplo de la <b>Cultura 3B</b> cumpliendo nuestros
                  principios al realizar sus actividades diarias.
                </li>
                <li className="red-li">
                  Ser un embajador <b>“No dejar pasar”</b> promoviendo con
                  toda su gente la resolución de problemas, escuchando sus
                  necesidades y propuestas.
                </li>
                <li className="red-li">
                  Dar respuesta y solución a las inquietudes y propuestas de
                  “No dejar pasar” que surgen en su equipo.
                </li>
                <li className="red-li">
                  Tener “actitud de dueño”, no limitándose a informar si no
                  dar solución de forma oportuna.
                </li>
                <li className="red-li">
                  Cumplir con los cuatro pasos del No dejar pasar:
                  <ul className="list-inside">
                    <li className="star-li">
                      <span className="text-red-700">Detectar</span> un
                      problema o sugerir una mejora.{" "}
                    </li>
                    <li className="star-li">
                      <span className="text-red-700">Validar o analizar</span>{" "}
                      la problemática y su posible solución.{" "}
                    </li>
                    <li className="star-li">
                      <span className="text-red-700">Tomar las acciones</span>{" "}
                      necesarias y escalar si es necesario.{" "}
                    </li>
                    <li className="star-li">
                      <span className="text-red-700">Informar</span> a su
                      Director Regional y responsables y dar seguimiento
                      posterior.{" "}
                    </li>
                  </ul>
                </li>
              </ul>
              <p className="text-gray-800 leading-8 print:text-sm">
                Realizar controles de puntos aleatorios evitando buscar errores,
                evaluando el criterio del colaborador, reconociendo,
                retroalimentando y generando un plan de acción por escrito con
                las propuestas y mejoras.
              </p>
            </div>
            <div className="border-b-2"></div>
            <div
              className="px-4 py-6 space-y-2"
              style={{ pageBreakBefore: "always" }}
            >
              <p className="text-red-700 text-lg font-bold flex-1 mb-6 print:text-base">
                PERFIL DE PUESTO
              </p>
              <div className="flex justify-start items-top space-x-8">
                <p className="text-gray-900 print:text-sm">
                  <span className="text-red-700 font-bold">Edad: </span>
                  <span className="">
                    {answers[13] ? `${answers[13].respuesta_texto} años` : "NA"}
                  </span>
                </p>
                <p className="text-gray-900 print:text-sm">
                  <span className="text-red-700 font-bold">Sexo: </span>
                  <span className="">
                    {answers[14] ? answers[14].respuesta_texto : "NA"}
                  </span>
                </p>
              </div>
              <div className="flex justify-start items-top space-x-8">
                <p className="text-gray-900 print:text-sm">
                  <span className="text-red-700 font-bold">Escolaridad: </span>
                  <span className="">
                    {answers[26] ? answers[26].respuesta_texto : "NA"}
                  </span>
                </p>
              </div>
              <div className="">
                <p className="text-gray-900 print:text-sm">
                  <span className="text-red-700 font-bold">Idiomas: </span>
                  <span className="">
                    {checkJSON(22)
                      ? !returnJSON(22)
                        ? "NA"
                        : returnJSON(22)
                            .map((item, key) => {
                              return item.titulo + " " + item.nivel;
                            })
                            .join()
                      : "NA"}
                  </span>
                </p>
              </div>
              <div className="">
                <p className="text-gray-900 text-lg print:text-base">
                  <span className="text-red-700 font-bold">Experiencia: </span>
                  <span className="">
                    {answers[25] ? answers[25].respuesta_texto : "NA"}
                  </span>
                </p>
                <p className="text-gray-900">
                  {answers[27] ? answers[27].respuesta_texto : ""}
                </p>
              </div>
            </div>
            <div className="border-b-2"></div>
            <div className="px-4 py-6 space-y-2">
              <p className="text-red-700 text-lg font-bold flex-1 mb-6 print:text-base">
                HABILIDADES / COMPETENCIAS NECESARIAS
              </p>
              <div className="flex justify-start items-top space-x-8">
                <ul className="">
                  {checkJSON(29)
                    ? !returnJSON(29)
                      ? "NA"
                      : returnJSON(29).map((item, key) => (
                          <li
                            className="text-gray-900 leading-10 print:text-sm"
                            key={`functions-container-item-key-${key}`}
                          >
                            <div className="flex items-center space-x-4">
                              <span>{item.titulo}</span>
                              <div className="flex space-x-2">
                                <StarIcon
                                  className={`h-5 w-5 ${
                                    item.nivel > 0
                                      ? "text-red-700"
                                      : "text-red-200"
                                  }`}
                                />
                                <StarIcon
                                  className={`h-5 w-5 ${
                                    item.nivel > 1
                                      ? "text-red-700"
                                      : "text-red-200"
                                  }`}
                                />
                                <StarIcon
                                  className={`h-5 w-5 ${
                                    item.nivel > 2
                                      ? "text-red-700"
                                      : "text-red-200"
                                  }`}
                                />
                              </div>
                            </div>
                          </li>
                        ))
                    : "NA"}
                </ul>
              </div>
            </div>
            <div className="border-b-2"></div>
            <div className="px-4 py-6 space-y-2">
              <p className="text-red-700 text-lg font-bold flex-1 mb-6 print:text-base">
                COMPETENCIAS TÉCNICAS
              </p>
              <div className="flex justify-start items-top space-x-8">
                <ul className="">
                  {checkJSON(30)
                    ? !returnJSON(30)
                      ? ""
                      : returnJSON(30).map((item, key) => (
                          <li
                            className="text-gray-900 leading-10 print:text-sm"
                            key={`functions-container-item-key-${key}`}
                          >
                            <div className="flex items-center space-x-4">
                              <span>{item.titulo}</span>
                              <div className="flex space-x-2">
                                <StarIcon
                                  className={`h-5 w-5 ${
                                    item.nivel > 0
                                      ? "text-red-700"
                                      : "text-red-200"
                                  }`}
                                />
                                <StarIcon
                                  className={`h-5 w-5 ${
                                    item.nivel > 1
                                      ? "text-red-700"
                                      : "text-red-200"
                                  }`}
                                />
                                <StarIcon
                                  className={`h-5 w-5 ${
                                    item.nivel > 2
                                      ? "text-red-700"
                                      : "text-red-200"
                                  }`}
                                />
                              </div>
                            </div>
                          </li>
                        ))
                    : ""}
                </ul>
              </div>
            </div>
            <div className="border-b-2"></div>
            <div className="px-4 py-6 space-y-2">
              <p className="text-red-700 text-lg font-bold flex-1 mb-6">
                Rango de sueldo:
              </p>
            </div>
          </div>
        </div>
      </div>
      <BigModalTemplate ref={deleteFormatRef}>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon
              className="h-6 w-6 text-red-600"
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3
              as="h3"
              className="text-lg font-medium leading-6 text-gray-900"
            >
              Eliminar proceso
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                ¿Deseas eliminar el proceso de actualización?
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={sendDeleteDP}
            disabled={loadingDelete}
          >
            {loadingDelete ? "Eliminando actualización" : "Eliminar"}
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={() => deleteFormatRef.current.hideModal()}
          >
            Cancel
          </button>
        </div>
      </BigModalTemplate>
      <BigModalTemplate ref={editDatesRef}>
        <div className="flex justify-between items-top mb-8">
          <p className="text-3xl font-medium">Editar las fechas del periodo</p>
          <button
            type="button"
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            onClick={(e) => editDatesRef.current.hideModal()}
          >
            <span className="sr-only">Cerrar</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="">
          <div className="mb-2">
            <label
              htmlFor="question-description"
              className="block text-xs text-red-500"
            >
              FECHAS DE ACTUALIZACIÓN
            </label>
            <div className="">
            <DateRangePicker
              onChange={setUpdateDate}
              value={updateDate}
              locale="es"
              calendarIcon={
                <CalendarIcon className="h-5 w-5 text-black" />
              }
              clearIcon={null}
              prevLabel={
                <ChevronLeftIcon className="h-5 w-5 text-white" />
              }
              nextLabel={
                <ChevronRightIcon className="h-5 w-5 text-white" />
              }
            />
            </div>
          </div>
          <div className="mb-2">
            <label
              htmlFor="question-description"
              className="block text-xs text-red-500"
            >
              FECHAS DE VALIDACIÓN
            </label>
            <div className="">
            <DateRangePicker
              onChange={setValidateDate}
              value={validateDate}
              locale="es"
              calendarIcon={
                <CalendarIcon className="h-5 w-5 text-black" />
              }
              clearIcon={null}
              prevLabel={
                <ChevronLeftIcon className="h-5 w-5 text-white" />
              }
              nextLabel={
                <ChevronRightIcon className="h-5 w-5 text-white" />
              }
            />
            </div>
          </div>
          <div className="mb-2">
            <label
              htmlFor="question-description"
              className="block text-xs text-red-500"
            >
              FECHAS DE AUTORIZACIÓN
            </label>
            <div className="">
            <DateRangePicker
              onChange={setAuthorizeDate}
              value={authorizeDate}
              locale="es"
              calendarIcon={
                <CalendarIcon className="h-5 w-5 text-black" />
              }
              clearIcon={null}
              prevLabel={
                <ChevronLeftIcon className="h-5 w-5 text-white" />
              }
              nextLabel={
                <ChevronRightIcon className="h-5 w-5 text-white" />
              }
            />
            </div>
          </div>
          <div className="pt-6">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={(e) => editDatesRef.current.hideModal()}
                className="rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                Cancelar
              </button>
              <button
                type="button"
                disabled={loadingEdit}
                onClick={editProcessDates}
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                {
                  loadingEdit ? "Editando fechas" : "Editar"
                }
              </button>
            </div>
          </div>
        </div>
      </BigModalTemplate>
      <BigModalTemplate ref={addExpertRef}>
        <div className="flex justify-between items-top mb-8">
          <p className="text-3xl font-medium">Agregar experto</p>
          <button
            type="button"
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            onClick={(e) => addExpertRef.current.hideModal()}
          >
            <span className="sr-only">Cerrar</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="">
          <div className="mb-2">
            <label
              htmlFor="user-type"
              className="block text-sm font-medium text-gray-700"
            >
              Usuario
            </label>
            <FindExpertsPalette value={selectedUser} callback={selectExpert} />
          </div>
          <div className="mb-2">
            <label
              htmlFor="user-type"
              className="block text-sm font-medium text-gray-700"
            >
              Tipo de usuario
            </label>
            <select
              id="user-type"
              name="user-type"
              className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              value={userType}
              onChange={(e) => setUserType(e.target.value)}
            >
              <option value={1}>Actualizador</option>
              <option value={2}>Validador</option>
              <option value={3}>Autorizador</option>
            </select>
          </div>
          <div className="pt-6">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={(e) => addExpertRef.current.hideModal()}
                className="rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                Cancelar
              </button>
              <button
                type="button"
                disabled={loadingEdit}
                onClick={addUser}
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                {
                  loadingEdit ? "Agregando experto" : "Agregar"
                }
              </button>
            </div>
          </div>
        </div>
      </BigModalTemplate>
      <BigModalTemplate ref={deleteExpertRef}>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon
              className="h-6 w-6 text-red-600"
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3
              as="h3"
              className="text-lg font-medium leading-6 text-gray-900"
            >
              Eliminar experto
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                ¿Deseas eliminar el experto funcional del proceso?
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={patchDeleteUser}
            disabled={loadingDelete}
          >
            {loadingDelete ? "Eliminando experto" : "Eliminar"}
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={() => deleteExpertRef.current.hideModal()}
          >
            Cancel
          </button>
        </div>
      </BigModalTemplate>
      <RequestPositionProfileChanges ap={ap} ref={changesModalRef} />
    </>
  );
};

export default PositionProfileHistory;
