import {
  ChevronRightIcon,
  ExclamationTriangleIcon,
  HomeIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import InfinityLoader from "../../components/loaders/InfinityLoader";
import BigModalTemplate from "../../components/modals/BigModalTemplate";
import API from "../../services/API";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function PositionsInRegionArea() {
  const { user, token } = useSelector((state) => state.authentication);
  const [loading, setLoading] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(true);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [title, setTitle] = useState("");
  const [positions, setPositions] = useState([]);
  const [currentPosition, setCurrentPosition] = useState({});
  const pathParams = useParams();
  const newPositionRef = useRef();
  const deletePositionRef = useRef();
  let query = useQuery();

  useEffect(() => {
    const params = { type: pathParams.regionType, area: pathParams.areaId };
    const options = { params, headers: { Authorization: token } };
    setLoadingInfo(true);
    API.get("/regions/areas/positions", options).then(({ data, status }) => {
      setLoadingInfo(false);
      if (status === 200) {
        setPositions(
          data.map((f) => {
            f.checked = false;
            return f;
          })
        );
      }
    })
    .catch(error => {
      setLoadingInfo(false);
    });
  }, []);

  const fetchPositions = () => {
    const params = { type: pathParams.regionType, area: pathParams.areaId };
    const options = { params, headers: { Authorization: token } };
    API.get("/regions/areas/positions", options).then(({ data, status }) => {
      if (status === 200) {
        setPositions(
          data.map((f) => {
            f.checked = false;
            return f;
          })
        );
      }
    });
  };

  const postPosition = () => {
    let body = {
      puesto: title,
      area_id: pathParams.areaId,
      region_id: pathParams.regionType === "CORPORATIVO" ? 9990 : 1000,
      usuario_alta: user.numero_empleado,
    };
    const options = { headers: { Authorization: token } };
    setLoading(true);
    API.post("/dp/positions", body, options).then((response) => {
      const { data, status } = response;
      if (status === 200) {
        setLoading(false);
        fetchPositions();
        return newPositionRef.current.hideModal();
      }
    })
    .catch(()=>{
      setLoading(false);
    });
  };

  const seletcToDelete = (position) => {
    setCurrentPosition(position);
    deletePositionRef.current.showModal();
  };

  const deletePosition = () => {
    let body = {
      delete: currentPosition.puesto_id,
      region_id: pathParams.regionType === "CORPORATIVO" ? 9990 : 1000,
      usuario_alta: user.numero_empleado,
    };
    const options = { headers: { Authorization: token } };
    setLoadingDelete(true);
    API.post("/dp/positions", body, options).then((response) => {
      setLoadingDelete(false);
      const { data, status } = response;
      if (status === 200) {
        fetchPositions();
        return deletePositionRef.current.hideModal();
      }
    })
    .catch(()=>{
      setLoadingDelete(false);
    });
  };

  let superAdministrador =
    user?.certificados?.filter((c) => c.cat_permiso_id == 9 && c.estatus == 1)
      ?.length > 0;

  return (
    <div className="w-full">
      <div className="px-4 sm:px-6 lg:px-8 py-8 flex-1 w-full">
        <nav className="flex mb-4" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            <li>
              <div>
                <Link
                  to="/t3b/admin/regions"
                  className="text-gray-400 hover:text-gray-500"
                >
                  <HomeIcon
                    className="h-5 w-5 flex-shrink-0"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Home</span>
                </Link>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon
                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <span className="ml-4 text-sm font-medium text-gray-700">
                  {query.get("area_nombre")}
                </span>
              </div>
            </li>
          </ol>
        </nav>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              {loadingInfo ? (
                <>
                  <InfinityLoader />
                </>
              ) : (
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full">
                    <thead className="bg-white">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Puestos
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          <span className="sr-only">Proceso</span>
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6 text-right"
                        >
                          {superAdministrador && (
                            <button
                              onClick={(e) => newPositionRef.current.showModal()}
                              className="rounded-lg px-3 py-2 bg-green-200 text-green-700 text-sm font-medium"
                            >
                              Nuevo puesto
                            </button>
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {[...positions].map((position, positionIdx) => (
                        <tr
                          key={`key-puesto-${position.puesto_id}`}
                          className={classNames(
                            positionIdx === 0
                              ? "border-gray-300"
                              : "border-gray-200",
                            "border-t"
                          )}
                        >
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-700 sm:pl-6">
                            {position.puesto}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-red-600 sm:pl-6">
                            {position.descripciones_activas > 0
                              ? `En proceso de actualización`
                              : ""}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <div className="flex justify-end gap-8">
                              {superAdministrador && <button onClick={e => seletcToDelete(position)} className="text-red-600 hover:text-red-900">
                                Eliminar
                                <span className="sr-only">
                                  , {position.puesto_nombre}
                                </span>
                              </button>}
                              <Link
                                to={`/t3b/admin/regions/REGION/areas/${
                                  pathParams.areaId
                                }/positions/${
                                  position.puesto_id
                                }?area_nombre=${query.get(
                                  "area_nombre"
                                )}&puesto_nombre=${position.puesto}`}
                                className="text-indigo-600 hover:text-indigo-900"
                              >
                                Información
                                <span className="sr-only">
                                  , {position.puesto_nombre}
                                </span>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <BigModalTemplate ref={newPositionRef}>
        <div className="flex justify-between items-top mb-8">
          <p className="text-3xl font-medium">Nuevo puesto</p>
          <button
            type="button"
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            onClick={(e) => newPositionRef.current.hideModal()}
          >
            <span className="sr-only">Cerrar</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="">
          <div className="mb-4">
            <label
              htmlFor="question-title"
              className="block text-xs text-red-500"
            >
              PUESTO
            </label>
            <div className="mt-1">
              <input
                id="title"
                name="title"
                type="text"
                placeholder="Nombre del puesto..."
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="pt-6">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={(e) => newPositionRef.current.hideModal()}
                className="rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                Cancelar
              </button>
              <button
                type="button"
                disabled={loading}
                onClick={postPosition}
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                {loading ? "Creando puesto" : "Crear"}
              </button>
            </div>
          </div>
        </div>
      </BigModalTemplate>
      <BigModalTemplate ref={deletePositionRef}>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon
              className="h-6 w-6 text-red-600"
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3
              as="h3"
              className="text-lg font-medium leading-6 text-gray-900"
            >
              Eliminar puesto
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                ¿Deseas eliminar el puesto?
                Este será eliminado de los catálogos de puesto, área, región. Pero no de los catálogos maestros.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={deletePosition}
            disabled={loading}
          >
            {loading ? "Eliminando puesto" : "Eliminar"}
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={() => deletePositionRef.current.hideModal()}
          >
            Cancel
          </button>
        </div>
      </BigModalTemplate>
    </div>
  );
}
