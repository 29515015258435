import { useEffect, useState, useCallback, useMemo, Fragment } from "react";
import { useSelector } from "react-redux";
import API from "../../services/API";
import face from "./../../assets/img/faces/avatar.png";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import moment from "moment/moment";
import { UsersIcon } from "@heroicons/react/24/solid";
import HomeUpdater from "../../components/cards/HomeUpdater";
import "moment/locale/es";
import { Dialog, Transition } from "@headlessui/react";

export default function CommonHome() {
  moment.locale("es");
  const { user, token } = useSelector((state) => state.authentication);
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [positions, setPositions] = useState([]);
  const [positionsIndirect, setPositionsIndirect] = useState([]);
  const [organization, setOrganization] = useState({});

  const [aps, setAPs] = useState([]);
  const [positionDPs, setPositionDPs] = useState([]);
  const [hasDP, setHasDP] = useState(null);
  const [open, setOpen] = useState(false);

  let navigate = useNavigate();
  const { showNotification } = useOutletContext();

  useEffect(() => {
    const fetchPositionDPs = (ne) => {
      let params = { numero_empleado: ne };
      const options = { params, headers: { Authorization: token } };
      API.get("/dp/users/position-dps", options)
        .then((response) => {
          if (response) {
            const { data, status } = response;
            if (status === 200) {
              setPositionDPs(data);
            } else if (status === 204) {
              setPositionDPs([]);
            } else {
              setPositionDPs([]);
            }
          } else {
            setPositionDPs([]);
          }
        })
        .catch((error) => {
          setPositionDPs([]);
        });
    };
    const fetchOrganization = (ne) => {
      const options = {
        headers: {
          Authorization: token
        }
      }
      API.get(`/users/${ne}/organization`, options).then(({ data, status }) => {
        if (status === 200) {
          setOrganization(data);
        } else {
          setOrganization({});
        }
      });
    };
    const fetchAPs = (ne) => {
      setLoading(true);
      let params = { numero_empleado: ne };
      const options = { params, headers: { Authorization: token } };
      API.get("/dp/formats/users", options)
        .then((response) => {
          setLoading(false);
          if (response) {
            const { data, status } = response;
            if (status === 200) {
              setAPs(data);
            } else if (status === 204) {
              setAPs([]);
              // fetchPositionDPs(ne);
            } else {
              setAPs([]);
            }
          } else {
            setAPs([]);
          }
        })
        .catch((error) => {
          setAPs([]);
          setLoading(false);
        });
    };

    if (user?.numero_empleado) {
      fetchAPs(user?.numero_empleado);
      fetchOrganization(user?.numero_empleado);
    }
  }, [user?.numero_empleado]);

  useEffect(() => {
    function getIndirectPositions(item, positionsArray) {
      if(!item.tieneColaboradores) {
          return;
      }
      for (let i = 0; i < item.colaboradores.length; i++) {
        let colaborador = item.colaboradores[i];
        let positionIndex = positionsArray.findIndex(
          (p) => p.puesto == colaborador.puesto
        );
        if (positionIndex < 0) {
          let position = {
            puesto: colaborador.puesto,
            puesto_id: colaborador.puesto_id,
            area: colaborador.area,
          };
          positionsArray.push(position);
        }
        getIndirectPositions(colaborador, positionsArray);
      }
    }
    let newPositions = [];
    let newPositionsIndirect = [];
    if(organization.tieneColaboradores){
      for (let i = 0; i < organization.colaboradores.length; i++) {
        let item = organization.colaboradores[i];
        let positionIndex = newPositions.findIndex(
          (p) => p.puesto == item.puesto
        );
        if (positionIndex < 0) {
          let position = {
            puesto: item.puesto,
            puesto_id: item.puesto_id,
            area: item.area,
          };
          newPositions.push(position);
        }
        getIndirectPositions(item, newPositionsIndirect);
      }
      newPositions.sort((a, b) => a.puesto.localeCompare(b.puesto));
      newPositionsIndirect.sort((a, b) => a.puesto.localeCompare(b.puesto));
      setPositions(newPositions);
      setPositionsIndirect(newPositionsIndirect);
    }
  }, [organization]);

  // useEffect(() => {
  //   const fetchEmployees = (users) => {
  //     const params = { users };
  //     const options = { params, headers: { Authorization: token } };
  //     API.get("/usersearch", options)
  //       .then(({ data, status }) => {
  //         if (status === 200) {
  //           setEmployees(data);
  //           let newPositions = [];
  //           for (let i = 0; i < data.length; i++) {
  //             let positionIndex = newPositions.findIndex(
  //               (p) => p.puesto == data[i].puesto
  //             );
  //             if (positionIndex < 0) {
  //               let position = {
  //                 puesto: data[i].puesto,
  //                 puesto_id: data[i].puesto_id,
  //                 area: data[i].area,
  //                 area_id: data[i].area_id,
  //               };
  //               newPositions.push(position);
  //             }
  //           }
  //           // newPositions.push(myPosition);
  //           newPositions.sort((a, b) => a.puesto.localeCompare(b.puesto));
  //           setPositions(newPositions);
  //         } else {
  //           setEmployees([]);
  //         }
  //       })
  //       .catch((error) => {
  //         setEmployees([]);
  //         alert(error.status + " - " + error.message);
  //       });
  //   };
  //   if (user) {
  //     if (user?.colaboradores?.length > 0) {
  //       fetchEmployees(user?.colaboradores?.join());
  //     }
  //   }
  // }, [user]);

  useEffect(() => {
    const EF = [...aps].filter((ap) => [1].includes(Number(ap.tra_ap_tipo_id)));
    const VL = [...aps].filter((ap) => [2].includes(Number(ap.tra_ap_tipo_id)));
    const AU = [...aps].filter((ap) => [3].includes(Number(ap.tra_ap_tipo_id)));
    let url = "";
    if (EF.length > 0) {
      setOpen(true);
      url = "POP-UP-INTEGRA.png";
    }
    if (VL.length > 0) {
      setOpen(true);
      url = "POP-UP-INTEGRA.png";
    }
    if (AU.length > 0) {
      setOpen(true);
      url = "POPUP-AUTORIZADOR.png";
    }
    setHasDP(url);
  }, [aps]);

  const actAPs = useMemo(
    () => [...aps].filter((ap) => [1].includes(Number(ap.tra_ap_tipo_id))),
    [aps]
  );
  const valAPs = useMemo(
    () => [...aps].filter((ap) => [2].includes(Number(ap.tra_ap_tipo_id))),
    [aps]
  );
  const autAPs = useMemo(
    () => [...aps].filter((ap) => [3].includes(Number(ap.tra_ap_tipo_id))),
    [aps]
  );

  const getDatePeriodo = () => {
    let label = "";
    let minDate, maxDate;
    if (actAPs.length > 0) {
      for (let i = 0; i < actAPs.length; i++) {
        let item = actAPs[i];
        if (minDate) {
          if (moment(item.actualizacion_inicio).isBefore(minDate)) {
            minDate = moment(item.actualizacion_inicio);
          }
        } else {
          minDate = moment(item.actualizacion_inicio);
        }

        if (maxDate) {
          if (moment(item.autorizacion_fin).isAfter(maxDate)) {
            maxDate = moment(item.autorizacion_fin);
          }
        } else {
          maxDate = moment(item.autorizacion_fin);
        }
      }
    }
    if (valAPs.length > 0) {
      for (let i = 0; i < valAPs.length; i++) {
        let item = valAPs[i];
        if (minDate) {
          if (moment(item.actualizacion_inicio).isBefore(minDate)) {
            minDate = moment(item.actualizacion_inicio);
          }
        } else {
          minDate = moment(item.actualizacion_inicio);
        }

        if (maxDate) {
          if (moment(item.autorizacion_fin).isAfter(maxDate)) {
            maxDate = moment(item.autorizacion_fin);
          }
        } else {
          maxDate = moment(item.autorizacion_fin);
        }
      }
    }
    if (autAPs.length > 0) {
      for (let i = 0; i < autAPs.length; i++) {
        let item = autAPs[i];
        if (minDate) {
          if (moment(item.actualizacion_inicio).isBefore(minDate)) {
            minDate = moment(item.actualizacion_inicio);
          }
        } else {
          minDate = moment(item.actualizacion_inicio);
        }

        if (maxDate) {
          if (moment(item.autorizacion_fin).isAfter(maxDate)) {
            maxDate = moment(item.autorizacion_fin);
          }
        } else {
          maxDate = moment(item.autorizacion_fin);
        }
      }
    }
    return `${moment(minDate).format("D")} de ${moment(minDate).format(
      "MMMM"
    )} del ${moment(minDate).format("YYYY")} al ${moment(maxDate).format(
      "D"
    )} de ${moment(maxDate).format("MMMM")} del ${moment(maxDate).format(
      "YYYY"
    )}`;
  };

  return (
    <>
      <div className="min-h-full">
        <main className="py-8">
          {loading ? (
            <div className="w-full h-full flex items-center justify-center bg-white rounded-lg">
              <video
                className="w-96 h-96 flex flex-1"
                alt={"Tiendas 3B"}
                muted
                loop
                autoPlay
              >
                <source
                  src={
                    "https://dfh9lj2viqrbd.cloudfront.net/splash/IRHLoading.mp4"
                  }
                  type="video/mp4"
                />
                Sin soporte de video MP4
              </video>
            </div>
          ) : (
            <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
              <h1 className="sr-only">Perfil de usuario</h1>
              <div className="space-y-4">
                <section aria-labelledby="profile-overview-title" className="mb-8">
                  <div className="overflow-hidden rounded-lg bg-white shadow">
                    <h2 className="sr-only" id="profile-overview-title">
                      Perfil de usuario
                    </h2>
                    <div className="bg-white p-6">
                      <div className="sm:flex sm:items-center sm:justify-between">
                        <div className="sm:flex sm:space-x-5">
                          <div className="flex-shrink-0">
                            <img
                              className="mx-auto h-20 w-20 rounded-full object-cover"
                              src={
                                user?.imagen_perfil
                                  ? `https://dfh9lj2viqrbd.cloudfront.net/profile/${user?.imagen_perfil}`
                                  : face
                              }
                              alt=""
                            />
                          </div>
                          <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                            <p className="text-xl font-bold text-gray-900 sm:text-2xl">
                              {user?.nombre_propio}
                            </p>
                            <p className="text-sm font-medium text-red-600">
                              {user?.puesto}
                            </p>
                          </div>
                        </div>
                        <div className="mt-5 flex justify-center sm:mt-0"></div>
                      </div>
                    </div>
                    {(actAPs.length > 0 ||
                      valAPs.length > 0 ||
                      autAPs.length > 0) && (
                      <div className="border-t border-gray-200 bg-gray-50 px-6 py-5 text-center text-sm font-medium">
                        <nav
                          className="flex flex-col items-center justify-center"
                          aria-label="Progress"
                        >
                          <p className="text-xl font-light">
                            <span className="font-bold uppercase">
                              Periodo de actualización
                            </span>
                          </p>
                          <p className="text font-light">
                            <span className="font-bold text-gray-500">
                              {getDatePeriodo()}
                            </span>
                          </p>
                        </nav>
                      </div>
                    )}
                  </div>
                </section>
                {actAPs.length > 0 || valAPs.length > 0 || autAPs.length > 0 ? (
                  <div>
                  {actAPs.length > 0 && (
                    <div className="">
                      <p className="text-gray-900 text-3xl font-medium">Experto Funcional</p>
                      <div className="space-y-8">
                        {actAPs.map((ap) => (
                          <HomeUpdater
                            key={`card-ef-${ap.tra_ap_usuario_id}`}
                            id={ap.tra_ap_id}
                            positionId={ap.puesto_id}
                            cardType={ap.tra_ap_tipo_id}
                            status={ap.estatus_ap_id}
                            title={ap.puesto_nombre}
                            startDate={new Date(ap.actualizacion_inicio)}
                            endDate={new Date(ap.actualizacion_fin)}
                            lastUpdateDate={new Date(ap.fecha_modificacion)}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                  {valAPs.length > 0 && (
                    <div className="">
                      <p className="text-gray-900 text-3xl font-medium mb-4">Validador</p>
                      <div className="space-y-8">
                        {valAPs.map((ap) => (
                          <HomeUpdater
                            key={`card-ef-${ap.tra_ap_usuario_id}`}
                            id={ap.tra_ap_id}
                            positionId={ap.puesto_id}
                            cardType={ap.tra_ap_tipo_id}
                            status={ap.estatus_ap_id}
                            title={ap.puesto_nombre}
                            startDate={new Date(ap.validacion_inicio)}
                            endDate={new Date(ap.validacion_fin)}
                            lastUpdateDate={new Date(ap.fecha_modificacion)}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                  {autAPs.length > 0 && (
                    <div className="">
                      <p className="text-gray-900 text-3xl font-medium">Autorizador</p>
                      <div className="space-y-8">
                        {autAPs.map((ap) => (
                          <HomeUpdater
                            key={`card-ef-${ap.tra_ap_usuario_id}`}
                            id={ap.tra_ap_id}
                            positionId={ap.puesto_id}
                            cardType={ap.tra_ap_tipo_id}
                            status={ap.estatus_ap_id}
                            title={ap.puesto_nombre}
                            startDate={new Date(ap.autorizacion_inicio)}
                            endDate={new Date(ap.autorizacion_fin)}
                            lastUpdateDate={new Date(ap.fecha_modificacion)}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                ) : (
                  <>
                    <p className="text-2xl text-gray-800 font-medium uppercase">Mi descripción de puesto</p>
                    <div className="overflow-hidden bg-white shadow sm:rounded-md">
                      <ul role="list" className="divide-y divide-gray-200">
                        <li>
                          <div className="px-4 py-4 sm:px-6 block sm:flex sm:items-center sm:justify-between space-y-4">
                            <div className="flex-1">
                              <p className="truncate text-sm font-medium text-red-600">
                                {user?.puesto}
                              </p>
                              <p className="mt-2 flex items-center text-sm text-gray-500">
                                <UsersIcon
                                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                  aria-hidden="true"
                                />
                                {user?.area}
                              </p>
                            </div>
                            <Link
                              to={`/t3b/position/${user?.puesto_id}/profile?changes=yes`}
                              type="button"
                              className="inline-flex items-center rounded-md border border-transparent bg-red-100 px-3 py-2 text-sm font-medium leading-4 text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                            >
                              Ver descripción
                            </Link>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </main>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0 sm:py-8">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden bg-transparent transition-all sm:w-full sm:max-w-xl">
                  {hasDP && (
                    <img
                      className=""
                      src={`https://dfh9lj2viqrbd.cloudfront.net/processes/DescripcionesPuesto/${hasDP}`}
                    />
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
