import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { useNavigate, useParams } from "react-router-dom";
import { useRef } from "react";
import API from "../../services/API";
import {
  competencesOptions,
} from "../../constants/utils";
import UpdatePositionProfileFormat from "../../components/dp/UpdatePositionProfileFormat";
import BigModalTemplate from "../../components/modals/BigModalTemplate";
import { ExclamationTriangleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import CommonModalTemplate from "../../components/modals/CommonModalTemplate";

const UpdatePositionProfile = () => {
  const navigate = useNavigate();
  const pathParams = useParams();
  const { positionProfileId, printType } = pathParams;
  const { user, token } = useSelector((state) => state.authentication);
  let printComponent = useRef();
  let updateProfileRef = useRef();
  let validateProfileRef = useRef();
  let authorizeProfileRef = useRef();

  const [isLoading, setLoading] = useState(true);
  const [isLoadingUpdate, setLoadingUpdate] = useState(false);
  const [answers, setAnswers] = useState({});
  const [assessmentUser, setAssessmentUser] = useState({});
  const [myAnswers, setMyAnswers] = useState({});
  const [ap, setAP] = useState({});

  useEffect(() => {
    const fetchAPAnswers = (ne) => {
      setLoading(true);
      let params = { tra_ap_id: ne };
      const options = { params, headers: { Authorization: token } };
      API.get("/dp/formats/answers", options)
        .then((response) => {
          setLoading(false);
          if (response) {
            const { data, status } = response;
            if (status === 200) {
              let transformed = {};
              for (let obj of Object.entries(data)) {
                let value = obj[1];
                let setValue = value;
                if (setValue.tipo_respuesta_id === 2) {
                  setValue.respuesta_texto = JSON.parse(
                    setValue.respuesta_texto
                  );
                  setValue.respuesta_json = JSON.parse(setValue.respuesta_json);
                }
                transformed[setValue.pregunta_id] = setValue;
                if (setValue.pregunta_id == 29) {
                  let answ = {};
                  if (setValue.respuesta_json.length < 1) {
                    answ = {
                      [setValue.pregunta_id]: {
                        numero_empleado: user.numero_empleado,
                        pregunta_id: setValue.pregunta_id,
                        tipo_respuesta_id: 2,
                        respuesta_json: competencesOptions
                          .filter((c) => c.id != "TYPE2")
                          .map((c) => {
                            return {
                              titulo: c.id,
                              nivel: 2,
                            };
                          }),
                        respuesta_texto: competencesOptions
                          .filter((c) => c.id != "TYPE2")
                          .map((c) => {
                            return {
                              titulo: c.id,
                              nivel: 2,
                            };
                          }),
                      },
                    };
                  } else {
                    let resp = [...setValue.respuesta_json];
                    for (let ij = 0; ij < competencesOptions.length; ij++) {
                      if (competencesOptions[ij].id != "TYPE2") {
                        let index = setValue.respuesta_json.findIndex(
                          (c) => c.titulo == competencesOptions[ij].id
                        );
                        if (index < 0) {
                          resp.push({
                            titulo: competencesOptions[ij].id,
                            nivel: 2,
                          });
                        }
                      }
                    }

                    answ = {
                      [setValue.pregunta_id]: {
                        numero_empleado: user.numero_empleado,
                        pregunta_id: setValue.pregunta_id,
                        tipo_respuesta_id: 2,
                        respuesta_json: resp,
                        respuesta_texto: resp,
                      },
                    };
                  }
                  setAnswers(answ);
                }
              }
              setAnswers(transformed);
            } else {
              let answ = {};
              let resp = [];
              for (let ij = 0; ij < competencesOptions.length; ij++) {
                if (competencesOptions[ij].id != "TYPE2") {
                  resp.push({ titulo: competencesOptions[ij].id, nivel: 2 });
                }
              }

              answ = {
                [29]: {
                  numero_empleado: user.numero_empleado,
                  pregunta_id: 29,
                  tipo_respuesta_id: 2,
                  respuesta_json: resp,
                  respuesta_texto: resp,
                },
              };
              setAnswers(answ);
            }
          } else {
            setAnswers({});
          }
        })
        .catch((error) => {
          setAnswers({});
          setLoading(false);
        });
    };
    if (ap.tra_ap_id) {
      fetchAPAnswers(ap.tra_ap_id);
    } else {
      let answ = {};
      let resp = [];
      for (let ij = 0; ij < competencesOptions.length; ij++) {
        if (competencesOptions[ij].id != "TYPE2") {
          resp.push({ titulo: competencesOptions[ij].id, nivel: 2 });
        }
      }
      answ = {
        [29]: {
          numero_empleado: user.numero_empleado,
          pregunta_id: 29,
          tipo_respuesta_id: 2,
          respuesta_json: resp,
          respuesta_texto: resp,
        },
      };
      setMyAnswers(answ);
    }
  }, [ap]);

  useEffect(() => {
    const fetchAPs = (tra_ap_id, numero_empleado) => {
      setLoading(true);
      let params = { tra_ap_id, numero_empleado };
      const options = { params, headers: { Authorization: token } };
      API.get("/dp/formats/users", options)
        .then((response) => {
          setLoading(false);
          if (response) {
            const { data, status } = response;
            if (status === 200) {
              setAP(data);
            } else {
              navigate("/t3b/home");
            }
          } else {
            setAP({});
          }
        })
        .catch((error) => {
          setAP({});
          setLoading(false);
        });
    };
    if (user) {
      fetchAPs(positionProfileId, user.numero_empleado);
    }
  }, []);

  const reactToPrintContent = useCallback(() => {
    return printComponent.current;
  }, [printComponent.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: `${printType < 2 ? "Autoevaluacion_" : "Evaluacion_"}${
      assessmentUser.nombre_completo
    }`,
    removeAfterPrint: true,
    copyStyles: true,
    pageStyle: "{ size: auto; margin: 0mm; }",
  });

  const onChangeCallback = (question, answer) => {
    let answ = {...answer};
    if(answ.tipo_respuesta_id===2){
      answ.respuesta_texto = JSON.stringify(answ.respuesta_json);
      answ.respuesta_json = answ.respuesta_texto;
    }
    let body = {
      tra_ap_id: ap.tra_ap_id,
      tra_ap_usuario_id: ap.tra_ap_usuario_id,
      numero_empleado: user.numero_empleado,
      ...answ
    }
    API.post(`/dp/ap/${ap.tra_ap_id}/answers`, body)
    .then( response => {
      setLoading(false);
    })
  }

  const sendUpdateDP = (e) => {
    let body = {
      tra_ap_id: ap.tra_ap_id,
      body: {
        usuario_modificacion: user.numero_empleado,
        fecha_modificacion: new Date(),
        estatus_ap_id: 2,
      }
    }
    setLoadingUpdate(true);
    const options = { headers: { Authorization: token } };
    API.patch('/dp/formats/users/adps', body, options)
    .then( response => {
      const { status } = response;
      setLoadingUpdate(false);
      if(status===200){
        return navigate('/t3b/home');

      }
    })
    .catch( error => {
      setLoadingUpdate(false);
    })
  }

  const sendValidateDP = (e) => {
    let body = {
      tra_ap_id: ap.tra_ap_id,
      body: {
        usuario_modificacion: user.numero_empleado,
        fecha_modificacion: new Date(),
        estatus_ap_id: 3,
      }
    }
    setLoadingUpdate(true);
    const options = { headers: { Authorization: token } };
    API.patch('/dp/formats/users/adps', body, options)
    .then( response => {
      const { status } = response;
      setLoadingUpdate(false);
      if(status===200){
        return navigate('/t3b/home');

      }
    })
    .catch( error => {
      setLoadingUpdate(false);
    })
  }

  const sendAuthorizeDP = (e) => {
    let body = {
      tra_ap_id: ap.tra_ap_id,
      body: {
        usuario_modificacion: user.numero_empleado,
        fecha_modificacion: new Date(),
        estatus_ap_id: 4,
      }
    }
    setLoadingUpdate(true);
    const options = { headers: { Authorization: token } };
    API.patch('/dp/formats/users/adps', body, options)
    .then( response => {
      const { status } = response;
      setLoadingUpdate(false);
      if(status===200){
        return navigate('/t3b/home');

      }
    })
    .catch( error => {
      setLoadingUpdate(false);
    })
  }

  return (
    <>
      <div className="w-full flex flex-col justify-center items-center px-2 sm:px-4 md:px-8 py-8">
        <div className="w-full max-w-7xl mt-8 flex justify-end">
          <div>
            {ap.tra_ap_tipo_id == 1 && ap.estatus_ap_id==1 && (
              <button
                type="button"
                onClick={e => updateProfileRef.current.showModal()}
                className="col-span-1 flex items-center justify-center rounded-md border border-transparent bg-yellow-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
              >
                Actualizar
              </button>
            )}
            {ap.tra_ap_tipo_id == 2  && ap.estatus_ap_id==2 && (
              <button
                type="button"
                onClick={e => validateProfileRef.current.showModal()}
                className="col-span-1 flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                Validar
              </button>
            )}
            {ap.tra_ap_tipo_id == 3  && ap.estatus_ap_id==3 && (
              <button
                type="button"
                onClick={e => authorizeProfileRef.current.showModal()}
                className="col-span-1 flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Autorizar
              </button>
            )}
          </div>
        </div>
        <div ref={printComponent} className="w-full">
          <UpdatePositionProfileFormat
            ap={ap}
            answers={answers}
            setAnswers={setAnswers}
            onChangeCallback={onChangeCallback}
          />
        </div>
      </div>
      <CommonModalTemplate ref={updateProfileRef}>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <p className="text-lg font-medium leading-6 text-gray-900">Enviar descripción</p>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                ¿Deseas enviar la descripción de puesto? Una vez enviada no podrás realizar más cambios
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            onClick={sendUpdateDP}
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Enviar
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={() => updateProfileRef.current.hideModal()}
          >
            Cancelar
          </button>
        </div>
      </CommonModalTemplate>
      <CommonModalTemplate ref={validateProfileRef}>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <p className="text-lg font-medium leading-6 text-gray-900">Validar descripción</p>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                ¿Deseas validar la descripción de puesto? Una vez validada no podrás realizar más cambios
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            onClick={sendValidateDP}
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Validar
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={() => validateProfileRef.current.hideModal()}
          >
            Cancelar
          </button>
        </div>
      </CommonModalTemplate>
      <CommonModalTemplate ref={authorizeProfileRef}>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <p className="text-lg font-medium leading-6 text-gray-900">Autorizar descripción</p>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                ¿Deseas autorizar la descripción de puesto? Una vez autorizada no podrás realizar más cambios
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            onClick={sendAuthorizeDP}
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Autorizar
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={() => authorizeProfileRef.current.hideModal()}
          >
            Cancelar
          </button>
        </div>
      </CommonModalTemplate>
    </>
  );
};

export default UpdatePositionProfile;
