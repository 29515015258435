import { useEffect, useRef } from 'react';
import autosize from "autosize";

const TextAreaAutosize = ({ ...rest }) => {
  const textRef = useRef(null);
  useEffect(() => {
    if (textRef.current) {
      autosize(textRef.current);
    }
  }, [textRef]);
  return (
    <textarea
      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
      ref={textRef}
      {...rest}
    >
    </textarea>
  );
}

TextAreaAutosize.defaultProps = {
}

export default TextAreaAutosize;