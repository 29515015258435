import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { classNames } from "../../constants/functions";
import { months_es } from "../../utils/date_utils";

const HomeUpdater = ({ id, status, title, startDate, endDate, lastUpdateDate, cardType, positionId }) => {
  const getAssessmentStatusRowClassName = (status_id) => {
    let className = "";
    switch (status_id) {
      case 1:
        className = "bg-red-100";
        break;
      case 2:
        className = "bg-yellow-100";
        break;
      case 3:
        className = "bg-orange-100";
        break;
      case 4:
        className = "bg-green-100";
        break;
      default:
        className = "bg-red-100";
        break;
    }
    return className;
  };

  const getAssessmentStatusLabelClassName = (status_id) => {
    let className = "";
    switch (status_id) {
      case 1:
        className = "text-red-800";
        break;
      case 2:
        className = "text-yellow-800";
        break;
      case 3:
        className = "text-orange-800";
        break;
      case 4:
        className = "text-green-800";
        break;
      default:
        className = "text-red-800";
        break;
    }
    return className;
  };

  const getAssessmentStatusLabel = (status_id) => {
    let className = "";
    switch (status_id) {
      case 1:
        className = "No actualizada";
        break;
      case 2:
        className = "Actualizada";
        break;
      case 3:
        className = "Validada";
        break;
      case 4:
        className = "Autorizada";
        break;
      default:
        className = "No actualizada";
        break;
    }
    return className;
  };

  return (
    <div
      className={classNames("relative bg-white rounded-lg shadow")}
      key={`key-assessment-${id}`}
    >
      <div className="relative mx-auto max-w-7xl p-4 sm:p-6 lg:p-6">
        <div>
          <div className="flex justify-end">
            <span
              className={`inline-block flex-shrink-0 rounded-full px-2 py-0.5 font-medium ${getAssessmentStatusLabelClassName(
                status
              )} ${getAssessmentStatusRowClassName(
                status
              )}`}
            >
              {getAssessmentStatusLabel(status)}
            </span>
          </div>
          <p className="mt-1 text-xl font-semibold tracking-tight text-black sm:text-2xl">
            {title}
          </p>
          <p className="mt-1 text-sm text-gray-500">
            Última actualización el <span className="">
              {lastUpdateDate.getDate()}{" "}
                de {months_es[lastUpdateDate.getMonth()]}{" "}
                a las {lastUpdateDate.getHours()}:{lastUpdateDate.getMinutes()}
            </span>{" "}
          </p>
          <p className="sm:text-lg text-gray-700 font-medium">
            Disponible del{" "}
            <span className="text-red-500">
              {startDate.getDate()}
            </span>{" "}
            de {months_es[startDate.getMonth()]} al{" "}
            <span className="text-red-500">
              {endDate.getDate()}
            </span>{" "}
            de {months_es[endDate.getMonth()]}
          </p>
          <div className="mt-2 grid grid-cols-2 sm:flex sm:space-x-2 gap-2">
            {cardType == 1 && <Link to={status == 1 ? `/t3b/position-profile/${id}/update` : `/t3b/position/${positionId}/profile?changes=no`}>
              <button
                type="button"
                className="col-span-1 sm:inline-flex items-center justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 font-medium text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
              >
                {status == 1 ? "Actualizar" : "Ver"}
              </button>
            </Link>}
          </div>
          <div className="mt-2 grid grid-cols-2 sm:flex sm:space-x-2 gap-2">
            {cardType == 2 && <Link to={status == 2 ? `/t3b/position-profile/${id}/update` : `/t3b/position/${positionId}/profile?changes=no`}>
              <button
                type="button"
                className="col-span-1 sm:inline-flex items-center justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 font-medium text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
              >
                {status == 2 ? "Validar" : "Ver"}
              </button>
            </Link>}
          </div>
          <div className="mt-2 grid grid-cols-2 sm:flex sm:space-x-2 gap-2">
            {cardType == 3 && <Link to={status == 3 ? `/t3b/position-profile/${id}/update` : `/t3b/position/${positionId}/profile?changes=no`}>
              <button
                type="button"
                className="col-span-1 sm:inline-flex items-center justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 font-medium text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
              >
                {status == 3 ? "Autorizar" : "Ver"}
              </button>
            </Link>}
          </div>
        </div>
      </div>
    </div>
  );
};

HomeUpdater.propTypes = {
  id: PropTypes.number,
  status: PropTypes.number,
  title: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  lastUpdateDate: PropTypes.instanceOf(Date),
};

HomeUpdater.defaultProps = {
  id: 1,
  status: 1,
  title: "Default title",
  startDate: new Date(),
  endDate: new Date(),
  lastUpdateDate: new Date(),
};

export default HomeUpdater;
