import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import './index.css';
import Login from "./routes/Login";
import NotFound from "./routes/NotFound";
import HomeLayout from "./routes/HomeLayout";
import AdminLayout from "./routes/AdminLayout";
import CommonHome from "./routes/Common/Home";
import Users from "./routes/Admin/Users";
import PrintPositionProfile from "./routes/Common/PrintPositionProfile";
import UpdatePositionProfile from "./routes/Common/UpdatePositionProfile";
import PositionsRegions from "./routes/Admin/PositionsRegions";
import CreatePositionProfileProcess from "./routes/Admin/CreatePositionProfileProcess";
import TokenLogin from "./routes/TokenLogin";
import PositionsInRegionArea from "./routes/Admin/PositionsInRegionArea";
import PositionInfo from "./routes/Admin/PositionInfo";
import UpdatePositionProfileAdmin from "./routes/Admin/UpdatePositionProfileAdmin";
import PositionsChangesRequest from "./routes/Admin/PositionsChangesRequest";
import PositionProfileHistory from "./routes/Admin/PositionProfileHistory";
import { useEffect } from "react";

export default function App() {
  useEffect(()=>{
    function preventDef(e) {
      e.preventDefault();
    };
    window.addEventListener("contextmenu", preventDef, false);
    return () => {
      window.removeEventListener("test", preventDef, false);
    }
  },[])
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/token-login" element={<TokenLogin />} />
        <Route path="/t3b" element={<HomeLayout />} >
          <Route path="home" element={<CommonHome />} />
          <Route path="position" >
            <Route path=":positionId" >
              <Route path="profile" element={<PrintPositionProfile />} />
            </Route>
          </Route>
          <Route path="position-profile" >
            <Route path=":positionProfileId" >
              <Route path="update" element={<UpdatePositionProfile />} />
            </Route>
          </Route>
          <Route path="admin" >
            <Route path="position-profile" >
              <Route path=":positionProfileId" >
                <Route path="update" element={<UpdatePositionProfileAdmin />} />
              </Route>
            </Route>
            <Route path="position-history" >
              <Route path=":positionProfileId" element={<PositionProfileHistory />} />
            </Route>
            <Route path="regions" >
              <Route index element={<PositionsRegions />} />
              <Route path=":regionType" >
                <Route path="areas" >
                  <Route path=":areaId" >
                    <Route index element={<PositionsInRegionArea />} />
                    <Route path="positions" >
                      <Route path=":positionId" >
                        <Route index element={<PositionInfo />} />
                        <Route path="update" element={<PrintPositionProfile />} />
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path="users" element={<Users />} />
            <Route path="update-process" element={<CreatePositionProfileProcess />} />
            <Route path="inbox" element={<PositionsChangesRequest />} />
          </Route>
        </Route>
        <Route
          path="*"
          element={<Navigate to="/t3b/home" replace  />}
        />
      </Routes>
    </BrowserRouter>
  );
}