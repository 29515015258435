import { XMarkIcon } from "@heroicons/react/24/solid";
import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import API from "../../services/API";
import TextAreaAutosize from "../inputs/TextAreaAutosize";
import BigModalTemplate from "./BigModalTemplate";
import { CheckIcon } from '@heroicons/react/24/outline';


const initialState = {
  section1: "",
  section2: "",
  section3: "",
  section4: "",
  section5: "",
  section6: "",
  section7: "",
  section8: "",
  section9: "",
  section10: "",
  section11: "",
};

const titles = {
  section1: "REPORTA A",
  section2: "SUPERVISA A",
  section3: "OBJETIVO DEL PUESTO",
  section4: "RESPONSABILIDADES Y DECISIONES ESPECÍFICAS",
  section5: "IDIOMAS",
  section6: "EXPERIENCIA",
  section7: "HABILIDADES / COMPETENCIAS NECESARIAS",
  section8: "COMPETENCIAS TÉCNICAS",
  // section9: "IX. EXPERIENCIA REQUERIDA",
  // section10: "X.PICO DE EXCELENCIA 3B",
  // section11: "XI. COMPETENCIAS DEL PUESTO Y NIVEL DE DOMINIO",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "reset":
      return initialState;
    default:
      return {
        ...state,
        [action.type]: action.payload,
      };
  }
};

const RequestPositionProfileChanges = forwardRef(({ ap }, ref) => {
  const [sections, dispatch] = useReducer(reducer, initialState);
  let navigate = useNavigate();
  const [admons, setAdmons] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user, token } = useSelector((state) => state.authentication);
  const refModal = useRef();
  const successModal = useRef();

  useImperativeHandle(ref, () => ({
    showModal() {
      refModal.current.showModal();
    },
    hideModal() {
      refModal.current.hideModal();
    },
  }));

  useEffect(() => {
    let params = { admon: true };
    const options = { params, headers: { Authorization: token } };
    API.get("/dp/users", options).then(({ data, status }) => {
      if (status === 200) {
        setAdmons(data.filter(a => a.send_email));
      }
    });
  }, []);

  const onApplication = (e) => {
    let sectionsBody = [];
    if (sections.section1 !== "") {
      sectionsBody.push({
        section: titles["section1"],
        change: sections.section1,
      });
    }
    if (sections.section2 !== "") {
      sectionsBody.push({
        section: titles["section2"],
        change: sections.section2,
      });
    }
    if (sections.section3 !== "") {
      sectionsBody.push({
        section: titles["section3"],
        change: sections.section3,
      });
    }
    if (sections.section4 !== "") {
      sectionsBody.push({
        section: titles["section4"],
        change: sections.section4,
      });
    }
    if (sections.section5 !== "") {
      sectionsBody.push({
        section: titles["section5"],
        change: sections.section5,
      });
    }
    if (sections.section6 !== "") {
      sectionsBody.push({
        section: titles["section6"],
        change: sections.section6,
      });
    }
    if (sections.section7 !== "") {
      sectionsBody.push({
        section: titles["section7"],
        change: sections.section7,
      });
    }
    if (sections.section8 !== "") {
      sectionsBody.push({
        section: titles["section8"],
        change: sections.section8,
      });
    }
    // if (sections.section9 !== "") {
    //   sectionsBody.push({
    //     section: titles["section9"],
    //     change: sections.section9,
    //   });
    // }
    // if (sections.section10 !== "") {
    //   sectionsBody.push({
    //     section: titles["section10"],
    //     change: sections.section10,
    //   });
    // }
    // if (sections.section11 !== "") {
    //   sectionsBody.push({
    //     section: titles["section11"],
    //     change: sections.section11,
    //   });
    // }
    if (sectionsBody.length < 1) {
    }
    let htmlBody = "<div>";
    htmlBody += `<h5>${user.nombre} ha enviado una solicitud para actualizar la descripción de Puesto de ${ap.puesto_nombre} con las siguientes sugerencias:</h5>`;
    sectionsBody.forEach((section) => {
      let html = `<h4 style="margin:0;">${section.section}</h4><pstyle="margin-top:0;">${section.change}</p>`;
      htmlBody += html;
    });
    htmlBody += "</div>";
    let body = {
      puesto_id: ap.puesto_id,
      puesto: ap.puesto_nombre,
      nombre_completo: user.nombre,
      email_to: admons.map((a) => a.email),
      htmlBody: htmlBody,
      subject: `Solicitud de Actualización de Descripción de Puesto de ${ap.puesto_nombre}`,
      text: `${user.nombre} ha enviado una solicitud para actualizar la descripción de Puesto de ${ap.puesto_nombre} con las siguientes sugerencias`,
    };
    let bodyRequest = {
      usuario_solicita_id: user.numero_empleado,
      puesto_id: ap.puesto_id,
      sections: sectionsBody,
    };
    setLoading(true);
    const options = { headers: { Authorization: token } };
    API.post(`/dp/requests`, bodyRequest, options)
      .then((result) => {
        if (result.status === 200) {
          successModal.current.showModal();
          API.post(`/dp/email/application`, body, options)
          .then((result)=>{
            if(result.status===200){
              setLoading(false);
            }
          })
          .catch( error =>{
            setLoading(false);
          })
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <BigModalTemplate ref={refModal}>
      <h3 className="text-lg font-medium leading-6 text-gray-900">Solicitar cambios</h3>
      <p className="mt-1 text-sm text-gray-500 mb-4">
        Solicite un cambio en la descripción de puesto en alguna de las siguientes secciones.
      </p>
      <div>
        <div className="flex flex-col mb-4">
          <h3 className="text-sm font-medium leading-6 text-gray-900">REPORTA A.</h3>
          <TextAreaAutosize
            value={sections["section1"]}
            onChange={(e) =>
              dispatch({ type: "section1", payload: e.target.value })
            }
            placeholder="Escribe tu solucitud de actualización de REPORTA A"
            id="section-1"
          ></TextAreaAutosize>
        </div>
        <div className="flex flex-col mb-4">
          <h3 className="text-sm font-medium leading-6 text-gray-900">SUPERVISA A.</h3>
          <TextAreaAutosize
            value={sections["section2"]}
            onChange={(e) =>
              dispatch({ type: "section2", payload: e.target.value })
            }
            placeholder="Escribe tu solucitud de actualización de SUPERVISA A"
            id="section-2"
          ></TextAreaAutosize>
        </div>
        <div className="flex flex-col mb-4">
          <h3 className="text-sm font-medium leading-6 text-gray-900">OBJETIVO DEL PUESTO.</h3>
          <TextAreaAutosize
            value={sections["section3"]}
            onChange={(e) =>
              dispatch({ type: "section3", payload: e.target.value })
            }
            placeholder="Escribe tu solucitud de actualización de OBJETIVO DEL PUESTO"
            id="section-3"
          ></TextAreaAutosize>
        </div>
        <div className="flex flex-col mb-4">
          <h3 className="text-sm font-medium leading-6 text-gray-900">RESPONSABILIDADES Y DECISIONES ESPECÍFICAS.</h3>
          <TextAreaAutosize
            value={sections["section4"]}
            onChange={(e) =>
              dispatch({ type: "section4", payload: e.target.value })
            }
            placeholder="Escribe tu solucitud de actualización de RESPONSABILIDADES Y DECISIONES ESPECÍFICAS"
            id="section-4"
          ></TextAreaAutosize>
        </div>
        <div className="flex flex-col mb-4">
          <h3 className="text-sm font-medium leading-6 text-gray-900">IDIOMAS.</h3>
          <TextAreaAutosize
            value={sections["section5"]}
            onChange={(e) =>
              dispatch({ type: "section5", payload: e.target.value })
            }
            placeholder="Escribe tu solucitud de actualización de IDIOMAS"
            id="section-5"
          ></TextAreaAutosize>
        </div>
        <div className="flex flex-col mb-4">
          <h3 className="text-sm font-medium leading-6 text-gray-900">EXPERIENCIA.</h3>
          <TextAreaAutosize
            value={sections["section6"]}
            onChange={(e) =>
              dispatch({ type: "section6", payload: e.target.value })
            }
            placeholder="Escribe tu solucitud de actualización de EXPERIENCIA"
            id="section-6"
          ></TextAreaAutosize>
        </div>
        <div className="flex flex-col mb-4">
          <h3 className="text-sm font-medium leading-6 text-gray-900">HABILIDADES / COMPETENCIAS NECESARIAS.</h3>
          <TextAreaAutosize
            value={sections["section7"]}
            onChange={(e) =>
              dispatch({ type: "section7", payload: e.target.value })
            }
            placeholder="Escribe tu solucitud de actualización de HABILIDADES / COMPETENCIAS NECESARIAS"
            id="section-7"
          ></TextAreaAutosize>
        </div>
        <div className="flex flex-col mb-4">
          <h3 className="text-sm font-medium leading-6 text-gray-900">COMPETENCIAS TÉCNICAS.</h3>
          <TextAreaAutosize
            value={sections["section8"]}
            onChange={(e) =>
              dispatch({ type: "section8", payload: e.target.value })
            }
            placeholder="Escribe tu solucitud de actualización de COMPETENCIAS TÉCNICAS"
            id="section-8"
          ></TextAreaAutosize>
        </div>
        {/* <div className="flex flex-col mb-4">
          <h3 className="text-sm font-medium leading-6 text-gray-900">IX. COMPETENCIAS DEL PUESTO Y NIVEL DE DOMINIO</h3>
          <TextAreaAutosize
            value={sections["section10"]}
            onChange={(e) =>
              dispatch({ type: "section10", payload: e.target.value })
            }
            placeholder="Escribe tu solucitud de actualización de la sección X"
            id="section-10"
          ></TextAreaAutosize>
        </div> */}
        <div className="flex flex-col mb-4">
          <button
            type="button"
            className="col-span-1 flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            onClick={onApplication}
            disabled={loading}
          >
            {loading ? "Enviando" : "Solicitar Actualización"}
          </button>
        </div>
      </div>
      <BigModalTemplate ref={successModal}>
        <div>
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
            <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Solicitud enviada
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Los cambios se aplicarán en los próximos 3 días.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
            onClick={() => navigate(-1, {replace: true})}
          >
            Regresar a inicio
          </button>
        </div>
      </BigModalTemplate>
    </BigModalTemplate>
  );
});
export default RequestPositionProfileChanges;
