import { useEffect, useRef, useState } from "react";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  CheckCircleIcon,
  ChevronRightIcon,
} from "@heroicons/react/20/solid";
import API from "../../services/API";
import { DocumentTextIcon, EnvelopeIcon, PaperClipIcon } from "@heroicons/react/24/solid";
import { useSelector } from "react-redux";
import face from "./../../assets/img/faces/avatar.png";
import { Link, useNavigate } from "react-router-dom";
import NoSpaceTemplate from "../../components/modals/NoSpaceTemplate";
import { getDateLabel } from "../../utils/date_utils";
import ReactPaginate from "react-paginate";

const tabs = [
  { name: "Abiertas", value: "1" },
  { name: "Cerradas", value: "2" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PositionsChangesRequest() {
  const { token, user } = useSelector((state) => state.authentication);
  const [step, setStep] = useState(1);
  const [currentADP, setCurrentADP] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [dps, setDps] = useState([]);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [currentLimit, setCurrentLimit] = useState(25);
  const [currentStatus, setCurrentStatus] = useState(1);
  const [pagesNumber, setPagesNumber] = useState(0);
  const [dpsCount, setDpsCount] = useState(0);
  const requestModalRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchActiveDPs = () => {
      let headers = {
        Authorization: token,
      };
      let params = {
        estatus: currentStatus,
        limit: currentLimit,
        offset: currentOffset * currentLimit,
      };
      const options = { params, headers };
      API.get("/dp/requests", options)
        .then((response) => {
          const { data, status } = response;
          if (status === 200) {
            setDps(data.dps);
            setPagesNumber(data.pages);
            setDpsCount(data.count);
          } else if (status === 204) {
            setDps([]);
            setPagesNumber(0);
            setDpsCount(0);
          } else {
            setDps([]);
            setPagesNumber(0);
            setDpsCount(0);
          }
        })
        .catch((err) => {
          setDps([]);
          setPagesNumber(0);
          setDpsCount(0);
        });
    };
    fetchActiveDPs();
  }, [currentStatus, currentLimit, currentOffset]);

  const selectActiveDP = (e, active = null) => {
    e.preventDefault();
    setCurrentADP(active);
    requestModalRef.current.showModal();
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentOffset(newOffset);
  };

  const fetchActiveDPs = () => {
    let headers = {
      Authorization: token,
    };
    let params = {
      estatus: currentStatus,
      limit: currentLimit,
      offset: currentOffset * currentLimit,
    };
    const options = { params, headers };
    API.get("/dp/requests", options)
      .then((response) => {
        const { data, status } = response;
        if (status === 200) {
          setDps(data.dps);
          setPagesNumber(data.pages);
          setDpsCount(data.count);
        } else if (status === 204) {
          setDps([]);
          setPagesNumber(0);
          setDpsCount(0);
        } else {
          setDps([]);
          setPagesNumber(0);
          setDpsCount(0);
        }
      })
      .catch((err) => {
        setDps([]);
        setPagesNumber(0);
        setDpsCount(0);
      });
  };

  const onDelete = (e) => {
    let body = {
      tra_ap_solicitud_id: currentADP.tra_ap_solicitud_id,
      usuario_solicita_id: user.numero_empleado,
      estatus_ap_id: 2,
      estatus_cierre: 3
    };
    setLoadingDelete(true);
    const options = { headers: { Authorization: token } };
    API.post(`/dp/requests`, body, options)
      .then((result) => {
        setLoadingDelete(false);
        if (result.status === 200) {
          requestModalRef.current.hideModal();
          fetchActiveDPs();
        }
      })
      .catch((error) => {
        setLoadingDelete(false);
      });
  };

  const createUpdate = (e) => {
    let body = {
      numero_empleado: user.numero_empleado,
      usuario_actualiza_id: user.numero_empleado,
      puesto_id: currentADP.puesto_id,
    };
    setLoadingUpdate(true);
    const options = { headers: { Authorization: token } };
    API.post(`/dp/positions/${currentADP.puesto_id}/update`, body, options)
      .then((response) => {
        setLoadingUpdate(false);
        const { data, status } = response;
        if (status === 200) {
          return navigate(`/t3b/admin/position-profile/${data.tra_ap_id}/update?close=${currentADP.tra_ap_solicitud_id}`);
        }
      })
      .catch((error) => {
        setLoadingUpdate(false);
      });
  };

  return (
    <div className="">
      <main className="pt-8 pb-16">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="px-4 sm:px-0">
            <h2 className="text-lg font-medium text-gray-900">
              Solicitudes de actualización
            </h2>

            {/* Tabs */}
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Selecciona una opción
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id="tabs"
                name="tabs"
                className="mt-4 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                value={currentStatus}
                onChange={(e) => currentStatus(e.target.value)}
              >
                {tabs.map((tab) => (
                  <option key={tab.value}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <div className="border-b border-gray-200">
                <nav className="mt-2 -mb-px flex space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <button
                      key={tab.name}
                      onClick={(e) => setCurrentStatus(tab.value)}
                      className={classNames(
                        tab.value == currentStatus
                          ? "border-red-500 text-red-600"
                          : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                        "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                      )}
                    >
                      {tab.name}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>

          {/* Stacked list */}
          {dps.length > 0 ? (
            <ul
              role="list"
              className="mt-5 divide-y divide-gray-200 border-t border-gray-200 sm:mt-0 sm:border-t-0"
            >
              {dps.map((dp) => (
                <li key={`key-solicitud-${dp.tra_ap_solicitud_id}`}>
                  <a
                    href="/#"
                    onClick={(e) => selectActiveDP(e, dp)}
                    to={`/t3b/admin/inbox/${dp.tra_ap_solicitud_id}`}
                    className="group block"
                  >
                    <div className="flex items-center py-5 px-4 sm:py-6 sm:px-0">
                      <div className="flex min-w-0 flex-1 items-center">
                        <div className="flex-shrink-0">
                          <img
                            className="h-12 w-12 rounded-full group-hover:opacity-75 object-cover"
                            src={
                              dp.imagen_perfil
                                ? `https://dfh9lj2viqrbd.cloudfront.net/profile/${dp?.imagen_perfil}`
                                : face
                            }
                            alt=""
                          />
                        </div>
                        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                          <div>
                            <p className="truncate text-sm font-medium text-red-600">
                              {dp.nombre_completo}
                            </p>
                            <p className="mt-2 flex items-center text-sm text-gray-500">
                              <EnvelopeIcon
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              <span className="truncate">{dp.email}</span>
                            </p>
                          </div>
                          <div className="hidden md:block">
                            <div>
                              {
                                currentStatus == 1
                                ? <p className="text-sm text-gray-500">
                                  Solicitado el <span className="font-medium underline">{getDateLabel(dp.fecha_alta)}</span> para:
                                </p>
                                : dp.estatus_cierre == 2
                                  ? <p className="text-sm text-green-600 font-medium">
                                    Solicitud aceptada
                                  </p>
                                  : <p className="text-sm text-red-600 font-medium">
                                    Solicitud rechazada
                                  </p>
                              }
                              <p className="mt-2 flex items-center text-sm text-gray-900">
                                <DocumentTextIcon
                                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-red-500"
                                  aria-hidden="true"
                                />
                                {dp.puesto_nombre}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <ChevronRightIcon
                          className="h-5 w-5 text-gray-400 group-hover:text-gray-700"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          ) : (
            <div className="text-center py-16">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  vectorEffect="non-scaling-stroke"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                />
              </svg>
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                Sin solicitudes
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                No hay solicitudes con los filtros seleccionados.
              </p>
            </div>
          )}

          {/* Pagination */}
          <div className="border-t border-gray-200"></div>
          <ReactPaginate
            nextLabel="Siguiente"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pagesNumber}
            previousLabel="Anterior"
            pageClassName="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
            pageLinkClassName="page-link"
            className="hidden md:-mt-px md:flex"
            previousClassName="-mt-px flex w-0 flex-1"
            previousLinkClassName="inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
            nextClassName="-mt-px flex w-0 flex-1 justify-end"
            nextLinkClassName="inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
            breakLabel="..."
            breakClassName="flex items-end"
            breakLinkClassName="page-link"
            containerClassName="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0"
            activeClassName="inline-flex items-center border-t-2 border-red-500 px-4 pt-4 text-sm font-medium text-red-600"
            renderOnZeroPageCount={null}
          />
        </div>
      </main>
      <NoSpaceTemplate ref={requestModalRef}>
        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {currentADP?.puesto_nombre}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Detalles de la solicitud.
            </p>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Nombre solicitante</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {currentADP?.nombre_completo}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Puesto solicitante
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {currentADP?.puesto}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Email solicitante
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {currentADP?.email}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Fecha solicitud
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {getDateLabel(currentADP?.fecha_alta)}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Cambios propuestos
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <ul
                    role="list"
                    className="divide-y divide-gray-200 rounded-md border border-gray-200"
                  >
                    {
                      currentADP?.lines.map((line)=>(
                        <li key={`key-line-${line.tra_ap_solicitud_seccion_id}`} className="py-3 pl-3 pr-4">
                          <p className="flex-1 font-semibold">
                            {line.section}
                          </p>
                          <p className="flex-1 text-sm">
                            {line.change}
                          </p>
                        </li>
                      ))
                    }
                  </ul>
                </dd>
              </div>
              {currentStatus == 1 && <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                </dt>
                <dd className="mt-1 sm:col-span-2 sm:mt-0 block sm:flex gap-4 sm:justify-end">
                  <button
                    type="button"
                    onClick={onDelete}
                    disabled={loadingDelete || loadingUpdate}
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  >
                    {loadingDelete ? "Rechazando solicitud" : "Rechazar"}
                  </button>
                  <button
                    type="button"
                    onClick={createUpdate}
                    disabled={loadingDelete || loadingUpdate}
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                  >
                    {loadingDelete ? "Realizando solicitud" : "Actualizar"}
                  </button>
                </dd>
              </div>}
            </dl>
          </div>
        </div>
      </NoSpaceTemplate>
    </div>
  );
}
